import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReservationDTO } from 'app/reservation-management/reservation-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-rating-manage',
  templateUrl: './rating-manage.component.html',
  styleUrls: ['./rating-manage.component.css'],
})
export class RatingManageComponent implements OnInit,OnDestroy {
  constructor(
    public datacontext: DataContextService,
    private fb: FormBuilder,
    private router:Router
    ,private translate: TranslateService

  ) {}
  isFlipped=false

  reservationRatingDetails: ReservationDTO;
  ratingForm: FormGroup;
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.reservationRatingDetails = JSON.parse(
      localStorage.getItem('reservationDetails')!
    );
    if(!this.reservationRatingDetails){
      this.router.navigate(['/profile','reservation-management'])
    }
    this.initRatingForm();
  }
  backHistory() {
    window.history.back();
  }

  private initRatingForm() {
    this.ratingForm = this.fb.group({
      comment: [''],
      clean: ['', [Validators.required]],
      amenities: ['', [Validators.required]],
      staff: ['', [Validators.required]],
      value: ['', [Validators.required]],
      location: ['', [Validators.required]],
      PropertyId: ['', [Validators.required]],
    });

    this.ratingForm
      .get('PropertyId')
      ?.setValue(this.reservationRatingDetails.PropertyId);
  }
  getStatusClass(status: string): string {
    switch (status) {
      case 'pending':
        return 'coming-color';
      case 'ملغي':
        return 'cancelled-color';
      case 'مكتمل':
        return 'completed-color';
      default:
        return '';
    }
  }

  isCompleted(paymentStatus:any,endDate:any):string{

    const xDate = new Date(endDate).getTime()
    const currentDate = new Date().getTime()
    if(paymentStatus === 'pending' && currentDate > xDate){
      return 'غير مدفوع'
    }
    if(paymentStatus === 'completed' && xDate < currentDate){
      return 'بأنتظار الوصول'
    }

    if(paymentStatus === 'completed' && currentDate > xDate){
      return 'غيل مكتمل'
    }

    return ''
  }
  addRating() {
    const token = this.datacontext.getRouteParams('token')
    this.datacontext
      .postData(
        `/rating/add?token=${token}`,this.ratingForm.value
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.router.navigate(['/profile','reservation-management'])
          this.datacontext.showSuccess('', 'تم اضافة تعليق');
        }
      });
  }

  ngOnDestroy(): void {
    if(this.reservationRatingDetails){
      localStorage.removeItem('reservationDetails')
    }
  }
}
