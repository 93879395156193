import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { forkJoin } from 'rxjs';
import { BankListDTO } from './bank-list-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

interface statistic {
  available:number;
  fee:string;
  paidout:number;
  total:number;
}
@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.css']
})
export class PaymentsListComponent implements OnInit {
bankForm:FormGroup
statistic:statistic
isFlipped=false;


constructor(private fb:FormBuilder,private router:Router,private dataContext:DataContextService , private translate:TranslateService){}

ngOnInit(): void {
  this.isFlipped = this.translate.currentLang === 'en';

  // Listen for language change events
  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
  });
  this.bankForm = this.fb.group({
    value:['',[Validators.required,Validators.min(1)]],
    VendorBankAccountId:['',Validators.required]
  })

  this.gettingBankStatistics()
}

bankList:BankListDTO[]
gettingBankStatistics(){
  const token = this.dataContext.getRouteParams('token')
  const functions = [this.dataContext.getData(`/vendor/payout/list?token=${token}`),this.dataContext.getData(`/vendor/bank/list?token=${token}`) ]
 forkJoin(functions).subscribe((data:any) => {
console.log(data)

  if(data[0] && data[0].success){
      this.statistic = data[0].statstics
  }
  if(data[1] && data[1].success){
   this.bankList = data[1].data
  }
  })
}
addBankAccount(){
  this.router.navigate(['/profile','payments-list','add'])
}

submitPayment(){
  console.log('iam here')
  // if(this.bankForm.invalid){
  //   return this.bankForm.markAllAsTouched()
  // }

  const amount = this.bankForm.controls['value'].value
  console.log(amount,this?.statistic)
  if(amount > this.statistic.available) return this.dataContext.showError('','عفواً رصيدك غير كافي')
  const token = this.dataContext.getRouteParams('token')
  this.dataContext.postData(`/vendor/payout/request?token=${token}`,this.bankForm.value).subscribe((data:any) => {
    if(data.success){
      this.dataContext.showSuccess('','تم رفع الطلب')
      this.gettingBankStatistics()
      this.bankForm.reset()
    }
  })
  
}
}
