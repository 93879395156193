import { Component,Input, OnInit } from '@angular/core';
import { CategoryItemInterface } from './category-item-interface';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.css']
})
export class CategoryItemComponent implements OnInit {
@Input() categories:CategoryItemInterface[]=[];

constructor(private router:Router,private activatedRoute:ActivatedRoute,private translate: TranslateService){}
isFlipped=false
ngOnInit(): void {

  this.isFlipped = this.translate.currentLang === 'en';

  // Listen for language change events
  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
  });
}
searchRandom(category:CategoryItemInterface){
  localStorage.setItem('typeId',JSON.stringify(category))
  const routeParam = this.router.url.includes('/search')
  if(routeParam){
    this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
      this.router.navigate(['/search'])
    })
  }else{
    this.router.navigate(['/search'])
  }
}
}
