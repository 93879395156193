import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckOutDTO } from 'app/checkout-dialog/checkout-dto';

@Component({
  selector: 'app-review-reservations',
  templateUrl: './review-reservations.component.html',
  styleUrls: ['./review-reservations.component.css'],
})
export class ReviewReservationsComponent implements OnInit {
  @Output() onHandelConfirmReservation: EventEmitter<FormGroup> =
    new EventEmitter();
  onConfirmOrder(confirm: FormGroup) {
    this.onHandelConfirmReservation.emit(confirm);
  }
  validPaymentForm: FormGroup = new FormGroup({});
  onHandelFormPaymentValid(isFormValid: FormGroup) {
    this.validPaymentForm = isFormValid;
  }

  rooms: CheckOutDTO[];
  ngOnInit(): void {
    const roomDetails = JSON.parse(localStorage.getItem('roomsDetails')!);
    if (roomDetails) {
      this.rooms = roomDetails;
    }
  }
}
