import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ArabicDatePipe } from 'app/arabic-date.pipe';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, throwError } from 'rxjs';
import { ReservationDTO } from './reservation-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reservation-management',
  templateUrl: './reservation-management.component.html',
  styleUrls: ['./reservation-management.component.css'],
  providers: [ArabicDatePipe],
})
export class ReservationManagementComponent implements OnInit {
  @BlockUI('root') rootBlocker!: NgBlockUI;

  constructor(
    private router: Router,
    private datacontext: DataContextService,
    private translate: TranslateService
  ) {}
  isFlipped = false;

  ngOnInit(): void {
    this.gettingResrvationList();
    this.isFlipped = this.translate.currentLang === 'en';
    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }

  reservationList: ReservationDTO[];
  private gettingResrvationList() {
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start()
    this.datacontext
      .getData(`/kamel/list?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError(
              '',
              this.translate.instant(`${err.error.message}`)
            );
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          console.log(value);
          this.reservationList = value.data;
        }
        // this.rootBlocker.stop()
      });
  }
  getStatusClass(status: string): string {
    switch (status) {
      case 'pending':
        return 'coming-color';
      case 'cancelled':
        return 'cancelled-color';
      case 'completed':
        return 'completed-color';
      case 'rejected':
        return 'cancelled-color';
      case 'pendingPaymentTimeout':
        return 'cancelled-color';
      default:
        return 'coming-color';
    }
  }

  reservationDetails(reservationDetails: ReservationDTO) {
    localStorage.setItem(
      'reservationDetails',
      JSON.stringify(reservationDetails)
    );
    this.router.navigate(['/checkout'], { queryParams: { step: 3 } });
  }

  RatingDetails(reservationDetails: ReservationDTO) {
    localStorage.setItem(
      'reservationDetails',
      JSON.stringify(reservationDetails)
    );
    this.router.navigate(['/profile', 'add-rating']);
  }
}
