import { Component, Input } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AmenityDTO } from 'app/new-property/property-dto';
import { SearchDTO } from 'app/search-details/search-dto';

@Component({
  selector: 'app-chalets-options',
  templateUrl: './chalets-options.component.html',
  styleUrls: ['./chalets-options.component.css']
})
export class ChaletsOptionsComponent {
  @Input() searchRes:SearchDTO | null = null
constructor(private translate: TranslateService ){}
isFlipped=false

ngOnInit(): void {
  this.isFlipped = this.translate.currentLang === 'en';

  // Listen for language change events
  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
  });
}
}
