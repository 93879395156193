<div class="container" dir="auto" style="text-align: start;">
    <div id="nav-hidden">
      <app-navigation></app-navigation>
    </div>
    <div class="chat-all">
        <div class="forposition container">
            <div class="">
                <h2 class="title mb-0">{{'chat'|translate}}</h2>
            </div>
            <div class="margin-right">
                <button class="btn btn-danger" (click)="setChatType('guest')">{{'guest'|translate}}</button>
                <button class="btn btn-danger" (click)="setChatType('host')">{{'host'|translate}}</button>
            </div>
        </div>
        <div>
            <div class="row content-right" [dir]="isFlipped ? 'rtl' : 'ltr'">
                <div title="Chat" class="chat">
                    <div class="div-showMessages" id="messagesId"  #messagesContainer *ngIf="Chats && Chats !== 'null' && Chats !== 'undefined'">
                            <div *ngFor="let msg of Chats" [dir]="msg.from !== chatType||!msg.from? (isFlipped ? 'ltr' : 'rtl') : (isFlipped ? 'rtl' : 'ltr')">
                                <div  [ngStyle]="{
                                    'background-color': msg.from === 'guest' ? '#D1361F' : 'lightgray',
                                    'color': msg.from === 'guest' ? 'white' : 'black',
                                    }"   class="msg">
                                    {{ msg.body }}

                                </div>
                            </div>
                    
                    </div>
                    <div *ngIf="Chats=='null' || Chats=='undefined'" class="div-showMessages text-center align-item-center">
                        <h1 class="highlighted" *ngIf="Chats=='undefined'">{{'no_conversation'|translate}}</h1>
                        <h1 class="highlighted" *ngIf="Chats=='null'">{{'start_conversation_now'|translate}}</h1>
                    </div>
                    <!-- <div class="div-showMessages highlighted">
                    </div> -->
                    <div *ngIf="isdispInput" class="div-message">
                        <form>
                            <div class="d-flex flex-row-reverse justify-content-center">
                              <input #messageInput (keyup)="sendMessage($event)" class="input-message form-control" type="text"/>
                              <!-- <input class="input-plus" type="button" value="+" (click)="triggerInputKeyup()"> -->
                              <!-- <button (click)="triggerInputKeyup()" class="send bnt btn-danger">{{'request'|translate}}</button> -->
                            </div>
                          </form>
                    </div>
                </div>
                <div title="Properties" class="col-4 div-props container ">
                    <div *ngFor="let prop of FilteredReservationList" class="my-2">
                        <div>
                            {{prop.createdAt |  date:'M/d/yyyy' }}
                        </div>
                        <div style="border-radius: 25px;" [ngStyle]="{'border': selectedChatId == prop.to ? '1px solid #ef3c23' : 'none'}">
                            <app-chat-item  
                            [index]="prop.PropertyId"  (onSelectEvent)="getSelectedID($event)" [oneProp]="prop"></app-chat-item>
                        </div>
            
                    </div>
                </div>
            </div>
    </div>
</div>