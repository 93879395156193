import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent {
  @Input('back') back: string = '';
  isLangEn: boolean;
  constructor(
    private location: Location,
    private translateService: TranslateService
  ) {
    console.log('this.detectLanguage()', this.detectLanguage());
    this.isLangEn = this.detectLanguage() === 'en';
  }
  detectLanguage(): string {
    // Implement your language detection logic here
    return (
      this.translateService.currentLang || this.translateService.defaultLang
    );
  }
  onHistoryBack() {
    this.location.back();
  }
}
