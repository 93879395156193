<div class="container"  dir="auto" style="text-align: start;">
  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-5">
      <div class="map">
        <h3 class="title">{{'show_on_map'|translate}}</h3>
        <div class="img" *ngIf="searchRes && !searchRes.mapLat && !searchRes.mapLon">
          <img [src]="resreveInfo.mapImage" alt="" />
        </div>
        <ng-container *ngIf="searchRes && searchRes.mapLon && searchRes.mapLat">
          <google-map height="200px" width="100%" [options]="options">
            <map-circle [center]="circleCenter" [radius]="circleRadius" [options]="circleOptions"></map-circle>
          </google-map>
        </ng-container>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-7">
      <div class="info">
        <h3 class="title"> {{'important_information'|translate}}</h3>
        <div class="my-4">
          <div class="d-flex flex-column flex-sm-row ">
            <div class="d-flex align-items-center gap-1">
              <img src="../../assets/icons/ARROW 48.svg" alt="" />
              <p class="mb-0 description-title">{{'checkIn'|translate}}</p>
            </div>
            <p class="description-subtitle mb-0">
              {{ 
               (searchRes?.checkInTime ?? 0) && (searchRes?.checkInTime ?? 0) > 12 
                  ? ((searchRes?.checkInTime??0) - 12) + ':00 PM' 
                  : searchRes?.checkInTime + ':00 AM' 
              }}
            </p>  
               </div>
        </div>
        <div class="my-4">
          <div class="d-flex flex-column flex-sm-row">
            <div class="d-flex align-items-center gap-1">
              <img src="../../assets/icons/ARROW -1.svg" alt="" />
              <p class="mb-0 description-title">{{'checkOut'|translate}}</p>
            </div>
            <p class="description-subtitle mb-0">
              {{ 
               (searchRes?.checkOutTime ?? 0) && (searchRes?.checkOutTime ?? 0) > 12 
                  ? ((searchRes?.checkOutTime??0) - 12) + ':00 PM' 
                  : searchRes?.checkOutTime + ':00 AM' 
              }}
            </p>  
          </div>
        </div>
        <div class="my-4">
          <div class="d-flex flex-column flex-sm-row">
            <div class="d-flex align-items-center gap-1">
              <img src="../../assets/icons/info-buttons.svg" alt="" />
              <p class="mb-0 description-title">{{'cancel_reservation'|translate}}</p>
            </div>
            <p class="description-subtitle mb-0 l-h-1" [innerHTML]="resreveInfo.cancellationPolicy"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
