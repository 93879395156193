<table>
    <tr>
        <th></th>
        <!-- <th  class="space"></th> -->
        <th>{{'image'|translate}}</th>
        <th>{{'property_name'|translate}}</th>
        <th>{{'room_count'|translate}}</th>
        <!-- <th>{{'price_per_night'|translate}}</th> -->
        <th>{{'city'|translate}}</th>
        <th>{{'status'|translate}}</th>
    </tr>
  <tbody *ngFor="let row of propertyList; let i = index">
    <tr class="text-center" >
        <td >{{row?.id}}</td>
        <!-- <th class="space"></th> -->
        <td><img [src]="row.mainImage" alt=""></td>
        <td>{{!isFlipped?row?.nameAr:row?.nameEn}}</td>
        <td>{{row?.rooms}}</td>
        <!-- <td>{{lowesPriceRooms(row) | number:'1.2-2'}}</td> -->
        <td>{{row?.city}}</td>
        <td  [ngClass]="{'green': row.status === 'pending', 'purple': row.status === 'active'}">{{row.status === 'active' ? ('active'|translate) : ('inactive'|translate) }}</td>

        <td>
          <mat-slide-toggle *ngIf="row.status !== 'pending'" (change)="onChangeStatus(row)" [checked]="row.status === 'active' ? true : false"></mat-slide-toggle>
        </td>
        <td>
          <a role="button" (click)="onEditRoom(row.id)">
            <img class="icon-edit" src="assets/aroovia-icons/edit.svg" alt="edit-icon">
          </a>
        </td>
    </tr>
  </tbody>
</table>
<div class="my-4" *ngIf="propertyList && propertyList.length === 0">
  <p>{{'no_properties'|translate}}</p>
</div>
