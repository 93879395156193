import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { retry } from 'rxjs';
import { CategoryDTO } from './categories/category-dto';
import { WebsiteInfoDTO } from './home/website-info-dto';
import { PropertiesTypesDTO } from './new-property/property-dto';
import { PopUpComponent } from './pop-up/pop-up.component';
import { AuthenticationService } from './services/auth/authentication.service';
import { DataContextService } from './services/global-context/data-context.service';

export interface AdsDTO {
  id: number;
  type: string;
  perantId: number;
  isActive: number;
  title: string;
  body: string;
  createdAt: string;
  updatedAt: string;
  details: PropertiesTypesDTO | CategoryDTO;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'arovia';
  @BlockUI('root') rootBlocker!: NgBlockUI;
  @ViewChild('desktopView', { static: false }) desktopView!: ElementRef;
  isMobileVersion: boolean = false;
  isFlipped: boolean = false;

  constructor(
    private datacontext: DataContextService,
    private router: Router,
    private ActivatedRoute: ActivatedRoute,
    readonly viewportScroller: ViewportScroller,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([100, 0]);
        this.adminLogin(
          this.ActivatedRoute.snapshot?.queryParams['login-as-user'],
          this.ActivatedRoute.snapshot?.queryParams['propertyId']
        );
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  checkWindowSize() {
    const winWidth = window.innerWidth;
    if (winWidth < 768) {
      window.location.href = 'https://aroovia-landing-page.vercel.app/home'; // Redirect to the landing page
    }
  }

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is English
    });

    this.gettingGeneralData();
    this.checkWindowSize();
  }

  adminLogin(token: string | undefined, propertyId: number | undefined) {
    if (token) {
      this.datacontext
        .getData(`/auth/user/tokenCheck?token=${token}`)
        .subscribe((value: any) => {
          if (value.success) {
            this.datacontext.setUserData(value.data);
            this.authService.setToken('token', token);
            this.authService.setToken('userInfo', JSON.stringify(value.data));
            this.datacontext.showSuccess(
              this.translate.instant('login_successful'),
              ''
            );
            if (propertyId) {
              this.router.navigate(['/profile', 'new-property'], {
                queryParams: { new: 1, id: propertyId },
              });
            }
          }
        });
    }
  }

  websiteInfo: WebsiteInfoDTO;

  private gettingGeneralData() {
    // this.rootBlocker.start()

    this.datacontext
      .getData('/website-info')
      .pipe(retry(2))
      .subscribe((value: any) => {
        if (value.success) {
          this.websiteInfo = value.data;
          this.datacontext.setWebsiteInfo(this.websiteInfo);
        }
        // this.rootBlocker.stop()
      });
  }

  ngAfterViewInit(): void {
    this.gettingAds();
    // setTimeout(()=>{
    //   console.clear()
    // },500)
  }

  private gettingAds() {
    this.datacontext.getData('/ads').subscribe((data) => {
      const response = data as any;
      if (response.data) {
        const popup = sessionStorage.getItem('popup');
        if (!popup) {
          setTimeout(() => {
            this.dialog.open(PopUpComponent, {
              width: '824px',
              data: response.data,
              maxHeight: '552px',
              height: '552px',
            });
            sessionStorage.setItem('popup', 'true');
          }, 5000);
        }
      }
    });
  }
}
