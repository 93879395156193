import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-payments-list-manage',
  templateUrl: './payments-list-manage.component.html',
  styleUrls: ['./payments-list-manage.component.css'],
})
export class PaymentsListManageComponent implements OnInit {
  bankDetailsForm: FormGroup;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  isFlipped = false;
  constructor(
    private fb: FormBuilder,
    private dataContext: DataContextService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.bankDetailsForm = this.fb.group({
      name: ['', Validators.required],
      iban: [''],
      bank: ['', Validators.required],
      mobile: ['', Validators.required],
    });
  }
  @ViewChild('phoneNumber') inputTel: any;

  onSubmitBankInfo() {
    if (this.bankDetailsForm.invalid) {
      return this.bankDetailsForm.markAllAsTouched();
    }
    const newData = {
      ...this.bankDetailsForm.value,
      mobile: '+966' + this.bankDetailsForm.controls['mobile'].value,
      iban: 'SA' + this.bankDetailsForm.controls['iban'].value,
    };

    const token = this.dataContext.getRouteParams('token');
    this.dataContext
      .postData(`/vendor/bank/create?token=${token}`, newData)
      .subscribe((data: any) => {
        if (data.success) {
          this.dataContext.showSuccess(
            '',
            this.translate.instant('bank_added_successfully')
          );
          this.router.navigate(['/profile', 'payments-list']);
        }
      });
  }
}
