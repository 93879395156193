import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Renderer2,
  ElementRef,
  AfterViewInit,
  OnInit,
  AfterContentInit,
  AfterViewChecked,
} from '@angular/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, retry, throwError } from 'rxjs';
import { faqDTO } from './faq-dto';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent implements OnInit {
  @BlockUI('root') rootBlocker!: NgBlockUI;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private datacontext: DataContextService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {}
  isFlipped = false;

  ngOnInit(): void {
    this.gettingfaq();
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  faqList: faqDTO[];
  private gettingfaq() {
    // this.rootBlocker.start()
    this.datacontext
      .getData('/faq/list')
      .pipe(retry(2))
      .subscribe((value: any) => {
        if (value.success) {
          this.faqList = value.data;
        }
        // this.rootBlocker.stop()
      });
  }

  toggleActiveAccordion(index: number): void {
    const acc = this.el.nativeElement.getElementsByClassName(
      'accordion'
    ) as HTMLCollectionOf<HTMLElement>;

    acc[index].classList.toggle('active');
    const pannel = acc.item(index)?.nextSibling as HTMLElement;
    if (pannel.style.display === 'block') {
      pannel.style.display = 'none';
    } else {
      pannel.style.display = 'block';
    }
  }
  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
