import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, forkJoin, throwError } from 'rxjs';
interface statistic {
  available:number;
  fee:string;
  paidout:number;
  total:number;
}
@Component({
  selector: 'app-payment-history-list',
  templateUrl: './payment-history-list.component.html',
  styleUrls: ['./payment-history-list.component.css']
})
export class PaymentHistoryListComponent implements OnInit {

  constructor(private datacontext:DataContextService) {
    
  }

  listStatistc:statistic
  vendorList:any
  listBank:any
  paymentHistoryList:any
  ngOnInit(): void {
    this.gettingHistoryPayout()
  }

  private gettingHistoryPayout(){
    const token = this.datacontext.getRouteParams('token')
    const functions = [ this.datacontext.getData(`/vendor/payout/list?token=${token}`),  this.datacontext.getData(`/vendor/bank/list?token=${token}`)]
   forkJoin(functions).subscribe((data:any) => {
      if(data[0].success){
        this.listStatistc = data[0].statstics
        this.listBank = data[0].data
      }

      if(data[1].success){
        this.paymentHistoryList = data[1].data
        this.reducePaymentHistory()
      }
    })
  }

  private reducePaymentHistory(){
    let paymentHistory:any = []
    if(this.paymentHistoryList.length > 0){
      this.listBank.forEach((item:any) => {
        const vendorData = this.paymentHistoryList.find((vendor:any) => vendor.UserId === item.UserId && vendor.id === item.VendorBankAccountId)
        paymentHistory.push({
          id:item.id,
          value:item.value,
          vendorName:vendorData.name,
          mobile:vendorData.mobile,
          iban:vendorData.iban,
          bank:vendorData.bank,
          status:item.status
  
        })
      })
    }
    this.vendorList = paymentHistory
  }

  timerId:any
  onSearchOrders(e:Event){
    const inputValue = (e.target as HTMLInputElement).value

    if(this.timerId){
      clearTimeout(this.timerId)
      }
    this.timerId = setTimeout(() => {
      this.searchOrders(inputValue)
    }, 1000);
  }

  private searchOrders(name:string){
    // this.rootBlocker.start()
    const token = this.datacontext.getRouteParams('token')
    this.datacontext.getData(`/vendor/payout/list?token=${token}?search=${name}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(() => {
          // this.rootBlocker.stop()
          // this.datacontext.showError('',err.error.message)
        })
      })
    ).subscribe((value:any)=>{
      if(value.success){
        this.paymentHistoryList = value.data
        this.reducePaymentHistory()
      }
      // this.rootBlocker.stop()
    })
  }
  onExportExcel(){
    const token= this.datacontext.getRouteParams('token')
    // this.rootBlocker.start()
    if(this.paymentHistoryList.length === 0 ){
      return this.datacontext.showError('','لا يوجد بيانات')
    }
    this.datacontext.downloadExcelFile(`/vendor/payout/export-excel?token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          // this.rootBlocker.stop()
          this.datacontext.showError('','خطأ في تحميل الملف')
        })
      })
    ).subscribe((value:any)=>{
      const blob = new Blob([value], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'payment-history.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // this.rootBlocker.stop()
    })
  }
  onPDFExport(){
    const token= this.datacontext.getRouteParams('token')
    // this.rootBlocker.start()
    if(this.paymentHistoryList.length === 0 ){
      return this.datacontext.showError('','لا يوجد بيانات')
    }
    this.datacontext.downloadExcelFile(`/vendor/payout/export-pdf?token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          // this.rootBlocker.stop()
          this.datacontext.showError('','خطأ في تحميل الملف')
        })
      })
    ).subscribe((value:any)=>{
      const blob = new Blob([value], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'payment-history.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      // this.rootBlocker.stop()
    })
  }

  windowPrint(){
    window.print()
  }
}
