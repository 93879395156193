<div class="container" dir="auto" style="text-align: start;">
  <div id="nav-hidden">
    <app-navigation></app-navigation>
  </div>

  <div class="heading">
    <h2 class="title">{{'bank_details'|translate}}</h2>
    <p class="text-danger">{{'please_verify_and_match_information'|translate}}</p>
  </div>

  <div class="row  my-4">
    <div class="col-sm-12 col-md-8 my-4">
      <form [formGroup]="bankDetailsForm"  >
        <div class="col-8">

         <div class="d-flex gap-2">
           <span class="text-danger"> *</span>
          <label
          >{{'account_holder_name'|translate}}</label
        >
         </div>
          <div class="d-flex gap-2 my-4" >

            <input formControlName="name" type="text"
            [ngClass]="{'border-red':this.bankDetailsForm.controls['name'].errors && this.bankDetailsForm.controls['name'].touched}"/>
            <svg [matTooltip]="'bank_name_toolTip'|translate" matTooltipPosition="left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#000000" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"/></svg>
          </div>
          <div class="d-flex gap-2">
        <span class="text-danger"> *</span>

          <label
          >{{'iban_number'|translate}}</label
        >
      </div>
          <div dir="auto" style="text-align: start; " [ngStyle]="{
            'flex-direction': !isFlipped ? 'row-reverse' : 'row',
          }" class="d-flex gap-2 my-4">
            <div class="iban-container w-100">
            <div class="d-flex  " [ngStyle]="{
              'flex-direction': isFlipped ? 'row-reverse' : 'row',
            }" style="align-items: baseline; gap: 5px; ">
            <p>SA</p>

              <input formControlName="iban" appNumbersOnly type="text"
              [ngClass]="{'border-red':this.bankDetailsForm.controls['iban'].errors && this.bankDetailsForm.controls['iban'].touched}"/>
        
            </div>   
           </div>
            <svg [matTooltip]="'iban_toolTip'|translate" matTooltipPosition="left" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#000000" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"/></svg>
          </div>
          <div class="d-flex gap-2">
            <span class="text-danger"> *</span>
    
              <label
              >{{'bank_name'|translate}}</label
            >
          </div>
          <div class="d-flex gap-2 my-4 ">      
            <input formControlName="bank" type="text" placeholder=" {{'bank_name'|translate}} "
            [ngClass]="{'border-red':this.bankDetailsForm.controls['bank'].errors && this.bankDetailsForm.controls['bank'].touched}"/>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#000000" d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" hidden/></svg>
          </div>
          <div class="d-flex gap-2 my-4 payment-int flaggg" dir="auto">
            <!-- <ngx-intl-tel-input
            dir="auto"
            #phoneNumber
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="false"
            [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [selectFirstCountry]="true"
            [separateDialCode]="true"
            [onlyCountries]="[CountryISO.SaudiArabia]"
            [maxLength]="15"
            [phoneValidation]="true"
            inputId="my-input-id"
            name="phone"
            style="border-radius:10px !important; width: 100% !important;"
            [cssClass]="'payment-intel'"
            [selectedCountryISO]="CountryISO.SaudiArabia"
            formControlName="mobile"
            [ngClass]="{'border-red':this.bankDetailsForm.controls['mobile'].errors && this.bankDetailsForm.controls['mobile'].touched}"
          ></ngx-intl-tel-input> -->
          <div class="phone-input-container">
            <label style="text-align:start;" for="phone-input">
              <span  class="text-danger mx-1">*</span>
              <span class="phone-label"> {{ 'phone' | translate }}</span>
            </label>
            <div class="input-group">
              <div class="country-code">
                <img
                  src="assets/saudi-arabia-flag-icon.svg"
                  alt="Saudi Arabia Flag"
                  class="country-flag"
                />
                <span class="country-key">+966</span>
              </div>
              <input
                type="tel"
                [ngClass]="{'border-red':this.bankDetailsForm.controls['mobile'].errors && this.bankDetailsForm.controls['mobile'].touched}"
                name="phone"
                id="my-input-id"
                appNumbersOnly
                formControlName="mobile"
                pattern="[0-9]*"
                maxlength="10"
                inputmode="numeric"
              />
            </div>
          </div>
          </div>
        </div>
        <div class="col-6">
          <button type="button" class="action-button btnn" (click)="onSubmitBankInfo()">
            {{'save'|translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
