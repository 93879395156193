<div class="container-s container" dir="auto">
  <div class="title-cont"  [ngStyle]="{'text-align': isFlipped ? 'left' : 'right'}">
  <p class="title">{{'profile'|translate}}</p>
    <p class="subtitle">{{'update_personal_info'|translate}}</p>
  </div>
  <div class="table-responsive">
    <table class="responsive" [ngStyle]="{'text-align': currentLang === 'en' ? 'left' : 'right'}">
      <tr class="responsive" *blockUI="'fully-name'" >
        <td class="item">
          <div class="table-title">{{'full_name'|translate}}</div>
        </td>

        <td class="info-container">
          <form
            class="info responsive"
            [formGroup]="NameForm"
            (ngSubmit)="saveName()"
            *ngIf="nameForm"
          >
            <div class="name-container">
              <label>
                <span
                  class="label"
                  [ngClass]="{
                    wrong: this.NameForm.controls.firstName.status === 'INVALID'
                  }"
                  >{{'first_name'|translate}}</span
                ><span class="asterisk">*</span></label
              >
              <div class="first-name-input">
                <input type="text" formControlName="firstName" />
              </div>
            </div>
            <!-- //// -->
            <div>
              <label>
                <span
                  class="label"
                  [ngClass]="{
                    wrong: this.NameForm.controls.lastName.status === 'INVALID'
                  }"
                  >{{'last_name'|translate}}</span
                ><span class="asterisk">*</span></label
              >
              <div class="second-name-input">
                <input type="text" formControlName="lastName" />
              </div>
            </div>

            <div class="form-buttons">
              <button class="cancel" type="button" (click)="handelResetName()">{{'cancel'|translate}}</button>
              <button class="save" type="submit">{{'save'|translate}}</button>
            </div>
          </form>
          <div class="form-result" *ngIf="!nameForm">
            <p class="form-result-text">
              {{ NameForm.value.firstName }} {{ NameForm.value.lastName }}
            </p>
            <div>
              <button
                class="modify"
                (click)="showNameForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
              {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
      <!-- next -->
      <tr class="responsive" *blockUI="'email-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: emailForm.status === 'INVALID' }"
          >{{'email'|translate}}          </div>
        </td>
        <td>
          <form
            class="email-container"
            [formGroup]="emailForm"
            (ngSubmit)="saveEmail()"
            *ngIf="emailFormBool"
          >
            <div class="email-form">
              <!-- <label><span class="label">البريد الإلكتروني</span></label> -->
              <input
                id="name"
                type="text"
                formControlName="email"
                class="email-input"
              />
            </div>
            <div class="form-buttons">
              <button class="cancel" type="button" (click)="handelResetEmail()">{{'cancel'|translate}}</button>
              <button class="save" type="submit">{{'save'|translate}}</button>
            </div>
          </form>

          <div class="email-info form-result" *ngIf="!emailFormBool">
            <p class="form-result-text">
              {{ this.emailForm.value.email }}
              <span class="status status-green" *ngIf="!this.emailForm.value.email">{{'added_successfully'|translate}}</span>
              <!-- <span *ngIf="!this.emailForm.value.email">{{ this.emailForm.value.email }}</span> -->
            </p>
            <div>
              <button
                class="modify"
                (click)="showEmailForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >{{'edit'|translate}}             
             </button>
            </div>
          </div>
        </td>
      </tr>
      <!-- next -->
      <tr class="responsive" *blockUI="'phone-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: phoneForm.status === 'INVALID' }"
      >{{'mobile_number'|translate}}          </div>
        </td>
        <td>
          <form
            class="phone-container"
            *ngIf="phoneFormBool"
            (ngSubmit)="savePhone()"
          >
            <div>
              <div class="phone-form">
                <form [formGroup]="phoneForm">
                  <div class="area-code flex">
                    <div class="area-code-inner h-100">

                      <div class="flex flag">
                        <div class="phone-input-container">
                          <!-- <label style="text-align:start;" for="phone-input">
                            <span  class="text-danger mx-1">*</span>
                            <span class="phone-label"> {{ 'phone' | translate }}</span>
                          </label> -->
                          <div class="input-group">
                            <div class="country-code">
                              <img
                                src="assets/saudi-arabia-flag-icon.svg"
                                alt="Saudi Arabia Flag"
                                class="country-flag"
                              />
                              <span class="country-key">+966</span>
                            </div>
                            <input
                              type="tel"
                              name="phone"
                              id="my-input-id"
                              appNumbersOnly
                              formControlName="phoneNumber"
                              pattern="[0-9]*"
                              maxlength="10"
                              inputmode="numeric"
                            />
                          </div>
                        </div>                   
                        <!-- <ngx-intl-tel-input
                        #phoneNumber
                        [cssClass]="'custom border-0 w-100'"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="false"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="true"
                        [selectedCountryISO]="CountryISO.SaudiArabia"
                        [onlyCountries]="[CountryISO.SaudiArabia]"
                        [separateDialCode]="true"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        inputId="my-input-id"
                        name="phone"
                        formControlName="phoneNumber"
                      ></ngx-intl-tel-input> -->
                      </div>
                    </div>
                    <div>
                      <!-- <button class="modify" (click)="onSendOTP()">{{'send'|translate}}</button> -->
                    </div>
                  </div>
                </form>
              </div>
              <form
                [formGroup]="codeForm"
                class="fix-form"
                *ngIf="codeFormBool">
                <div class="confirmaion-code">
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-column align-items-center gap-2 justify-content-center ">
                      <div class="d-flex align-item-center justify-content-center">
                        <div class="d-flex align-items-center flex-column w-md-75 w-sm-25 w-xmd-80 w-50">
                          <div class="confirmation-title">
                            <span
                              class="confirmation-text"
      
                              >{{'verification_code'|translate}} </span
                            >
                            <span class="time">{{ formatTime(remainingTime) }}</span>
                            <p class="resend mb-0" style="opacity: 0;cursor: context-menu;"></p>
                          </div>
                          <div class="confirmation-input justify-content-center" >
                            <input
                              type="text"
                              placeholder="_"

                              formControlName="validationCodePhoneOne"
                              (input)="validateOneInteger($event)"
                              (keydown.tab)="moveToNextField($event, inputField2)"
                              #inputField1
                              (input)="moveToNextFieldAuto(inputField2)"
                              maxlength="1"/>
                            <input
                              type="text"

                              placeholder="_"
                              formControlName="validationCodePhoneTwo"
                              (input)="validateOneInteger($event)"
                              #inputField2
                              (keydown.tab)="moveToNextField($event, inputField3)"
                              (input)="moveToNextFieldAuto(inputField3)"
                              maxlength="1"/>
                            <input
                              type="text"
                              placeholder="_"
                              formControlName="validationCodePhoneThree"
                              (input)="validateOneInteger($event)"
                              #inputField3
                              (keydown.tab)="moveToNextField($event, inputField4)"
                              (input)="moveToNextFieldAuto(inputField4)"
                              maxlength="1"/>
                            <input
                              type="text"
                              placeholder="_"
                              formControlName="validationCodePhoneFour"
                              (input)="validateOneInteger($event)"
                              #inputField4
                              (keydown.tab)="moveToNextField($event, inputField1)"
                              (input)="moveToNextFieldAuto(inputField1)"
                              maxlength="1"/>
                          </div>
                        </div>
                        <p class="resend pointer mb-0 mt-4" (click)="onSendOTP()">{{'request_new_code'|translate}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="form-buttons special-height">
              <button
                class="cancel"
                (click)="handelResetPhone()"
              >
                {{'cancel'|translate}}
              </button>
              <button [disabled]="phoneForm.invalid" [ngClass]="{'btn-disabled': phoneForm.invalid}" class="save" type="submit" (click)="savePhone()">
{{'confirm'|translate}}              </button>
            </div>
          </form>

          <div class="phone-info form-result" *ngIf="!phoneFormBool">
            <p class="form-result-text"  dir="ltr">
              {{ datacontext.userData?.mobile }}
              <span class="status status-yellow" *ngIf="!datacontext.userData?.mobile">{{'not_verified'|translate}}</span>
            </p>
            <div>
              <button
                class="modify"
                (click)="showPhoneForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
                {{'edit'|translate}}              </button>
            </div>
          </div>
        </td>
      </tr>
      <!-- next -->
      <tr class="responsive" *blockUI="'date-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: dateForm.status === 'INVALID' }"
          >
             {{'date_of_birth'|translate}}
          </div>
        </td>
        <td>
          <form
            class="modif"
            [formGroup]="dateForm"
            (ngSubmit)="saveDate()"
            *ngIf="dateFormBool"
          >
            <div>
              <!-- <span class="label">تاريخ الميلاد</span> -->
              <div class="date">
                <!-- <input type="date" formControlName="date" #dateInput /> -->
                <input type="text"
                placeholder="mm/dd/yyyy"
                class="form-control shadow-none"
                formControlName="date"
                bsDatepicker
                
                [bsConfig]="bsConfig">

              </div>
            </div>
            <div class="form-buttons">
              <button class="cancel" (click)="handelResetBirthDate()">{{'cancel'|translate}}</button>
              <button class="save" type="submit">{{'save'|translate}}</button>
            </div>
          </form>
          <div class="form-result date-info" *ngIf="!dateFormBool">
            <p class="form-result-text">{{ dateForm.value.date ? (dateForm.value.date | date:'dd/MM/yyyy') : '' }}</p>
            <div>
              <button
                class="modify"
                (click)="showDateForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
            {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
      <!-- next -->
      <tr class="responsive" *blockUI="'nationality-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{
              wrong:
                nationalityForm.controls.nationality.status === 'INVALID' &&
                nationalityForm.controls.nationality.touched === true
            }"
          >
            <!--  [ngClass]="{'wrong': newRoom.controls.bedType.status==='INVALID' && newRoom.controls.bedType.touched===true}"-->
            {{'nationality'|translate}}
          </div>
        </td>
        <td>
          <form
            class="modif"
            [formGroup]="nationalityForm"
            (ngSubmit)="saveNationality()"
            *ngIf="nationalityFormBool"
          >
            <div>
              <!-- <span class="label">الجنسية</span> -->
              <div class="nationality">
                <!-- <input type="text" formControlName="nationality"> -->
                <select
                  name=""
                  class=" form-select custom-select shadow-none"
                  id=""
                  formControlName="nationality"
                  [ngClass]="{'bg-select-img':true}"
                >
                <option value="" disabled>{{'choose_nationality'|translate}}</option>
                <option *ngFor="let nationality of nationalitiesInArabic()" [value]="nationality">
                  {{ nationality }}
                </option>
                              </select>

              </div>
            </div>
            <div class="form-buttons">
              <button class="cancel" type="button" (click)="handelResetNationality()">{{'cancel'|translate}}</button>
              <button class="save" type="submit">{{'save'|translate}}</button>
            </div>
          </form>
          <div class="form-result" *ngIf="!nationalityFormBool">
            <p class="form-result-text">
              {{ nationalityForm.value.nationality }}
            </p>
            <div>
              <button
                class="modify"
                (click)="showNationalityForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
                {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
      <!-- next -->
      <tr class="responsive" *blockUI="'gender-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: genderForm.status === 'INVALID' }"
          >
{{'gender'|translate}}
   </div>
        </td>
        <td>
          <form
            class="modif"
            [formGroup]="genderForm"
            (ngSubmit)="saveGender()"
            *ngIf="genderFormBool"
          >
            <div>
              <!-- <span class="label">النوع</span> -->
              <div class="gender">
                <!-- <input type="text" formControlName="gender" /> -->
                <select class="form-select shadow-none custom-select" name="gender" id="gender" formControlName="gender" [ngClass]="{'bg-select-img':true}">
                  <option value="">{{'choose_gender'|translate}}</option>
                  <option value="male">{{'male'|translate}}</option>
                  <option value="female">{{'female'|translate}}</option>
                </select>
              </div>
            </div>
            <div class="form-buttons">
              <button class="cancel" type="button" (click)="handelResetGender()">{{'cancel'|translate}}</button>
              <button class="save" type="submit">{{'save'|translate}}</button>
            </div>
          </form>
          <div class="form-result" *ngIf="!genderFormBool">
            <p class="form-result-text">{{ genderForm.value.gender=== 'male' ? ('male'|translate) : ('female'|translate) }}</p>
            <div>
              <button
                class="modify"
                (click)="showGenderForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
                {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr class="responsive" *blockUI="'address-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: addressForm.status === 'INVALID' }"
          >
            {{'address'|translate}}
          </div>
        </td>
        <td>
          <form
            class="modif"
            [formGroup]="addressForm"
            (ngSubmit)="saveAddress()"
            *ngIf="addressBool"
          >
            <div>
              <!-- <span class="label">العنوان</span> -->
              <div class="address">
                <input type="text" formControlName="address" />
              </div>
            </div>
            <div class="form-buttons">
              <button class="cancel" type="button" (click)="handelResetAddress()">{{'cancel'|translate}}</button>
              <button class="save" type="save">{{'save'|translate}}</button>
            </div>
          </form>
          <div class="form-result" *ngIf="!addressBool">
            <p class="form-result-text">{{ addressForm.value.address }}</p>
            <div>
              <button
                class="modify"
                (click)="showAdressForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing"
              >
                {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>


      <tr class="responsive" *blockUI="'image-blocker'">
        <td class="item">
          <div
            class="table-title">
            {{'image'|translate}}
          </div>
        </td>
        <td>
          <form
          class="modif"
          [formGroup]="imageForm"
          (ngSubmit)="saveImage()"
          *ngIf="imageBool"
        >

            <div class="address">
              <input (change)="onImageChange($event)" type="file" formControlName="image" />
             <!-- <input type="text" id="license" [value]="imageUrl" style="cursor: not-allowed;" readonly> -->

            </div>

          <div class="form-buttons">
            <button class="cancel" type="button" (click)="handleResetImage()">{{'cancel'|translate}}</button>
            <button class="save" type="save">{{'save'|translate}}</button>
          </div>
        </form>

          <div class="form-result" *ngIf="!imageBool">
            <p class="form-result-text">
              <!-- {{ this.imageForm.value.image }} -->
              <span class="status status-green" *ngIf="this.emailForm.value.email">{{'added_successfully'|translate}}</span>
              <span *ngIf="!this.imageForm.value.image">{{this.imageForm.value.image }}</span>

            </p>
            <div>
              <button
                class="modify margin-end"
                (click)="showImageForm()"
                [ngClass]="{ grey: isEditing}"
                [disabled]="isEditing">
                {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr>


      <tr class="responsive" *blockUI="'passport-blocker'">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: idForm.status === 'INVALID' }">{{'id_or_passport'|translate}}          
          </div>
        </td>
        <td>
          <form
            class="modif"
            [formGroup]="idForm"
            (ngSubmit)="saveId()"
            *ngIf="idFormBool"
          >
              <!-- <span class="label">الهوية أو جواز السفر</span> -->
              <div class="id"><input type="file"  accept="image/jpeg, image/png, image/gif"  (change)="onPassportSelected($event)"/></div>
            <div class="form-buttons">
              <button class="cancel" (click)="this.idFormBool = false;this.isEditing = false;">{{'cancel'|translate}}</button>
              <button class="save">{{'save'|translate}}</button>
            </div>
          </form>

          <div class="form-result" *ngIf="!idFormBool">
            <p>
              <span *ngIf="datacontext.userData?.nationalId" class="status status-green ms-3 mt-4"> {{'added_successfully'|translate}}</span>
              <span *ngIf="!datacontext.userData?.nationalId"></span>
            </p>

            <div>
              <button
                class="modify margin-end"
                (click)="showIdForm()"
                [ngClass]="{ grey: isEditing }"
                [disabled]="isEditing">
                 {{'add'|translate}}              
              </button>
            </div>
          </div>
        </td>
      </tr>


      <!-- <tr class="responsive">
        <td class="item">
          <div
            class="table-title"
            [ngClass]="{ wrong: otherTravelersForm.status === 'INVALID' }"
          >
            المسافرون الآخرون
          </div>
        </td>
        <td>
          <form
            class="modif"
            (ngSubmit)="saveOtherTravelers()"
            *ngIf="otherTravelers"
          >
            <div>
              <div
                [formGroup]="otherTravelersForm"
                *ngFor="
                  let passengerGroup of passenger?.controls;
                  let i = index
                "
              >
                <div class="other-passangers">
                  <input type="text" formControlName="otherTravelers" />
                </div>
              </div>
              <div>
                <p (click)="addPassenger()" class="add-button pointer">+</p>
              </div>
            </div>
            <div class="form-buttons">
              <button class="cancel" (click)="onCancel()">{{'cancel'|translate}}</button>
              <button class="save">حفظ</button>
            </div>
          </form>
          <div class="form-result" *ngIf="!otherTravelers">
            <p
              class="form-result-text"
              *ngFor="let passengerGroup of passenger?.controls; let i = index"
            >
              {{ this.otherTravelersForm.value.passenger[i].otherTravelers }}
            </p>
            <div>
              <button
                class="modify"
                (click)="showOtherTravelers()"
                [ngClass]="{ grey: isEditing }"
              >
                {{'edit'|translate}}
              </button>
            </div>
          </div>
        </td>
      </tr> -->
    </table>
  </div>
</div>
