import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-review-reservations-card',
  templateUrl: './review-reservations-card.component.html',
  styleUrls: ['./review-reservations-card.component.css'],
})
export class ReviewReservationsCardComponent implements OnInit {
  reservationDetails: any = null;
  roomsChoosen: any = null;
  constructor(
    private datacontxt: DataContextService,
    private translate: TranslateService
  ) {}
  isFlipped = false;

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    const reservationDetails = JSON.parse(
      localStorage.getItem('constReservtionData')!
    );
    const roomDetails = JSON.parse(localStorage.getItem('roomsDetails')!);
    if (reservationDetails) {
      this.reservationDetails = reservationDetails;
      this.roomsChoosen = roomDetails;
    }
  }

  dateFormate(date: string): string {
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
    const day = date.split('/')[0];
    const choosenMonth = parseInt(date.split('/')[1]);
    const month = months[choosenMonth - 1];

    return `${day} ${month} ${date.split('/')[2]}`;
  }
}
