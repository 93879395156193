
<div  (click)="getChat(oneProp);"  class="d-flex flex-row-reverse justify-content-between container item ">
    <div class="d-flex flex-row-reverse">
        <div class="d-flex flex-row-reverse">
            <img class="img-icon" [src]="oneProp.Property?.mainImage">
            <div class="notification-icon"></div>
        </div>
        <div>
            <h1 class="hotel-name">{{oneProp.Property.nameAr}}</h1>    
            <!-- <h1 class="hotel-name">{{oneProp.User.firstName}}</h1>     -->
            <!-- <h1 class="hotel-name">{{oneProp.Property.id}}</h1>     -->
        </div>
    </div>
    <!-- <div class="arrow" dir="auto"> <i class="fa-solid fa-angle-left "></i></div> -->
</div>