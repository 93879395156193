<div class="container-s" id="over-print">
  <!-- <div class="back remove hovered-pointer w-25" (click)="backHistory()">

        <img src="../../assets/Icon feather-arrow-left.svg" alt="" class="img">
        <p class="back-title"> {{'go_back'|translate}}</p>
    </div> -->
  <app-navigation></app-navigation>
  <div class="middle-container" id="container-hidden">
    <div class="right align-item-center">
      <p class="title mb-0">{{ "view_orders" | translate }}</p>
      <div class="view-all mb-0 back" *ngIf="isAllOrders">
        <p role="button" routerLink="/profile/orders" class="view-all-txt">
          {{ "view_all" | translate }}
        </p>
        <img src="../../assets/Icon feather-arrow-left.svg" alt="" />
      </div>
    </div>
    <div class="left">
      <div class="search-input">
        <input
          (input)="onSearchOrders($event)"
          type="text"
          placeholder="{{ 'search' | translate }}"
          style="width: 271px"
        />
        <!-- <img src="../../assets/" alt=""> -->
      </div>
      <div class="actions">
        <p class="txt">{{ "export" | translate }}</p>
        <!-- <img src="../../assets/aroovia-icons/" alt=""> -->
        <div class="actions">
          <div class="export-img hovered-pointer" (click)="onExportExcel()">
            <img src="../../assets/New folder/Group 573.svg" alt="" />
          </div>
          <!-- <div class="export-img hovered-pointer " (click)="onPDFExport()">

                        <img src="../../assets/New folder/Group 572.svg" alt="">
                    </div>
                    <div class="export-img hovered-pointer " (click)="windowPrint()">

                        <img src="../../assets/New folder/Group 571.svg" alt="">
                    </div> -->
        </div>
      </div>
      <div class="filter">
        <!-- <p class="filter-txt">تصفية</p>
            <div class="filter-img-container">
                <img src="../../assets/New folder/Glyph.svg" alt="">
            </div> -->
      </div>
    </div>
  </div>
  <div class="filters">
    <div
      class="filter-container hovered-pointer grey-filter"
      (click)="filterOrders('all-orders')"
    >
      <span role="button"> {{ "all_orders" | translate }}</span>
    </div>
    <!-- <div class="filter-container hovered-pointer blue-filter" (click)="filterOrders('current')">
            <p role="button">طلبات جديدة</p>
        </div> -->
    <div
      class="filter-container hovered-pointer mint-filter"
      (click)="filterOrders('completed')"
    >
      <p>{{ "completed_orders_plural" | translate }}</p>
    </div>
    <div
      class="filter-container hovered-pointer pink-filter"
      (click)="filterOrders('cancelled')"
    >
      <p>{{ "canceled_orders_plural" | translate }}</p>
    </div>
    <div
      class="filter-container hovered-pointer light-purple-filter"
      (click)="filterOrders('refunded')"
    >
      <p>{{ "returned_orders_plural" | translate }}</p>
    </div>
    <div
      class="filter-container hovered-pointer peach-filter"
      (click)="filterOrders('pending')"
    >
      <p>{{ "awaiting_arrival_plural" | translate }}</p>
    </div>
    <!-- <div class="filter-container hovered-pointer purple-filter">
            <p>ضيوف مقيمين</p>
        </div> -->
  </div>
  <div class="table-responsive mx-2" id="table-container">
    <table>
      <tr class="header text-center">
        <th></th>
        <th>{{ "guest_name" | translate }}</th>
        <th>{{ "booking_location" | translate }}</th>
        <th>{{ "guests" | translate }}</th>
        <th>{{ "total_price" | translate }}</th>
        <th>{{ "city" | translate }}</th>
        <th>{{ "arrival_date" | translate }}</th>
        <th>{{ "departure_date" | translate }}</th>
        <th>{{ "rating_column" | translate }}</th>
        <th>{{ "details" | translate }}</th>
      </tr>
      <tbody>
        <tr *ngFor="let order of ordersList" class="body-design text-center">
          <td>{{ order.id }}</td>
          <td>{{ order.firstName + " " + order.lastName }}</td>
          <td [style.maxWidth]="'100px'">
            <span>{{ order.Property.address }}</span>
          </td>
          <td>{{ order.adults + order.infants + order.childerns }}</td>
          <td>{{ order.price }}</td>
          <!-- <td>{{order.Property.*city}}</td> -->
          <td>
            {{
              order.City && isFlipped
                ? order?.City?.nameEn?.length > 30
                  ? (order?.City?.nameEn | slice : 0 : 30) + "..."
                  : order?.City?.nameEn
                : order?.City?.nameAr?.length > 30
                ? (order?.City?.nameAr | slice : 0 : 30) + "..."
                : order?.City?.nameAr
            }}
          </td>
          <td>{{ order.startDate | date : "dd/MM/yyyy" }}</td>
          <td>{{ order.endDate | date : "dd/MM/yyyy" }}</td>
          <td>
            <span
              class="pill-shape p-2"
              [ngClass]="{
                'pink-filter':
                  order.reservationStatus == 'pendingPayment' ||
                  order.reservationStatus === 'rejected',
                'mint-filter':
                  order.reservationStatus == 'pending' ||
                  order.reservationStatus === 'completed',
                'blue-filter': order.reservationStatus == 'cancelled',
                'light-purple-filter': order.reservationStatus == 'refunded',
                'cancelled-color':
                  order.reservationStatus == 'pendingPaymentTimeout'
              }"
            >
              <span *ngIf="order.reservationStatus == 'pendingPayment'">
                {{ "pending_payment" | translate }}</span
              >
              <span
                *ngIf="order.reservationStatus == 'pending'"
                style="color: darkgreen !important"
                >{{ "suspended" | translate }}
              </span>
              <span *ngIf="order.reservationStatus == 'cancelled'">
                {{ "booking_cancelled" | translate }}</span
              >
              <span *ngIf="order.reservationStatus == 'pendingPaymentTimeout'"
                >{{ "unpaid" | translate }}
              </span>
              <span *ngIf="order.reservationStatus == 'refunded'">
                {{ "refund" | translate }}
              </span>
              <span *ngIf="order.reservationStatus == 'completed'">
                {{ "booking_ended" | translate }}
              </span>
              <span *ngIf="order.reservationStatus == 'rejected'">
                {{ "booking_rejected" | translate }}
              </span>
              <button
                class="accept ms-2"
                *ngIf="order.reservationStatus === 'pendingAcceptance'"
                (click)="onRejectOrder(order)"
              >
                {{ "reject_order" | translate }}
              </button>
              <button
                class="reject"
                *ngIf="order.reservationStatus === 'pendingAcceptance'"
                (click)="onAcceptOrder(order)"
              >
                {{ "accept_order" | translate }}
              </button>
            </span>
          </td>
          <td>
            <a style="cursor: pointer" (click)="handelOrderDetails(order)">
              <mat-icon>more_vert</mat-icon>
            </a>
          </td>
        </tr>
        <ng-container *ngIf="ordersList && ordersList.length === 0">
          <p>{{ "no_orders_found" | translate }}</p>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
