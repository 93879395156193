<div class="grey-container" *ngIf="websiteGeneralInfo" dir="auto" style="text-align: start;">
    <div class="padding">
        <div class="categories">
            <p class="categoryTitle">{{'select_your_preferred_place'|translate}}</p>
            <app-category-item [categories]="categoriesDetails"></app-category-item>
        </div>
        <div class="ts-center container grey-container">
          <div class="row mt-4">
              <div class="footer-list col-sm-12 col-md-4 col-lg-6">
                  <div class="list">
                      <!-- <p class="list-one-title">{{'select_your_preferred_place'|translate}}</p>
                      <p class="list-one-subtitle">{{'select_your_preferred_place'|translate}}</p> -->
                      <p class="list-one-subtitle">{{'support'|translate}}</p>
                      <p class="list-one-subtitle">
                        <a class="text-danger " style="width: fit-content;"  href="/profile/technical-support"><br>
                          {{'for_technical_support_and_inquiries'|translate}}
                            <!-- <span class="fs-9" style="text-align: auto;" dir="auto">
                              966558527859+
                            </span> -->
                          </a>
                      </p>
                  </div>

              </div>
              <div class="col-sm-12 col-md-8 col-lg-6">
                <div class="row">
                  <div class="col-sm-12 col-md-9 col-lg-9">
                      <div class="footer-list ">
                          <div class="list">
                              <p class="terms-and-conditions-subtitle cursor" routerLink="/privacy-policy">
                                {{'privacy_agreement'|translate}}
                              </p>
                              <p class="terms-and-conditions-subtitle cursor" routerLink="/tos">
                                {{'terms_and_conditions'|translate}}
                              </p>
                              <p class="terms-and-conditions-subtitle cursor" routerLink="/usage-policy">
                                {{'terms_of_use'|translate}}
                              </p>
                              <p class="terms-and-conditions-subtitle cursor" routerLink="/cancellation-policy">
                                {{'cancellation_policy'|translate}}
                              </p>
                          </div>

                      </div>

                  </div>
                  <div class="icons list col-sm-12 col-md-3 col-lg-3">
                      <div>
                        <a *ngFor="let social of websiteGeneralInfo.socialMedia"  [href]="social.url" target="_blank">
                          <img [src]="social.image" alt="" class="social-media-icons">
                        </a>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div class="footer-bottom">
            <div class="allrightsreserved">
              <p> {{'rights_reserved'|translate}}</p>
            </div>
            <div>
              <div style="display: flex; align-items: center; gap: 5px;">
                <p> {{'design_and_programming'|translate}}</p>
                <img src="assets/sweftat.svg" alt="sweftat-logo" width="40" height="40" style="margin-bottom: 10px;">
              </div>

            </div>
        </div>
    </div>
</div>
