<div class="custom-datepicker p-4">
  <div class="text-center  position-relative">
    <span
    role="button"
    class="btn-sm position-absolute shadow-none cust-btn"
    (click)="HandelCloseDialog()"
  >
    <mat-icon mat-button mat-dialog-close >close</mat-icon>
  </span>
    <bs-daterangepicker-inline
      [bsConfig]="bsConfig"
      (bsValueChange)="onDateChange($event)"
      [minDate]="minDate"
      dir="auto" 
      
    >
    <!-- No Time I had to  -->
    </bs-daterangepicker-inline>
  </div>
  <div
    class="d-flex align-items-center justify-content-center"
    (click)="HandleConfirm()"
  >
    <button style="background-color: #EF3C23; " class="btn btn-sm btn-outline-light x-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
      >
        <path
          fill="#ffffff"
          d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275t.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275z"
        />
      </svg>
    </button>
  </div>
</div>
