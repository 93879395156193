import { Injectable, OnInit } from '@angular/core';
import { userData } from '../auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { BehaviorSubject } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
const API_URL = 'https://api.aroovia.com/api'
// const API_URL = 'http://localhost:4050'
@Injectable({
  providedIn: 'root'
})
export class DataContextService implements OnInit {
  userData:userData | null = null
  websiteInfo: BehaviorSubject<WebsiteInfoDTO> =  new BehaviorSubject<WebsiteInfoDTO>({} as WebsiteInfoDTO);
  favoritesSubject = new BehaviorSubject<Set<number>>(new Set());
  private readonly userInfoUrl = 'https://www.googleapis.com/oauth2/v3/userinfo';
  lang='ar'

  constructor(private toaster:ToastrService,private http: HttpClient,private translate: TranslateService) { }

  ngOnInit(): void {
    // Set initial value based on current language

    this.lang = this.translate.currentLang === 'en'? 'en' : 'ar';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang === 'en'?'en':'ar'; // Check if the new language is Arabic
    });
  }
  setUserData(user:userData | null){
    this.userData = user
  }

  setRouteParams(key:string,value:any){
    localStorage.setItem(key,value)
  }

  getRouteParams(key:string): string |null{
    return localStorage.getItem(key)
  }

  setLocalStroage(key:string,value:any){
    localStorage.setItem(key,value)
  }

  removeRouteParams(key:string){
    localStorage.removeItem(key)
  }

  showError(title:string,message:string){
    this.toaster.error(message,title)
  }

  showSuccess(title:string,message:string){
    this.toaster.success(message,title)
  }
  showInfoMessage(title:string,message:string){
    this.toaster.info(message,title)
  }
  postData(url:string, data:any, params?:any){
    return this.http.post(`${API_URL}`+url, data, params)
  }

  getChatList(url:string){
    return this.http.get(`${API_URL}${url}`);
  }

  postMessage(url:string, body:any, params?:any){
    return this.http.post(`${API_URL}${url}`, body, params);
  }

  getData(url:string){
    const headers = new HttpHeaders().set('locale', this.lang); // Replace 'en-US' with your desired locale

    return this.http.get(`${API_URL}` + url, { headers });
  
  }

  downloadExcelFile(url:string){
    return this.http.get(`${API_URL}`+url,{
      responseType: 'blob' as 'json'
    })
  }

  setWebsiteInfo(websiteInfo: WebsiteInfoDTO){
    this.websiteInfo.next(websiteInfo)
  }
  convertDateAPI(date:string) : string{ // swaping the date and return a valid date
    let dateArr = date.split('/')
    let swap1 = dateArr[0]
    let swap2 = dateArr[1]

    dateArr[0] = swap2
    dateArr[1] = swap1


    return  dateArr.join('/')
  }

  handelSearchDateConvert(startDate:string,endDate:string){
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];


  }
  toggleFavorite(id: number): void {
    const currentFavorites = this.favoritesSubject.value;
    if (currentFavorites.has(id)) {
      currentFavorites.delete(id);
    } else {
      currentFavorites.add(id);
    }
    this.favoritesSubject.next(new Set(currentFavorites));
  }


  getUserInfo(accessToken: string) {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);
    return this.http.get(this.userInfoUrl, { headers });
  }

}
