import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/services/auth/authentication.service';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { catchError, forkJoin, retry, throwError } from 'rxjs';
declare var google: any;

interface googleResponseDetails {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  picture: string;
  sub: string;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {
  loginFormPhone: FormGroup;
  validationFormPhone: FormGroup;
  loginEmailForm: FormGroup;
  codeFormPhoneView: boolean = false;
  loginFormPhoneView: boolean = true;
  emailFormView: boolean = false;
  phoneButton: boolean = false;
  emailButton: boolean = false;
  addressBool: boolean = false;
  isModelOpen: boolean = true;
  isFlipped = false;
  @BlockUI() blockUI!: NgBlockUI;
  private client: any;

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private route: Router,
    private authService: AuthenticationService,
    private datacontext: DataContextService,
    private ch: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    if (this.route.url.includes('/signin')) this.isModelOpen = false;
    this.loginFormPhone = this.fb.group({
      mobile: [
        '',
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(10),
          Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
        ],
      ],
    });
    this.validationFormPhone = this.fb.group({
      validationCodePhoneOne: [
        '',
        [Validators.required, this.numberValidation(/^\d{1}$/)],
      ],
      validationCodePhoneTwo: [
        '',
        [Validators.required, this.numberValidation(/^\d{1}$/)],
      ],
      validationCodePhoneThree: [
        '',
        [Validators.required, this.numberValidation(/^\d{1}$/)],
      ],
      validationCodePhoneFour: [
        '',
        [Validators.required, this.numberValidation(/^\d{1}$/)],
      ],
    });
    this.loginEmailForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
        ],
      ],
    });
    if (this.loginFormPhone) {
      this.phoneButton = true;
      this.emailButton = false;
    }
    if (this.loginEmailForm) {
      this.phoneButton = false;
      this.emailButton = true;
    }
  }

  ngOnInit(): void {
    this.client = google.accounts.oauth2.initTokenClient({
      client_id:
        '162526198800-kgcnl7c8tkbmko1amfi8ebr01hnuoo9u.apps.googleusercontent.com',
      scope: 'profile email',
      callback: (response: any) => {
        const token = response.access_token;
        this.gettingUserInfo(token);
      },
    });

    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }

  private gettingUserInfo(token: string) {
    this.datacontext.getUserInfo(token).subscribe((res: any) => {
      const response = res as googleResponseDetails;
      this.loginEmailForm.controls['email'].setValue(response.email);
      this.onSubmitEmail();
    });
  }

  timer: any;
  remainingTime: number = 120; // 2 minutes in seconds
  startTimer() {
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
    } else {
      clearInterval(this.timer);
    }
  }
  @ViewChild('intleInput') inputTel: any;
  selectedNumber: any;
  onSubmitPhone() {
    console.log(this.loginFormPhone.controls['phone']);
    console.log(this.loginFormPhone.controls['mobile']);
    console.log(this.loginFormPhone.controls);
    console.log(this.loginFormPhone);
    if (this.loginFormPhone?.status === 'VALID') {
      this.selectedNumber =
        '+966' + this.loginFormPhone.controls['mobile'].value;
      this.blockUI.start();
      const bodyData = {
        mobile: '+966' + this.loginFormPhone.controls['mobile'].value,
      };
      this.datacontext
        .postData(
          `/auth/user/login`,
          bodyData,
          this.route.lastSuccessfulNavigation?.extras.state?.['url']
        )
        .pipe(retry(2))
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.codeFormPhoneView = true;
              this.loginFormPhoneView = false;
              this.tempOTP = value;
              this.datacontext.showSuccess(
                '',
                this.translate.instant('verification_code_sent_to_phone')
              );
              this.ch.detectChanges();
              this.firstOtpInput.nativeElement.focus();
              this.startTimer();
            }
            this.blockUI.stop();
          },
        });
    }
  }

  formatTime(seconds: number): string {
    const minutes: string = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds: string = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  }

  @ViewChildren('inputOne,inputTwo,inputThree,inputFour') OTPdigits: QueryList<
    ElementRef<HTMLInputElement>
  >;
  handelPasteFeature(e: ClipboardEvent) {
    const cliped = e.clipboardData;

    if (cliped) {
      const text = cliped.getData('text').toString();

      ///@ts-ignore
      if (text.length === 4 && !isNaN(text)) {
        const controlsKeys = Object.keys(this.validationFormPhone.controls);
        this.OTPdigits.forEach((item, index) => {
          item.nativeElement.value = text.charAt(index);
          this.validationFormPhone.controls[controlsKeys[index]].patchValue(
            text.charAt(index)
          );
        });

        this.onCheckOTP();
      }
    }
  }

  tempOTP: { success: string; message: string; id: string } | null = null;
  emailFound = '';
  onSubmitEmail() {
    this.emailFound = '';
    if (this.loginEmailForm.status === 'VALID') {
      this.blockUI.start();
      const newData = {
        ...this.loginEmailForm.value,
        id: this.tempOTP?.id,
      };
      const functions = [
        this.datacontext.postData(
          '/auth/user/exist-email',
          this.loginEmailForm.value
        ),
        this.datacontext.postData(
          `/auth/user/login`,
          this.loginEmailForm.value
        ),
      ];
      forkJoin(functions)
        .pipe(retry(2))
        .subscribe({
          next: (value: any) => {
            if (value[1].success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('verification_code_sent')
              );
              this.tempOTP = value[1];
              this.codeFormPhoneView = true;
              this.emailFormView = false;
              this.loginFormPhoneView = false;
              this.startTimer();
            } else {
              this.datacontext.showError(
                this.translate.instant('an_error_occurred'),
                ''
              );
            }

            if (value[0].success) {
              if (value[0].message.includes('exist')) {
                this.emailFound = this.loginEmailForm.value.email;
              } else {
                this.emailFound = '';
              }
            }
            this.blockUI.stop();
          },
        });
    }
  }

  numberValidation(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = nameRe.test(control.value);
      // return { forbiddenName: { value: control.value } }
      return !forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }
  viewPhoneLogin() {
    this.loginFormPhoneView = true;
    this.emailFormView = false;
    this.emailButton = true;
    this.phoneButton = false;
    this.codeFormPhoneView = false;
    this.emailFound = '';
    this.loginEmailForm.get('email')?.setValue('');
    this.loginEmailForm.get('email')?.markAsPristine();
    this.loginEmailForm.get('email')?.markAsUntouched();
  }

  viewEmailLogin() {
    this.loginFormPhoneView = false;
    this.emailFormView = true;
    this.emailButton = false;
    this.phoneButton = true;
    this.codeFormPhoneView = false;
    this.emailFound = '';
    this.loginFormPhone.get('phoneNumber')?.setValue('');
    this.loginFormPhone.get('phoneNumber')?.markAsPristine();
    this.loginFormPhone.get('phoneNumber')?.markAsUntouched();
  }

  // nextElement(element:HTMLElement, currentElement: string){

  //   console.log(this.validationFormPhone.controls[currentElement].valid, this.validationFormPhone.controls[currentElement])
  //   if(this.validationFormPhone.controls[currentElement].valid){
  //     element.focus()
  //   }
  // }

  onCheckOTP() {
    if (this.validationFormPhone.invalid)
      return this.datacontext.showError(
        '',
        this.translate.instant('incorrect_data')
      );
    const bodyData = {
      otp:
        this.validationFormPhone.controls['validationCodePhoneOne'].value +
        this.validationFormPhone.controls['validationCodePhoneTwo'].value +
        this.validationFormPhone.controls['validationCodePhoneThree'].value +
        this.validationFormPhone.controls['validationCodePhoneFour'].value,
      id: this.tempOTP?.id,
    };

    this.blockUI.start();
    this.datacontext
      .postData(`/auth/user/login`, bodyData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            this.blockUI.stop();
            this.datacontext.showError(
              '',
              this.translate.instant('incorrect_code')
            );
          });
        })
      )
      .subscribe({
        next: (value) => {
          const responseValue: {
            success: string;
            message: string;
            token: string;
            data: any;
          } = value as any;

          if (responseValue.success) {
            this.datacontext.setUserData(responseValue.data);
            this.authService.setToken('token', responseValue.token);
            this.authService.setToken(
              'userInfo',
              JSON.stringify(responseValue.data)
            );
            this.datacontext.showSuccess(
              this.translate.instant('login_successful'),
              ''
            );
            if (this.route.getCurrentNavigation()?.extras.state?.['url'])
              this.route.navigate([
                this.route.getCurrentNavigation()?.extras.state?.['url'],
              ]);
            else this.route.navigate([this.route.url]);
            this.dialog.closeAll();
          } else {
            this.datacontext.showError(
              this.translate.instant('an_error_occurred'),
              ''
            );
          }
          this.blockUI.stop();
        },
      });
  }

  @ViewChild('inputOne') firstOtpInput: ElementRef<HTMLInputElement>;
  @ViewChild('inputTwo') secondOtpInput: ElementRef<HTMLInputElement>;
  @ViewChild('inputThree') thirdOtpInput: ElementRef<HTMLInputElement>;
  @ViewChild('inputFour') fourthOtpInput: ElementRef<HTMLInputElement>;
  onFoucsFirstOTPinput(isValid: boolean, isTouched: boolean) {
    const digit1 =
      this.validationFormPhone.controls['validationCodePhoneOne'].value;
    const digit2 =
      this.validationFormPhone.controls['validationCodePhoneTwo'].value;
    const digit3 =
      this.validationFormPhone.controls['validationCodePhoneThree'].value;
    const digit4 =
      this.validationFormPhone.controls['validationCodePhoneFour'].value;
    // if(isTouched && !isValid && !digit1 && !digit2 && !digit3 && !digit4){
    //   this.firstOtpInput.nativeElement.focus()
    // }
    if (!digit1) return this.firstOtpInput.nativeElement.focus();
    if (!digit2) return this.secondOtpInput.nativeElement.focus();
    if (!digit3) return this.thirdOtpInput.nativeElement.focus();
    if (!digit4) return this.fourthOtpInput.nativeElement.focus();
  }

  onSignInWithGoogle() {
    // this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)
    this.client.requestAccessToken();
  }
}
