import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {
   @Input('appPrevOnBackspace') prevElement!: HTMLElement | null;
   @Input('appNextOnValid') nextElement!: HTMLElement | null;

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const initialValue = input.value;
    input.value = initialValue.replace(/[^0-9]*/g, '');

    if (initialValue !== input.value) {
      this.el.nativeElement.dispatchEvent(new Event('input'));
    }

    if (this.control.valid && this.nextElement) {
      this.nextElement.focus();
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' && this.prevElement) {
      const input = this.el.nativeElement as HTMLInputElement;
      input.value = '';
      this.el.nativeElement.dispatchEvent(new Event('input'));
      this.prevElement.focus();
      event.preventDefault();
    }
  }

}