import { Component } from '@angular/core';
import { DataContextService } from '../services/global-context/data-context.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent {
  html: SafeHtml = '';
  constructor(
    private DataContextService: DataContextService,
    private DomSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.DataContextService.websiteInfo.subscribe((d: WebsiteInfoDTO) => {
      this.translate.currentLang == 'en'
        ? (this.html = this.DomSanitizer.bypassSecurityTrustHtml(
            d.privacyPolicyEn
          ))
        : (this.html = this.DomSanitizer.bypassSecurityTrustHtml(
            d.privacyPolicyAr
          ));
    });
  }
}
