<div class="contianer container">
    <div class="just flex">
        <div class="flex-arrow">
            <div class="arrow-icon">
              <img src="assets/aroovia-icons/Icon feather-arrow-left.svg" alt=""  [ngClass]="{ 'flipped-arrow': isFlipped }">
            </div>
            <div class="title ">
                {{'you_may_like'|translate}}
            </div>
        </div>

        <div class="flex-filter hovered-pointer ">
            <!-- <div class="btn-group w-100 mt-2" dropdown #dropdown="bs-dropdown" [insideClick]="true" [autoClose]="true">
              <div dropdownToggle  class=" d-flex gap-1">
                <div class="filter-title">
                  تصفية
                </div>

                <div class="filter-icon">
                    <div class="purple">
                        <img src="../../assets/aroovia-icons/Filter.svg" alt="" style="border-radius: 15px;">
                    </div>
                </div>
              </div>
              <ul
                *dropdownMenu
                id="dropdown-animated"
                class="dropdown-menu p-4 w-100 row"
                role="menu"
              >
                <li role="menuitem" class="my-3">
                    hello filter
                </li>
              </ul>
            </div> -->
        </div>


    </div>
    <div class="cards">
        <app-card [cardsSearch]="searchResult"></app-card>
    </div>
</div>

