

<div class="navigation    container"  dir="auto" style="text-align: start;">
  <div role="button" (click)="onHistoryBack()" style="display: flex; gap: 8px; align-items: center;" class="hovered-pointer">
    <img [ngClass]="{'flip-horizontal': isLangEn}" src="../../assets/Icon feather-arrow-left.svg">   
    <h6 class="back-text" >{{'go_back'|translate}}</h6>
  </div>
</div>

<!-- <div role="button" class="navigation hovered-pointer" (click)="onHistoryBack()" *ngIf="!back">
    <h6 class="back-text">الرجوع للخلف</h6>
    <a role="button" >
      <img src="../../assets/Icon feather-arrow-left.svg">
    </a>
</div>

<a style="text-decoration: none; color: black;" role="button" class="navigation hovered-pointer" [routerLink]="back" *ngIf="back">
  <h6 class="back-text">الرجوع للخلف</h6>
  <p>
    <img src="../../assets/Icon feather-arrow-left.svg">
  </p>
</a> -->
