import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicDate'
})
export class ArabicDatePipe implements PipeTransform {
  transform(value: string, includeYear: boolean = true): string {
    const months = [
      'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو',
      'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
    ];

    const dateObj = new Date(value);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    if (includeYear) {
      return `${day} ${month} ${year}`;
    } else {
      // Custom logic to handle start date without the year
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();

      return year === currentYear ? `${day} ${month}` : `${day} ${month} ${year}`;
    }
  }
}
