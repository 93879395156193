<div class="p-4">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center justify-content-center">
                    <h3>{{'confirm_logout'|translate}}</h3>
                </div>
            </div>
        </div>
        <div class="mt-4 mb-2 d-flex align-item-center gap-4">
            <button class="action-button" (click)="closeDialog()">{{'not_now'|translate}}</button>
            <button class="secondary" (click)="handelLogOut()">{{'yes'|translate}}</button>
        </div>
    </div>
</div>