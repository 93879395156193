import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, retry, throwError } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @BlockUI('root') rootBlocker!: NgBlockUI;
  propertysMayLikeList: SearchDTO[];
  constructor(
    private datacontext: DataContextService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.gettingPeopleMayLike();
  }

  private gettingPeopleMayLike() {
    // this.rootBlocker.start()
    const token = this.datacontext.getRouteParams('token');
    let url;
    if (token) {
      url = `/search/guest-prefer?token=${token}`;
    } else {
      url = `/search/guest-prefer`;
    }
    this.datacontext
      .getData(url)
      .pipe(retry(2))
      .subscribe((value: any) => {
        if (value.success) {
          this.propertysMayLikeList = value.data;
          const newSetOfItems = new Set<number>();
          this.propertysMayLikeList.forEach((item) => {
            if (item.isFavorite) {
              newSetOfItems.add(item.id);
              this.datacontext.favoritesSubject.next(newSetOfItems);
            }
          });
        }
        // this.rootBlocker.stop()
      });
  }

  handelToggleLike(property: SearchDTO) {
    // this.rootBlocker.start()
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .getData(`/wishlist/add-remove/${property.id}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          property.isFavorite = !property.isFavorite;
          this.datacontext.toggleFavorite(property.id);
          // this.datacontext.showSuccess('','تم التحديث بنجاج')
        }
        // this.rootBlocker.stop()
      });
  }
}
