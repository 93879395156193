<block-ui>
  <div class="container" mat-dialog-content>
    <div class="left rtl" *ngIf="isModelOpen">
      <mat-icon mat-button mat-dialog-close>close</mat-icon>
    </div>
    <div class="customContainer my-3">
      <!-- *ngIf="loginFormPhoneView && loginEmailForm" -->
      <p class="greeting my-4"> {{'welcome'|translate}}</p>
      <!-- <p *ngIf="emailFound" class="text-primary">{{emailFound}}</p> -->
      <div class="confirmation-contianer" *ngIf="codeFormPhoneView">
        <p class="confirmation-title mb-2"> {{'verification_code'|translate}}</p>
        <p class="confirmation-subtitle mb-2"> {{'code_sent_to'|translate}}</p>
        <p class="confirmation-contact" *ngIf="loginEmailForm.get('email')?.value !== ''">{{
          loginEmailForm.get('email')?.value }}</p>
        <p class="confirmation-contact" *ngIf="loginEmailForm.get('email')?.value === ''">
        {{selectedNumber}}
        </p>
      </div>
      <form [formGroup]="loginFormPhone" (ngSubmit)="onSubmitPhone()" *ngIf="loginFormPhoneView">
        <div class="input-container phoneNumberContainer" [ngClass]="{'green-border': loginFormPhone.get('mobile')?.valid}">
          <div class="areacode elImport">
            <!-- <p style="text-align: left; padding-left: 30px; opacity: 0;" class="mb-0">رقم الجوال</p> -->
            <!-- <ngx-intl-tel-input #intleInput [cssClass]="'custom border-0 w-100'" [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.SaudiArabia" [separateDialCode]="true" [maxLength]="15"
              [onlyCountries]="[CountryISO.SaudiArabia]"
              
              [phoneValidation]="true" (keyup.enter)="onSubmitPhone()"
               inputId="my-input-id" name="phone"
              formControlName="mobile"></ngx-intl-tel-input> -->
              <div class="phone-input-container">
             
                <div class="input-group">
                  <div class="country-code">
                    <img
                      src="assets/saudi-arabia-flag-icon.svg"
                      alt="Saudi Arabia Flag"
                      class="country-flag"
                    />
                    <span class="country-key">+966</span>
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    id="my-input-id"
                    appNumbersOnly
                    formControlName="mobile"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    maxlength="10"

                  />
                </div>
              </div>
              
          </div>
        </div>
        <div class="my-4">
          <button type="submit" [ngClass]="{'btn-disabled':loginFormPhone.invalid}" class="action-button"
            [disabled]="!loginFormPhone.valid">
            {{'continue'|translate}}
          </button>
        </div>
      </form>

      <form [formGroup]="validationFormPhone" *ngIf="codeFormPhoneView">
        <div (click)="onFoucsFirstOTPinput(validationFormPhone.valid,validationFormPhone.touched)" class="code"
          [ngClass]="{
            'green-border': validationFormPhone.valid,
            'red-border':
              !validationFormPhone.valid && validationFormPhone.touched == true
          }">
          <div class="code-container">
            <input (paste)="handelPasteFeature($event)" type="text" class="validation-code-input" placeholder="_"
              formControlName="validationCodePhoneOne" #inputOne maxlength="1" appNumbersOnly
              [appPrevOnBackspace]="null" [appNextOnValid]="inputTwo" />
            <input type="text" (paste)="handelPasteFeature($event)" class="validation-code-input" placeholder="_"
              formControlName="validationCodePhoneTwo" #inputTwo maxlength="1" appNumbersOnly
              [appPrevOnBackspace]="inputOne" [appNextOnValid]="inputThree" />
            <input type="text" (paste)="handelPasteFeature($event)" class="validation-code-input" placeholder="_"
              formControlName="validationCodePhoneThree" #inputThree maxlength="1" appNumbersOnly
              [appPrevOnBackspace]="inputTwo" [appNextOnValid]="inputFour" />
            <input type="text" (paste)="handelPasteFeature($event)" class="validation-code-input" placeholder="_"
              maxlength="1" formControlName="validationCodePhoneFour" #inputFour appNumbersOnly
              [appPrevOnBackspace]="inputThree" [appNextOnValid]="null" />
          </div>
        </div>
        <button type="submit" class="submitButton-code" (click)="onCheckOTP()"> {{'confirm_code'|translate}}</button>
        <div>
          <p class="duration-text">   {{'code_expiry'|translate}} {{formatTime(remainingTime)}} {{'minute'|translate}}</p>
        </div>
        <div class="incorrect-container" *ngIf="
            validationFormPhone?.invalid && validationFormPhone.touched == true
          ">
          <p class="incorrect-text" >{{'incorrect_code'|translate}}</p>
        </div>
      </form>

      <form  *ngIf="emailFormView" [formGroup]="loginEmailForm" (ngSubmit)="onSubmitEmail()">
        <div class="input-email-container" [ngClass]="{
            'green-border': loginEmailForm.valid,
            'is-invalid':
              loginEmailForm.get('email')?.touched &&
              loginEmailForm.get('email')?.errors
          }">
          <p  class="input-label input-auto-dir"  [dir]="!isFlipped ? 'ltr' : 'rtl'" > {{'email'|translate}}</p>
          <input type="email" formControlName="email" placeholder="name@company.com" class="email-input" />
        </div>
        <div class="my-3">
          <button type="submit" class="action-button" [ngClass]="{ 'btn-disabled': !loginEmailForm.valid }">
            {{'continue'|translate}}
          </button>
        </div>
      </form>
    </div>
    <div class="divider">
      <div class="divider-inner"></div>
      <p class="divider-text">{{'use_different_email_or_login'|translate}}</p>
      <div class="divider-inner"></div>
    </div>

    <div class="signin-options">
      <div class="icons ">
        <div class="image pointer" (click)="viewPhoneLogin()" *ngIf="phoneButton">
          <img src="../../assets/aroovia-icons/phone-call-2.svg" alt="" />
        </div>
        <div class="image pointer" (click)="viewEmailLogin()" *ngIf="emailButton">
          <img src="../../assets/aroovia-icons/email-3.svg" alt="" />
        </div>
        <!-- <div class="image pointer">
          <img src="../../assets/aroovia-icons/apple-logo.svg" alt="" class="apple-logo" />
        </div> -->
        <!-- <button class="btn m-0 p-0" id="google-signin"  (click)="onSignInWithGoogle()">
        <div class="image pointer">
            <img src="../../assets/aroovia-icons/google-2.svg" alt="" />
          </div>
        </button> -->
        <!-- <div class="image pointer">
          <img src="../../assets/aroovia-icons/facebook-2.svg" alt="" />
        </div> -->
      </div>
      <p class="icon-text">
        {{'registration_terms'|translate}}      </p>
    </div>
  </div>
</block-ui>
