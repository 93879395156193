<div class="container-custom container"  dir="auto" style="text-align: start;">


<div class="container">

  <h4 class="label">{{ 'favorites'|translate }}</h4>


</div>
<app-recomended *ngIf="wishlist && wishlist.length > 0" [wishlist]="wishlist" (onToggleLike)="handelToggleLike($event)"></app-recomended>

<ng-container *ngIf="wishlist && wishlist.length === 0">
  <p  dir="auto" style="text-align: start;">{{'no_preferences'|translate}}</p>
</ng-container>
</div>
