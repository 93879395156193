import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { arLocale, enGbLocale } from 'ngx-bootstrap/locale';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements AfterViewInit , OnInit {
  bsConfig!: Partial<BsDatepickerConfig>;
  locales = listLocales();
  minDate = new Date();
  months: string[] = [];
  days: string[] = [];
  choosenDate: Date[] | null = null;

  constructor(private translate:TranslateService ,private localeService: BsLocaleService, public dialogRef: MatDialogRef<DatepickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private datacontext: DataContextService
  ) {
    
    this.bsConfig = {
      dateInputFormat: 'MMMM-D',
      isAnimated: false,
      rangeInputFormat: 'MMMM-D',
      rangeSeparator: '',
      showWeekNumbers: false,
      preventChangeToNextMonth:true,
      
    };
  }

  ngOnInit() {
    this.loadEnglishTranslations();
  }

  loadEnglishTranslations() {
    this.translate.get(['months', 'days']).subscribe(translations => {
      if (translations['months'] && translations['days']) {
        // Assuming 'months' and 'days' are arrays of English names in the translation JSON
        this.months = translations['months'];
        this.days = translations['days'];
        this.customRangeLocale();  // Call after translations are loaded
      } else {
        console.error('Translations for months or days are not defined.');
      }
    });
  }

  customRangeLocale() {
    let ar = { ...enGbLocale }


    ar.months = this.months;
    ar.monthsShort = this.months;
    ar.weekdays = this.days;
    ar.weekdaysMin = this.days;
    ar.weekdaysShort = this.days;

    defineLocale('ar', ar);
    this.localeService.use('ar');
  }

  HandelCloseDialog() {
    this.dialogRef.close()
  }

  HandleConfirm() {
    if (this.choosenDate) {
      this.dialogRef.close(this.choosenDate)
    } else {
      this.translate.get('error.select_date').subscribe(message => {
        this.datacontext.showError(
          this.translate.instant('error.title'),
          message
        );
      });
    }
  }

  onDateChange(value: any) {
    this.choosenDate = value
  }

  ngAfterViewInit(): void {
    const datepickerElement = document.querySelector('.bs-datepicker') as HTMLElement;
    const pickerHeaderElement = document.querySelector('.bs-datepicker-head') as HTMLElement;
    if (datepickerElement) {
      datepickerElement.classList.add('bs-datepicker-c')
    }

    // if (pickerHeaderElement) {
    // }
  }
}
