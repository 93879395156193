import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/services/auth/authentication.service';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent {


  constructor(public dialog: MatDialog,private authService:AuthenticationService,private router:Router,private datacontext:DataContextService){}

  closeDialog(){
    this.dialog.closeAll()
  }

  handelLogOut(){
    this.authService.logout('token')
    this.authService.removeToken('userInfo')
    this.router.navigate(['/'])
    this.datacontext.userData = null
    this.dialog.closeAll()
  }
}
