import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, Observable, throwError } from 'rxjs';

interface notification {
  id: number,
  date: string,
  title: string,
  details: string,
  viewed: boolean,
  NotificationRaw:{
    id:number;
    status:string;
    createdAt:string;
    body:string;
    title:string;
    updatedAt:string;
  }

}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {



  @BlockUI('root') root!:NgBlockUI
  notifications?:notification[]
  token:string=''
  constructor(private datacontext:DataContextService){}
  ngOnInit(): void {
    this.token = this.datacontext.getRouteParams('token')!
    this.getNotifications()
  }

  private getNotifications(){
    // this.root.start();
    this.datacontext.getData(`/notification/list?token=${this.token}`).subscribe({
      next:(value:any) => {
        if(value.success){
          this.notifications = value.data
        }
        // this.root.stop()
      }
    })
  }


  // notifications = [
  //   {
  //     id: 1,
  //     date: "12/1/2024",
  //     title: "عنوان الإشعار الداخلي هنا",
  //     details: "تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة،",
  //     viewed: true
  //   },
  //   {
  //     id: 2,
  //     date: "12/1/2024",
  //     title: "عنوان الإشعار الداخلي هنا",
  //     details: "تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة،",
  //     viewed: false
  //   },
  //   {
  //     id: 3,
  //     date: "12/1/2024",
  //     title: "عنوان الإشعار الداخلي هنا",
  //     details: "تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة،",
  //     viewed: false
  //   },
  //   {
  //     id: 4,
  //     date: "12/1/2024",
  //     title: "عنوان الإشعار الداخلي هنا",
  //     details: "تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة، تفاصيل الإشعار هنا يكتب ببعض التفاصيل البسيطة،",
  //     viewed: false
  //   },

  // ];


}
