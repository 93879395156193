<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-7">
      <app-review-reservations-card></app-review-reservations-card>
      <!-- room option was -->
      <app-review-reservations-forms (isFormPaymentValid)="onHandelFormPaymentValid($event)"></app-review-reservations-forms>
    </div>
    <div class="col-sm-12 col-md-5">
      <app-review-reservations-details
      [isValidPaymentForm]="validPaymentForm"
        (orderNowEmitter)="onConfirmOrder($event)"
      ></app-review-reservations-details>
    </div>
  </div>
</div>
