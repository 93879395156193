<div class="content container" dir="auto" style="text-align: start">
  <app-navigation back="" dir="auto" style="text-align: start"></app-navigation>
  <h6 class="header fs-30 pb-6">
    {{ isEdit ? ("edit" | translate) : ("add" | translate) }}
    {{ "propertyy" | translate }}
  </h6>
  <div class="content-row-sm">
    <form [formGroup]="personalDetails" id="msform" class="container">
      <!-- progressbar -->
      <div class="progress-container">
        <!-- [dir]="isFlipped ? 'rtl' : 'ltr'" -->
        <!-- Labels for each step -->
        <div
          class="progress-labels fs-20 flex-sm-row flex-column align-items-center"
        >
          <div class="progress-label">{{ "property_data" | translate }}</div>
          <div class="progress-label">
            {{ "basic_information" | translate }}
          </div>
          <div class="progress-label">{{ "confirmation" | translate }}</div>
        </div>
        <!-- Progress bar with dynamic classes based on the current step -->
        <ul id="progressbar" class="custom-progress-bar">
          <div class="progress-li">
            <!-- First step -->
            <li [ngClass]="step >= 1 ? 'active ' : 'inactive'"></li>

            <!-- Second step -->
            <li [ngClass]="step >= 2 ? 'active' : 'inactive'"></li>

            <!-- Third step -->
            <li [ngClass]="step == 3 ? 'active' : 'inactive'"></li>
          </div>
        </ul>
      </div>

      <!-- fieldsets -->
      <div class="row" *ngIf="step === 1">
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p>
            <span class="text-danger">*</span>
            {{ "property_type" | translate }}
          </p>
          <select
            class="custom-select"
            style="text-align: left"
            formControlName="type"
            (change)="onChangeRealStateType()"
            [ngStyle]="{
            'text-align': !isFlipped ? 'right' : 'left',
            'background-position': !isFlipped ? 'left center' : 'right center',
            'padding-left': !isFlipped ? '20px' : '10px',
            'padding-right': !isFlipped ? '10px' : '20px',
          }"
            [ngClass]="{
              'bg-select-img': propertiesTypes && propertiesTypes.length > 0
            }"
          >
            <option
              [ngStyle]="{ 'text-align': !isFlipped ? 'right' : 'left' }"
              value=""
            >
              {{ "select_property_type" | translate }}
            </option>
            <option
              [ngStyle]="{ 'text-align': !isFlipped ? 'right' : 'left' }"
              *ngFor="let property of propertiesTypes"
              [value]="property.id"
            >
              {{ !isFlipped ? property.nameAr : property.nameEn }}
            </option>
          </select>
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-6"
          *ngIf="!checkType(personalDetails.get('type')?.value)"
        ></div>
        <div
          class="col-sm-12 col-md-6 col-lg-6 my-4"
          *ngIf="checkType(personalDetails.get('type')?.value)"
        >
          <p>
            <span class="text-danger">*</span>
            {{ "hotel_star_rating" | translate }}
          </p>
          <select
            formControlName="hotelStars"
            class="custom-select"
            name=""
            id=""
            [ngClass]="{ 'bg-select-img': true }"
          >
            <option value="">{{ "choose_number_of_stars" | translate }}</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </select>
        </div>
        <!-- <div class="col-sm-12 col-md-6 col-lg-6 my-4" *ngIf="personalDetails.get('type')?.value === '1'">
          <p>
            <span class="text-danger mx-1">*</span>
            <span class="color-primary">PDF, PNG, JPG</span>
            إرفاق وثيقة النجوم الفندقية
          </p>
          <div class="position-relative">
            <input type="file" id="starsPaper" accept="image/jpeg, image/png, image/gif" (change)="onStarsPaperImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
            <input type="text" id="starsPaper" [value]="starsPaperImageUrl" style="cursor: not-allowed;" readonly>
            <button class="choose-btn" type="button" (click)="openStarsPaperFileDialog()">Choose</button>
          </div>
        </div> -->
        <!-- <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p>
            <span class="text-danger mx-1">*</span>
            الرقم الضريبي
          </p>
          <input type="text" appOnlyNumbers formControlName="taxNum" name="taxNum" />
        </div> -->
        <div
          class="col-sm-12 col-md-6 col-lg-6 my-4"
          *ngIf="checkType(personalDetails.get('type')?.value)"
        >
          <p>
            <!-- <span class="text-danger mx-1">*</span> -->
            {{ "Commercial_registration" | translate }}
            <span class="color-primary">PDF, PNG, JPG</span>
          </p>
          <div class="position-relative">
            <input
              type="file"
              id="taxNum"
              accept="image/jpeg, image/png, image/gif"
              (change)="onTaxNumImageChange($event)"
              style="cursor: not-allowed; display: none"
              readonly
            />
            <input
              type="text"
              id="taxNum"
              [value]="taxNumImageUrl"
              style="cursor: not-allowed"
              readonly
            />
            <button
              class="choose-btn"
              type="button"
              (click)="openTaxNumFileDialog()"
            >
              Choose
            </button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p>
            <!-- <span class="text-danger mx-1">*</span> -->
            {{ "tourist_license" | translate }}
            <span class="color-primary">PDF, PNG, JPG</span>
          </p>
          <div class="position-relative">
            <input
              type="file"
              id="license"
              accept="image/jpeg, image/png, image/gif"
              (change)="onLicenseImageChange($event)"
              style="cursor: not-allowed; display: none"
              readonly
            />
            <input
              type="text"
              id="license"
              [value]="liscenceImageUrl"
              style="cursor: not-allowed"
              readonly
            />
            <button
              class="choose-btn"
              type="button"
              (click)="openLicenseFileDialog()"
            >
              Choose
            </button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "owner_id_or_passport" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
            <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
            <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="ownerId"
              name="ownerId"
              appNumbersOnly
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "priceBefore" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
            <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
            <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="priceBefore"
              name="priceBefore"
              appNumbersOnly
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <!-- <span class="text-danger mx-1">*</span> -->
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "priceAfter" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
            <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
            <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="priceAfter"
              name="priceAfter"
              appNumbersOnly
            />
          </div>
        </div>

        <div class="col-sm-12 col-md-4 col-lg-4 my-4">
          <p>
            <!-- <span class="text-danger mx-1">*</span> -->
            {{ "check_in_time" | translate }}
          </p>
          <div class="position-relative">
            <select
              [ngStyle]="{
              'text-align': !isFlipped ? 'right' : 'left',
              'background-position': !isFlipped ? 'left center' : 'right center',
              'padding-left': !isFlipped ? '20px' : '10px',
              'padding-right': !isFlipped ? '10px' : '20px',
            }"
              [ngClass]="{
                'bg-select-img': propertiesTypes && propertiesTypes.length > 0
              }"
              formControlName="checkInTime"
              name="checkInTime"
              class="custom-select"
            >
              <option
                value=""
                disabled
                selected
                style="
                  color: rgba(128, 128, 128, 0.459) !important;
                  display: none;
                  text-align: center;
                "
              >
                {{ "select_time" | translate }}
              </option>

              <option style="text-align: center" value="1">1:00 AM</option>
              <option style="text-align: center" value="2">2:00 AM</option>
              <option style="text-align: center" value="3">3:00 AM</option>
              <option style="text-align: center" value="4">4:00 AM</option>
              <option style="text-align: center" value="5">5:00 AM</option>
              <option style="text-align: center" value="6">6:00 AM</option>
              <option style="text-align: center" value="7">7:00 AM</option>
              <option style="text-align: center" value="8">8:00 AM</option>
              <option style="text-align: center" value="9">9:00 AM</option>
              <option style="text-align: center" value="10">10:00 AM</option>
              <option style="text-align: center" value="11">11:00 AM</option>
              <option style="text-align: center" value="12">12:00 PM</option>
              <option style="text-align: center" value="13">1:00 PM</option>
              <option style="text-align: center" value="14">2:00 PM</option>
              <option style="text-align: center" value="15">3:00 PM</option>
              <option style="text-align: center" value="16">4:00 PM</option>
              <option style="text-align: center" value="17">5:00 PM</option>
              <option style="text-align: center" value="18">6:00 PM</option>
              <option style="text-align: center" value="19">7:00 PM</option>
              <option style="text-align: center" value="20">8:00 PM</option>
              <option style="text-align: center" value="21">9:00 PM</option>
              <option style="text-align: center" value="22">10:00 PM</option>
              <option style="text-align: center" value="23">11:00 PM</option>
              <option style="text-align: center" value="24">12:00 AM</option>
              <option style="text-align: center" value="">
                {{ "upon request" | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 my-4">
          <p>
            <!-- <span class="text-danger mx-1">*</span> -->
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "check_out_time" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
            <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
            <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <!-- <input type="datetime-local" formControlName="checkOutTime" name="checkOutTime"  /> -->
            <!-- <input type="datetime-local" formControlName="checkOutTime" name="checkOutTime" /> -->
            <select
              [ngStyle]="{
              'text-align': !isFlipped ? 'right' : 'left',
              'background-position': !isFlipped ? 'left center' : 'right center',
              'padding-left': !isFlipped ? '20px' : '10px',
              'padding-right': !isFlipped ? '10px' : '20px',
            }"
              [ngClass]="{
                'bg-select-img': propertiesTypes && propertiesTypes.length > 0
              }"
              formControlName="checkOutTime"
              name="checkOutTime"
              class="custom-select"
            >
              <option
                value=""
                disabled
                selected
                style="
                  color: rgba(128, 128, 128, 0.459) !important;
                  display: none;
                  text-align: center;
                "
              >
                {{ "select_time" | translate }}
              </option>
              <option style="text-align: center" value="1">1:00 AM</option>
              <option style="text-align: center" value="2">2:00 AM</option>
              <option style="text-align: center" value="3">3:00 AM</option>
              <option style="text-align: center" value="4">4:00 AM</option>
              <option style="text-align: center" value="5">5:00 AM</option>
              <option style="text-align: center" value="6">6:00 AM</option>
              <option style="text-align: center" value="7">7:00 AM</option>
              <option style="text-align: center" value="8">8:00 AM</option>
              <option style="text-align: center" value="9">9:00 AM</option>
              <option style="text-align: center" value="10">10:00 AM</option>
              <option style="text-align: center" value="11">11:00 AM</option>
              <option style="text-align: center" value="12">12:00 PM</option>
              <option style="text-align: center" value="13">1:00 PM</option>
              <option style="text-align: center" value="14">2:00 PM</option>
              <option style="text-align: center" value="15">3:00 PM</option>
              <option style="text-align: center" value="16">4:00 PM</option>
              <option style="text-align: center" value="17">5:00 PM</option>
              <option style="text-align: center" value="18">6:00 PM</option>
              <option style="text-align: center" value="19">7:00 PM</option>
              <option style="text-align: center" value="20">8:00 PM</option>
              <option style="text-align: center" value="21">9:00 PM</option>
              <option style="text-align: center" value="22">10:00 PM</option>
              <option style="text-align: center" value="23">11:00 PM</option>
              <option style="text-align: center" value="24">12:00 AM</option>
              <option style="text-align: center" value="">
                {{ "upon request" | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p>
            <span class="text-danger mx-1">*</span>
            {{ "owner_or_operator_name" | translate }}
          </p>
          <input type="text" formControlName="ownerName" name="ownerName" />
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-6 my-4 firstpage payment-int flaggg"
        >
          <!-- <div class="d-flex align-items-center ">
            <svg class="mb-2" matTooltip="This number is only available to Arrovia to contact the property owner" xmlns="http://www.w3.org/2000/svg" width="16"
              height="16" viewBox="0 0 24 24">
              <path fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8" />
            </svg>
            <p >
              <span class="text-danger mx-1">*</span>
              {{'mobile_number_not_published'|translate}}
            </p>
          </div> -->
          <!-- <input type="text" appOnlyNumbers formControlName="phone" name="phone" /> -->
          <div class="phone-input-container">
            <label style="text-align: start" for="phone-input">
              <span class="text-danger mx-1">*</span>
              <span class="phone-label"> {{ "phone" | translate }}</span>
            </label>
            <div class="input-group">
              <div class="country-code">
                <img
                  src="assets/saudi-arabia-flag-icon.svg"
                  alt="Saudi Arabia Flag"
                  class="country-flag"
                />
                <span class="country-key">+966</span>
              </div>
              <input
                type="tel"
                name="phone"
                id="my-input-id"
                appNumbersOnly
                formControlName="phone"
                pattern="[0-9]*"
                maxlength="10"
                inputmode="numeric"
                class="margin"
              />
            </div>
          </div>
          <!-- <ngx-intl-tel-input #privateNumber [cssClass]="'tel-input'"
            [enableAutoCountrySelect]="false"
            [enablePlaceholder]="false" [searchCountryFlag]="true"

            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
            [separateDialCode]="true" [onlyCountries]="[CountryISO.SaudiArabia]" [maxLength]="15"
            [phoneValidation]="true"
             inputId="my-input-id" name="phone" [selectedCountryISO]="CountryISO.SaudiArabia"
            
            formControlName="phone"
             style="text-align: start;"></ngx-intl-tel-input> -->
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <div class="d-flex align-items-center">
            <p>
              <span class="text-danger mx-1">*</span>
              {{ "email_not_published" | translate }}
            </p>
            <svg
              class="mb-3"
              [matTooltip]="
                'this_mail_only_abaliable_for_arrovia_to_contact_with_owner'
                  | translate
              "
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </div>
          <input type="email" formControlName="email" name="email" />
        </div>

        <!--  -->
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "num_rooms" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
    <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
    <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="rooms"
              name="rooms"
              appNumbersOnly
            />
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "num_beds" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
    <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
    <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="beds"
              name="beds"
              appNumbersOnly
            />
          </div>
        </div>

        <!--  -->
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "num_bathrooms" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
    <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
    <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="bathrooms"
              name="bathrooms"
              appNumbersOnly
            />
          </div>
        </div>

        <!--  -->
        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "num_totalSpace" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
    <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
    <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="totalSpace"
              name="totalSpace"
              appNumbersOnly
            />
          </div>
        </div>
        <!--  -->

        <div class="col-sm-12 col-md-6 col-lg-6 my-4">
          <p style="text-align: start">
            <span class="text-danger mx-1">*</span>
            <!-- <span class="color-primary">PDF, PNG, JPG</span> -->
            {{ "num_guests" | translate }}
          </p>
          <div class="position-relative">
            <!-- <input type="file" id="ownerIdentity" accept="image/jpeg, image/png, image/gif" (change)="onOwnerIdentityImageChange($event)" style="cursor: not-allowed; display: none;" readonly>
    <input type="text" id="ownerIdentity" [value]="ownerIdentityImageUrl" style="cursor: not-allowed;" readonly>
    <button class="choose-btn" type="button" (click)="openOwnerIdentityFileDialog()">Choose</button> -->
            <input
              type="text"
              formControlName="guests"
              name="guests"
              appNumbersOnly
            />
          </div>
        </div>
        <!--  -->
        <div
          class="col-sm-12 col-md-12 col-lg-12 my-4"
          style="display: flex; flex-direction: column; gap: 8px"
          *ngIf="!checkType(personalDetails.get('type')?.value)"
        >
          <p>
            {{ "request_insurance" | translate }}
          </p>
          <input
            type="checkbox"
            (change)="addInsurance($event)"
            formControlName="isInsured"
            name="phone"
            style="display: flex"
          />
        </div>

        <!-- Insurance amount input - shows when checkbox is checked -->
        <div
          class="col-sm-12 col-md-12 col-lg-12"
          *ngIf="personalDetails.get('isInsured')?.value && !checkType(personalDetails.get('type')?.value)"
        >
          <p>
            <span class="text-danger mx-1">*</span>
            {{ "insurance_amount" | translate }}
          </p>
          <input
            type="text"
            appNumbersOnly
            formControlName="insuranceValue"
            name="insuranceValue"
          />
        </div>

        <div
          style="display: flex; flex-direction: column; gap: 8px"
          class="col-sm-12 col-md-12 col-lg-12 mt-4"
        >
          <p class="custom-tooltip">
            {{ "auto_approve_bookings" | translate }}
            <svg
              [matTooltip]="
                'we_encourage_in_auto_approve_bookings_incase_its_not_on_other_platforms'
                  | translate
              "
              [matTooltipClass]="
                !isFlipped ? 'custom-tooltip rtl' : 'custom-tooltip ltr'
              "
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </p>

          <input
            type="checkbox"
            appNumbersOnly
            formControlName="isAutoAccepted"
            name="phone"
            style="display: flex"
          />
        </div>
        
        <div class="col-sm-12 col-md-12 col-lg-12 my-4">
          <p class="fs-6">
            <span class="text-danger">*</span> {{ "policy_cancel" | translate }}
          </p>
          <div [dir]="lang === 'en' ? 'ltr' : 'rtl'" style="text-align: start;">
            
          <mat-radio-group
          [color]="'primary'"
          aria-label="Select an option"
          formControlName="CancellationPolicyId">
          <mat-radio-button
            *ngFor="let cancel of cancellationPolicy"
            class="m-3"
            style="text-align: center"
            [value]="cancel.id"
            [dir]="lang === 'en' ? 'ltr' : 'rtl'">
            <a
              (click)="viewCancellationPolicy(cancel)"
              class="policy-title"
              style="font-size: 14px; text-decoration: none; font-family: 'black'; margin-top: 24px !important;"
              
              [innerHTML]="lang === 'en' ? cancel.nameEn : cancel.nameAr">
            </a>
            </mat-radio-button>
          </mat-radio-group>
          </div>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-12 my-4">
          <button
            type="button"
            (click)="next()"
            [ngClass]="{ 'btn-disabled': personalDetails.invalid }"
            name="next"
            [disabled]="personalDetails.invalid"
            class="action-button py-3"
          >
            {{ "next" | translate }}
          </button>
        </div>
      </div>
      <!-- peast here if there is any error -->
    </form>
    <form [formGroup]="addressDetails" id="msform">
      <div *ngIf="step == 2">
        <div
          class="property-name-lb adjust-orient"
          style="display: flex; gap: 10px"
        >
          <div class="width gap">
            <h6
              class="arabicLb"
              style="text-align: start"
              *ngIf="!checkType(personalDetails.get('type')?.value)"
            >
              <span class="text-danger">*</span>
              {{ "property_name_in_arabic" | translate }}
            </h6>
            <h6
              class="arabicLb"
              style="text-align: start"
              *ngIf="checkType(personalDetails.get('type')?.value)"
            >
              <span class="text-danger">*</span>
              {{ "hotel_trade_name" | translate }}
            </h6>
            <!-- <input class="arabic-name" type="text" formControlName="NameAr" (input)="filterInput($event)"  required/> -->
            <input
              class="arabic-name"
              type="text"
              formControlName="NameAr"
              required
            />
          </div>
          <div class="width">
            <h6 style="text-align: start" class="englishLb">
              {{ "property_name_in_english" | translate }}
            </h6>
            <!-- <input class="english-name" type="text" formControlName="NameEn" (input)="filterInputEN($event)" /> -->
            <input class="english-name" type="text" formControlName="NameEn" />
          </div>
        </div>

        <div class="descriptionAr-lb">
          <h6 class="arabic-Desc-Lb">
            <span class="text-danger">*</span>
            {{ "description_in_arabic" | translate }}
          </h6>
        </div>

        <textarea
          [ngClass]="{'border-red' : addressDetails.controls['descriptionAr'].touched && addressDetails.get('descriptionAr')?.errors?.['minlength'] || addressDetails.controls['descriptionAr'].touched && addressDetails.get('descriptionAr')?.errors?.['maxlength']}"
          class="desc-ar"
          formControlName="descriptionAr"
        ></textarea>
        <span
          class="mb-4 text-danger"
          *ngIf="addressDetails.controls['descriptionAr'].touched && addressDetails.get('descriptionAr')?.errors?.['minlength']"
          >{{ "description" | translate }}</span
        >
        <span
          class="mb-4 text-danger"
          *ngIf="addressDetails.controls['descriptionAr'].touched && addressDetails.get('descriptionAr')?.errors?.['maxlength']"
          >{{ "description1" | translate }}</span
        >
        <div class="descriptionEn-lb justify-content-between">
          <h6 class="english-Desc-Lb">
            {{ "description_in_english" | translate }}
          </h6>
          <!-- <h6 class="english-Desc-num">75 حرف</h6> -->
        </div>

        <textarea class="desc-en" formControlName="descriptionEn"></textarea>

        <div class="logo">
          <h6 class="">{{ "logo" | translate }}</h6>
          <h6 class="font-sx">{{ "optional" | translate }}</h6>
          <h6 class="icon">
            <svg
              [matTooltip]="'property_logo' | translate"
              matTooltipPosition="left"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </h6>
        </div>

        <div class="logo-img">
          <div class="image-upload-logo">
            <!-- <input type="" formControlName="logo" name="logo" /> -->
            <button type="button" (click)="openLogoFileDialog()">
              {{ "choose" | translate }}
            </button>
            <input
              dir="rtl"
              class="logo-img-input"
              type="text"
              [value]="logoImageUrl"
              readonly
            />
            <input
              class="logo-img-input d-none"
              type="file"
              id="logo"
              name="logo"
              accept="image/*"
              (change)="onLogoImageChange($event)"
            />
          </div>
        </div>
        <div class="main-pic">
          <h6 class="right">
            <span class="text-danger">*</span> {{ "main_image" | translate }}
          </h6>
          <h6 class="icon">
            <svg
              [matTooltip]="'main_imagee' | translate"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </h6>
        </div>
        <div class="main-pic-img">
          <div class="image-upload-main-pic">
            <!-- <input type="" formControlName="logo" name="logo" /> -->
            <button type="button" (click)="openMainPicFileDialog()">
              {{ "choose" | translate }}
            </button>
            <input
              class="main-pic-img-input d-none"
              type="file"
              id="main-pic"
              name="main-pic"
              accept="image/*"
              (change)="onMainPicImageChange($event)"
            />

            <input
              dir="rtl"
              class="logo-img-input"
              type="text"
              readOnly
              [value]="mainPicImageUrl"
              readonly
            />
          </div>
        </div>

        <div class="additional-pic">
          <div class="text-aligned" style="font-size: 20px !important">
            <h6 class="right">
              <span class="text-danger">*</span>
              {{ "additional_images" | translate }}
            </h6>
            <h6 class="">{{ "image_limit" | translate }}</h6>
          </div>

          <ngx-dropzone
            (change)="onSelect($event)"
            style="z-index: -1; display: inline-table; min-width: 100%"
            accept="image/*"
          >
            <div
              class="upload-title my-5"
              style="
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              #uploadImageCloud
            >
              <img
                src="../../assets/icons/Icon awesome-cloud-upload-icon.svg"
                alt=""
                class="upload-img"
              />
              <ngx-dropzone-label>{{
                "drag_images_here_plural" | translate
              }}</ngx-dropzone-label>
            </div>

            <!-- <ngx-dropzone-preview
                *ngFor="let f of files"
                [removable]="true"
                (removed)="onRemove(f)"
              >
              <img [src]="f.preview" alt="Image Preview">
                <ngx-dropzone-label
                  >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
                >
              </ngx-dropzone-preview> -->
            <div *ngFor="let file of files" class="file-preview-container">
              <div class="file-preview">
                <img [src]="getFileSrc(file)" alt="Image Preview" />
                <span class="remove-button" (click)="onRemove(file, $event)"
                  >&#10006;</span
                >
              </div>
            </div>
          </ngx-dropzone>
        </div>

        <div class="call-info">
          <h6 style="text-align: start" class="englishLb">
            {{ "contact_details" | translate }}
          </h6>
        </div>
        <div class="contact-info">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <h6 style="text-align: start" class="englishLb">
                {{ "website" | translate }}
              </h6>
              <input type="text" formControlName="Location" name="location" />
            </div>

            <div class="col-sm-12 col-md-4">
              <h6 style="text-align: start" class="englishLb">
                <span class="text-danger">*</span> {{ "email" | translate }}
              </h6>
              <input type="text" formControlName="email2" name="email2" />
            </div>

            <div
              class="col-sm-12 col-md-4"
              *ngIf="checkType(personalDetails.get('type')?.value)"
            >
              <div class="d-flex align-items-center gap-0">
                <h6 class="icon">
                  <svg
                    [matTooltip]="'email_published' | translate"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#000000"
                      d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                    />
                  </svg>
                </h6>
                <h6 class="middle">
                  <span class="text-danger">*</span> {{ "email" | translate }}
                </h6>
              </div>
              <input type="text" formControlName="Email" name="email" />
            </div>

            <div
              class="col-sm-12 col-md-4"
              *ngIf="checkType(personalDetails.get('type')?.value)"
            >
              <div class="d-flex align-items-center gap-0">
                <h6 class="icon">
                  <svg
                    [matTooltip]="'phone_published' | translate"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#000000"
                      d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                    />
                  </svg>
                </h6>
                <h6>
                  <span class="text-danger">*</span>
                  {{ "mobile_number" | translate }}
                </h6>
              </div>

              <div class="phone-input-container">
                <label style="text-align: start" for="phone-input">
                  <span class="text-danger mx-1">*</span>
                  <span class="phone-label"> {{ "phone" | translate }}</span>
                </label>
                <div class="input-group">
                  <div class="country-code">
                    <img
                      src="assets/saudi-arabia-flag-icon.svg"
                      alt="Saudi Arabia Flag"
                      class="country-flag"
                    />
                    <span class="country-key">+966</span>
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    id="my-input-id"
                    appNumbersOnly
                    formControlName="PhoneNumber"
                    pattern="[0-9]*"
                    maxlength="10"
                    inputmode="numeric"
                  />
                </div>
              </div>
              <!-- <input type="text" formControlName="PhoneNumber" name="phoneNum" /> -->
              <!-- <ngx-intl-tel-input #mobileNumber [cssClass]="'tel-input'"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="false" [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
                [selectFirstCountry]="true"
                [selectedCountryISO]="CountryISO.SaudiArabia"
                 [onlyCountries]="[CountryISO.SaudiArabia]"
                [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true" inputId="my-input-id" name="phone"
                formControlName="PhoneNumber"></ngx-intl-tel-input> -->
            </div>
          </div>
          <br />
          <div class="col-sm-12 col-md-6">
            <div class="phone-input-container">
              <label style="text-align: start" for="phone-input">
                <span class="text-danger mx-1">*</span>
                <span class="phone-label sizing">
                  {{ "phone" | translate }}</span
                >
              </label>
              <div class="input-group">
                <div class="country-code">
                  <img
                    src="assets/saudi-arabia-flag-icon.svg"
                    alt="Saudi Arabia Flag"
                    class="country-flag"
                  />
                  <span class="country-key">+966</span>
                </div>
                <input
                  type="tel"
                  name="phone2"
                  id="my-input-id"
                  appNumbersOnly
                  formControlName="phone2"
                  pattern="[0-9]*"
                  maxlength="10"
                  inputmode="numeric"
                />
              </div>
            </div>
            <!-- <h6 style="text-align: start;" class="englishLb"><span class="text-danger">*</span>{{'mobile_number'| translate}}</h6> -->
            <!-- <ngx-intl-tel-input #privateNumber [cssClass]="'tel-input'" [enableAutoCountrySelect]="false"
          [enablePlaceholder]="false" [searchCountryFlag]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
          [separateDialCode]="true" [onlyCountries]="[CountryISO.SaudiArabia]" [maxLength]="15"
          [phoneValidation]="true" inputId="my-input-id" name="phone2" [selectedCountryISO]="CountryISO.SaudiArabia"
          formControlName="phone2"></ngx-intl-tel-input> -->
          </div>
        </div>

        <div class="address">
          <h6 class="">
            <span class="text-danger">*</span> {{ "address" | translate }}
          </h6>
          <input
            type="text"
            formControlName="Address"
            name="address"
            #autoComplete
            placeholder=""
            (focus)="initGoogleMap()"  
            />
        </div>

<div style="display: flex; gap: 30px; align-items: center; width: 100%;">
  <div class="address" style="flex: 1;">
    <h6 class="">
      <span class="text-danger">*</span> {{ "city" | translate }}
    </h6>
    <div class="autoComplete">
      <ng-autocomplete
        [data]="data"
        formControlName="city"
        [searchKeyword]="keyword"
        name="address"
        placeholder="{{ 'search_destination' | translate }}"
        (selected)="selectEvent($event)"
        (inputChanged)="searchingCity($event)"
        [debounceTime]="1250"
        [itemTemplate]="itemTemplate"
        (inputCleared)="onInputClered()"
        class="small-placeholder"
      >
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.city"></a>
      </ng-template>
    </div>
    <datalist id="cityList">
      <option *ngFor="let city of cityData" [value]="city.nameEn">
        {{ isFlipped ? city.nameAr : city.nameEn }}
      </option>
    </datalist>
  </div>

  <div class="address" style="flex: 1;">
    <h6 class="">
      <span class="text-danger">*</span> {{ "district" | translate }}
    </h6>
    <div class="autoComplete">
      <ng-autocomplete
        [data]="districtOptions"
        formControlName="districtId"
        [searchKeyword]="'label'"
        name="district"
        placeholder="{{ 'search_district' | translate }}"
        (selected)="selectDistrictEvent($event)"
        [debounceTime]="1250"
        [itemTemplate]="districtTemplate"
        (inputCleared)="onDistrictInputCleared()"
        class="small-placeholder">
      </ng-autocomplete>
  
      <ng-template #districtTemplate let-item>
        <a [innerHTML]="lang === 'en' ? item.nameEn : item.nameAr"></a>
      </ng-template>
      <datalist id="districtList">
        <option *ngFor="let district of districtOptions" [value]="district.nameEn">
          {{ isFlipped ? district.nameAr : district.nameEn }}
        </option>
      </datalist>
    </div>
  </div>
</div>

        

        <div class="map">
          <h6 class="">{{ "select_from_map" | translate }}</h6>
          <!-- <div class="map-img"><img src="../../assets/Screenshot 2023-12-27 at 9.25.22ΓÇ»PM.png"></div> -->
          <google-map
            height="400px"
            width="100%"
            [options]="options"
            (mapClick)="onChooseLocation($event)"
          >
            <map-advanced-marker
              [position]="markerPosition"
            ></map-advanced-marker>
          </google-map>
        </div>

        <div class="favorite">
          <h6 class="font-lg">{{ "featured_facilities" | translate }}</h6>
          <h6 class="font-sx">{{ "select_up_to_three" | translate }}</h6>

          <h6 class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000000"
                d="M12 17q.425 0 .713-.288T13 16v-4q0-.425-.288-.712T12 11t-.712.288T11 12v4q0 .425.288.713T12 17m0-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </h6>
        </div>

        <div class="icons" dir="auto">
          <div class="row">
            <div
              class="col-sm-4 col-md-3 col-lg-3 my-2"
              *ngFor="let aminety of amenityList"
            >
              <div class="flex">
                <input
                  type="checkbox"
                  [checked]="amenityChoosenList.includes(aminety.id)"
                  (change)="onCheckAmenity($event)"
                  [id]="aminety.id"
                  [name]="aminety.nameAr"
                  [value]="aminety.id"
                />
                <img [src]="aminety.image" alt="amenity image" />
                <p class="description">
                  {{ !isFlipped ? aminety.nameAr : aminety.nameEn }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!--  -->

        <div class="all-favorite">
          <h6 class="right">{{ "all_available_facilities" | translate }}</h6>
        </div>

        <div class="icons" dir="auto">
          <div class="row">
            <div
              class="col-sm-4 col-md-3 col-lg-3 my-2"
              *ngFor="let aminety of amenityList"
            >
              <div class="flex">
                <input
                  type="checkbox"
                  [checked]="amenitySpecialChoosenList.includes(aminety.id)"
                  (change)="onCheckAmenitySpecial($event)"
                  [id]="aminety.id"
                  [name]="aminety.nameAr"
                  [value]="aminety.id"
                />
                <img [src]="aminety.image" alt="amenity image" />
                <p class="description">
                  {{ !isFlipped ? aminety.nameAr : aminety.nameEn }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="new-flex">

          </div> -->
        </div>
        <div class="d-flex align-items-center justify-content-between gap-2">
          <button
            (click)="next()"
            style="position: relative"
            [ngClass]="{ 'btn-disabled': addressDetails.invalid }"
            [disabled]="addressDetails.invalid || disabled"
            type="button"
            name="next"
            class="next action-button py-3"
          >
            {{ disabled ? "" : ("next" | translate) }}
            <!-- Add text for non-loading state -->

            <div class="spinner-container" *ngIf="disabled">
              <!-- Show spinner only when disabled -->
              <div class="circular-spinner"></div>
            </div>
          </button>
          <button
            (click)="previous()"
            type="button"
            name="next"
            class="action-button py-3"
            value="Next"
          >
            {{ "go_back" | translate }}
          </button>
        </div>
      </div>
    </form>
    <form id="msform">
      <div *ngIf="step == 3">
        <div class="flex-container">
          <h6 class="confirm-label">
            {{ "request_received" | translate }}{{ propertyUpdatedId }}
          </h6>
          <h6 *ngIf="!isEdit" class="confirm-parag">
            {{ "email_update_on_review_completion" | translate }}
          </h6>
          <h6 *ngIf="!isEdit" class="confirm-parag mt">
            {{ "property_approval_message" | translate }}
          </h6>
          <h6 *ngIf="isEdit" class="confirm-parag mt">
            {{ "property_has_been_updated" | translate }}
          </h6>
        </div>
      </div>
    </form>
  </div>
</div>

