import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
export interface userData {
  address: null |string;
  birthdate: null | string;
  email: string|null;
  firstName: string | null;
  gender: string|null;
  id: number;
  image: string|null;
  lastName: null | string;
  mobile: null | string;
  nationalId: null|string;
  nationalIdStatus: string;
  nationality: null|string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private router: Router) {}

  setToken(key: string, token: string): void {
    localStorage.setItem(key, token);
  }

  removeToken(key: string): void {
    localStorage.removeItem(key);
  }

  getToken(key: string): string | null {
    const token = localStorage.getItem(key);
    return token;
  }

  logout(localStorageKey: string) {
    this.removeToken(localStorageKey);
  }
}
