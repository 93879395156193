

<div class="cont">

    <app-searchcards></app-searchcards>
</div>

<app-filter></app-filter>
 
<app-categories></app-categories>
<!-- <app-deals></app-deals> -->
<app-recomended [mayLike]="propertysMayLikeList" (onToggleLike)="handelToggleLike($event)"></app-recomended>
<!-- <app-close-destinations></app-close-destinations> -->
<app-might-like></app-might-like>
<app-download></app-download>
