<div class="container-s">
    <div class="card-s">
        <div class="info">
            <p class="number">{{propertiesStatistics && propertiesStatistics.allProprties}}</p>
            <p class="description">{{'property_count'|translate}}</p>
        </div>
    </div>
    <!-- <div class="card-s">
        <div class="info">
            <p class="number">{{propertiesStatistics && propertiesStatistics.allRooms}}</p>
            <p class="description">{{'room_count'|translate}}</p>
        </div>
    </div> -->
    <div class="card-s">
        <div class="info">
            <p class="number">{{propertiesStatistics && propertiesStatistics.active}}</p>
            <p class="description">{{'active_properties'|translate}}</p>
        </div>
    </div>
    <div class="card-s">
        <div class="info">
            <p class="number">{{propertiesStatistics && propertiesStatistics.disabled}}</p>
            <p class="description">{{'inactive_properties'|translate}}</p>
        </div>
    </div>
</div>
