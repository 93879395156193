import { Component, Input, OnInit } from '@angular/core';
import {  SearchDTO } from 'app/search-details/search-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hotel-rate',
  templateUrl: './hotel-rate.component.html',
  styleUrls: ['./hotel-rate.component.css']
})
export class HotelRateComponent implements OnInit {
  @Input() searchRes:SearchDTO | null = null
  isFlipped=false;

  constructor(private translate:TranslateService) {}
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });

  }
  calcWith(total:number,rating:number) : number {
    return 500 / total / 100
  }
}
