import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-review-reservations-forms',
  templateUrl: './review-reservations-forms.component.html',
  styleUrls: ['./review-reservations-forms.component.css'],
})
export class ReviewReservationsFormsComponent implements OnInit, AfterViewInit {
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  paymentFormGuestInfo!: FormGroup;
  @Output() isFormPaymentValid = new EventEmitter<FormGroup>();
  UserInfo: any;
  constructor() {
    this.UserInfo = JSON.parse(localStorage.getItem('userInfo')!);
  }

  ngOnInit(): void {
    this.initInfoPaymenForm();

    this.paymentFormGuestInfo.valueChanges.subscribe((changed) => {
      this.isFormPaymentValid.emit(this.paymentFormGuestInfo);
    });
  }
  ngAfterViewInit(): void {
    const input = document.getElementsByClassName('iti--allow-dropdown')[0];
    if (input) {
      input.classList.add('h-100');
    }
  }
  initInfoPaymenForm() {
    this.paymentFormGuestInfo = new FormGroup({
      firstName: new FormControl(this.UserInfo.firstName, [
        Validators.required,
      ]),
      lastName: new FormControl(this.UserInfo.lastName, [Validators.required]),
      email: new FormControl(this.UserInfo.email, [
        Validators.required,
        Validators.email,
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(10),
        Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
        Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
      ]),
      acceptTermsYes: new FormControl('1'),
      selectedOption: new FormControl(''),
      mainGuestFirstName: new FormControl(''),
      mainGuestLastName: new FormControl(''),
      additionalComments: new FormControl(''),
      mainGuestEmail: new FormControl(''),
      mainGuestPhoneNumber: new FormControl(''),
    });
  }

  clearForm() {
    this.paymentFormGuestInfo.setValue({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      acceptTermsYes: '0',
      selectedOption: '',
      mainGuestFirstName: '',
      mainGuestLastName: '',
      additionalComments: '',
      mainGuestEmail: '',
      mainGuestPhoneNumber: '',
    });
  }

  submitForm() {}
}
