<div class="cont-s my-4">
  
  <form [formGroup]="paymentFormGuestInfo" style="text-align: start;" (ngSubmit)="submitForm()">
    <div class="third-row mb-5">
      <div class="radio-buttons">
        <p class="radio-title">{{'are_you_main_guest'|translate}}</p>
        <div class="options align-items-center d-block d-sm-flex align-content-center">
          <div (click)="initInfoPaymenForm()" style=" display: flex;" class="first-option align-items-center align-content-center">
            <input
              type="radio"
              value="1"
              id="yes"
              formControlName="acceptTermsYes"
            />
            <label for="yes">{{'yes'|translate}}</label>
          </div>
          <div (click)="clearForm()" class="second-option align-items-center align-content-center">
            <input
              type="radio"
              value="0"
              id="no"
              formControlName="acceptTermsYes"/>
            <label for="no" class="d-flex justify-content-center align-items-center align-content-center">
              {{'no'|translate}}
              <p class="no-terms">{{'booking_for_another_guest'|translate}}</p>
            </label>
          </div>
        </div>
      </div>
    </div>
    <p class="form-title" style="text-align: start;">{{'guest_details'|translate}}</p>
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <span>
          <span class="astriek">*</span>

          <label
          style=" text-align: start;"
            [ngClass]="{
              wrong:
                paymentFormGuestInfo.get('firstName')?.status === 'INVALID' &&
                paymentFormGuestInfo.get('firstName')?.touched === true
            }"
            >{{'first_name'|translate}}</label
          >
        </span
        >
        <input type="text" name="" id="" formControlName="firstName" />
      </div>
      <div class="col-sm col-lg-6">
        <span>
          <span class="astriek">*</span>
          <label
            [ngClass]="{
              wrong:
                paymentFormGuestInfo.get('lastName')?.status === 'INVALID' &&
                paymentFormGuestInfo.get('lastName')?.touched === true
            }"
            >{{'last_name'|translate}}</label
          >
        
          </span
        >
        <input type="text" name="" id="" formControlName="lastName" />
      </div>
    </div>
    <div class="row my-4">
      <div class="col-sm-12 col-lg-6">
        <span>
          <span class="astriek">*</span>
          <label
            [ngClass]="{
              wrong:
                paymentFormGuestInfo.get('email')?.status === 'INVALID' &&
                paymentFormGuestInfo.get('email')?.touched === true
            }"
            >{{'email'|translate}}</label
          >
        </span>
        <input type="text" name="" id="" formControlName="email" />
        <div>
          <p class="email-sub">{{'confirmation_emaill'|translate}}</p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-6">
        <!-- <span> -->
          <!-- <label
            [ngClass]="{
              wrong:
                paymentFormGuestInfo.get('phoneNumber')?.status === 'INVALID' &&
                paymentFormGuestInfo.get('phoneNumber')?.touched === true
            }"
            >{{'mobile_number'|translate}}</label
          >
          <span class="astriek">*</span></span
        > -->
        <!-- <input type="text" name="" id="" formControlName="email" /> -->

            <!-- <ngx-intl-tel-input
              #checkout
              [cssClass]="'h-100 border-0 intl-checkout'"
              style="height: 49px;"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="false"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.SaudiArabia"
              [onlyCountries]="[CountryISO.SaudiArabia]"
              [separateDialCode]="true"
              [maxLength]="15"
              [phoneValidation]="true"
              inputId="my-input-id"
              name="phone"
              formControlName="phoneNumber"
            ></ngx-intl-tel-input> -->
            <div class="phone-input-container">
              <span style="text-align:start;  display: flex;" for="phone-input">
                <span  class="text-danger mx-1">*</span>
                <label class="phone-label"> {{ 'phone' | translate }}</label>
              </span>
              <div class="input-group">
                <div class="country-code">
                  <img
                    src="assets/saudi-arabia-flag-icon.svg"
                    alt="Saudi Arabia Flag"
                    class="country-flag"
                  />
                  <span class="country-key">+966</span>
                </div>
                <input
                  type="tel"
                  name="phone"
                  id="my-input-id"
                  appNumbersOnly
                  formControlName="phoneNumber"
                  pattern="[0-9]*"
                  maxlength="10"
                  inputmode="numeric"
                  class="style"
                />
              </div>
        </div>
      </div>
    </div>
  </form>
</div>
