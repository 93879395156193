<div class="container" style="width: 80%" dir="auto" style="text-align: start;">
  <app-navigation back="/profile/rooms-list"></app-navigation>
  <h6 class="header">{{isEdit ? ('room_edit'|translate): ('room_add_new'|translate)}}  </h6>

  <div class="container my-4">
    <div class="header-title">
      <h2>{{'room_details'|translate}}</h2>

      <form [formGroup]="roomsInformationForm" (ngSubmit)="onAddNewRoom()">
        <div class="row">
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span>
              {{'room_name'|translate}}            </p>
            <input type="text" formControlName="roomName" />
          </div>
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> {{'room_number'|translate}}
            </p>
            <input type="text" appOnlyNumbers formControlName="roomNumber"  placeholder="0"/>
          </div>
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span>
              {{'property_select'|translate}}
            </p>
            <select class="custom-select" formControlName="stateType"
            [ngClass]="{'bg-select-img':propertyTypeList && propertyTypeList.length > 0}">
              <option value="">{{'property_select'|translate}}</option>
              <option
                *ngFor="let property of propertyTypeList"
                [value]="property.id"
              >
                {{!isFlipped? property.nameAr :property.nameEn}}
              </option>
            </select>
          </div>
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p><span class="text-danger">*</span>{{'MAX_GUESTS'|translate}}</p>
            <input
              type="text"
              appOnlyNumbers
              formControlName="maxRoomNumber"
              placeholder="0"
              />

          </div>
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> {{'MAX_CHILDREN'|translate}}
            </p>
            <input
              type="text"
              appOnlyNumbers
              formControlName="maxChildrenNumber"
              placeholder="0"
            />
          </div>
          <div class="my-4 col-sm-12 col-md-4 col-lg-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> {{'ROOM_SIZE'|translate}}
            </p>
            <input
              type="text"
              appOnlyNumbers
              formControlName="maxArea"
              placeholder="0"
            />
          </div>

          <h2 class="my-4">{{'bed_type'|translate}}</h2>

          <div class="col-sm-12 col-md-6 col-lg-6 my-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> {{'bed_details'|translate}}
            </p>
            <select
              class="custom-select"
              formControlName="bedType"
              name=""
              id=""
              [ngClass]="{'bg-select-img':bedsTypes && bedsTypes.length > 0}"
            >
              <option value="">{{'choose_bed_type'|translate}}</option>
              <option *ngFor="let beds of bedsTypes" [value]="beds.id">
                {{ !isFlipped?beds.nameAr:beds.nameEn }}
              </option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 my-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> {{'bed_number'|translate}}
            </p>
            <input appOnlyNumbers type="text" formControlName="bedNumber"  placeholder="0"/>
          </div>
          <!-- <div class="col-sm-12 col-md-6 col-lg-6 my-4">
            <p class="fs-15 fs-heavy">
              <span class="text-danger">*</span> سعر الليلة
            </p>
            <input
              type="text"
              appOnlyNumbers
              formControlName="dayPrice"
              placeholder="0"
            />
          </div> -->
          <!-- <div class="col-sm-12 col-md-6 col-lg-6 my-4">
            <p class="fs-15 fs-heavy">السعر بعد الخصم</p>
            <input
              type="text"
              appOnlyNumbers
              formControlName="priceAfterDiscount"
              placeholder="0"
            />
          </div> -->
          <!--  -->
          <!-- <div class="col-sm-12 col-md-12 col-lg-12 my-4">
            <p class="d-flex gap-4 fs-6 fs-15 fs-heavy ">
              <span class="text-danger">*</span>
              {{'room_images'|translate}}
              <span
                >{{'max_images_info'|translate}}</span
              >
            </p>
            <ngx-dropzone (change)="onSelect($event)">
              <div class="upload-title" #uploadImageCloud>
                <img
                  src="../../assets/icons/Icon awesome-cloud-upload-icon.svg"
                  alt=""
                  class="upload-img"
                />
                <ngx-dropzone-label>{{'drag_images'|translate}}</ngx-dropzone-label>
              </div>
              <div *ngFor="let file of files">
                <ngx-dropzone-preview
                  [file]="file"
                  *ngIf="file.type.startsWith('image/')"
                  [removable]="true"
                  (removed)="onRemove(file)"
                >
                  <ngx-dropzone-label
                    style="
                      overflow: hidden;
                      width: 200px;
                      height: 130px;
                      display: flex;
                      align-items: center;
                    "
                    ><img
                      style="object-fit: contain; width: 100%; height: 100%"
                      [src]="file.preview"
                      alt="Image Preview"
                  /></ngx-dropzone-label>
                </ngx-dropzone-preview>
              </div>
         
            </ngx-dropzone>
            <div
              class="row my-4"
              *ngIf="imagesOnEdit && imagesOnEdit.length > 0 && isEdit"
            >
              <div
                class="col-sm-12 col-md-3 position-relative"
                *ngFor="let image of imagesOnEdit; let i = index"
              >
                <button
                  type="button"
                  class="btn btn-sm remove-btn"
                  (click)="handelRemovePhoto(image, i)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#000000"
                      d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496"
                    />
                  </svg>
                </button>
                <div style="max-height: 200px; height: 200px;;" class="mb-2">
                  <img
                    [src]="
                      'https://wovenclouds.com/arrovia/api/uploads?path=/' + image
                    "
                    alt=""
                    class="w-100"
                    style="object-fit: contain; width: 100%; height: 100%; border-radius: 10px;"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <!--  -->

          <div class="col-sm-12 col-md-12 col-lg-12 my-4" >
            <p class="fs-6"> <span class="text-danger">*</span>  {{'policy_cancel'|translate}}</p>
            <mat-radio-group
              [color]="'primary'"
              aria-label="Select an option"
              formControlName="refuse1"
            >
              <mat-radio-button
                class="m-3"
                *ngFor="let cancel of cancellationPolicy"
                [value]="cancel.id"

                ><a
                (click)="viewCancellationPolicy(cancel)"
                  style="
                    text-decoration: underline;
                    font-size: 14px;
                    font-family: 'black';
                  "
                  [innerHTML]="cancel.nameAr|cancelNameAr"
                  ></a
                ></mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>

        <button
          class="action-button"
          [ngClass]="{ 'btn-disabled': roomsInformationForm.invalid }"
          [disabled]="roomsInformationForm.invalid"
        >
          {{ isEdit ? ('edit'|translate) : ('add'|translate) }} {{'room'|translate}}
        </button>
      </form>
    </div>
  </div>
</div>
