import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SearchDTO } from 'app/search-details/search-dto';

@Component({
  selector: 'app-hotel-details',
  templateUrl: './hotel-details.component.html',
  styleUrls: ['./hotel-details.component.css'],
})
export class HotelDetailsComponent implements OnChanges {
  constructor(private translate: TranslateService) {}
  isFlipped = false;

  @Input() hotelInfo: SearchDTO | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.hotelInfo?.hotelStars) {
        this.starsArray = Array(this.hotelInfo?.hotelStars)
          .fill(0)
          .map((x, i) => i);
      }
    }
  }
  starsArray: number[] = [];

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
}
