<div class="category-container d-flex flex-row gap-2 align-items-center justify-content-between">
    <div class="category-item" *ngFor="let category of categories">
        <div class="category-inner hovered-pointer"(click)="searchRandom(category)">
            <div class="image" *ngIf="category.image" style="align-items: center; display: flex; margin:auto;">
                <img  [src]="category.image" alt="">
            </div>
            <div class="title">
                <p>{{!isFlipped?category.nameAr:category.nameEn}}</p>
            </div>
        </div>
    </div>
</div>
