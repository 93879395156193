import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DatepickerComponent } from 'app/datepicker/datepicker.component';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { listLocales } from 'ngx-bootstrap/chronos';
import {
  BsDatepickerConfig,
  BsDatepickerDirective,
  BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-searchcards',
  templateUrl: './searchcards.component.html',
  styleUrls: ['./searchcards.component.css'],
})
export class SearchcardsComponent implements OnInit {
  searchTrip!: FormGroup;
  currentStartedDate = new Date();
  // startChoosenDate: Date | null = new Date();
  @ViewChild('picker') picker!: ElementRef;
  endChoosenDate: Date | null = null;
  @ViewChild(BsDatepickerDirective, { static: false })
  datepicker?: BsDatepickerDirective;
  bsConfig!: Partial<BsDatepickerConfig>;
  locales = listLocales();

  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private localeService: BsLocaleService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private datacontext: DataContextService,
    private translate: TranslateService
  ) {
    this.bsConfig = {
      dateInputFormat: 'MMMM-D',
      isAnimated: true,
      rangeInputFormat: 'MMMM-D',
    };
    // this.localeService.use('ar');
  }
  isFlipped = false;
  data: any = [];

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.initSearchTripForm();
  }

  isLoading: boolean = false;
  private async getCities(city: string) {
    this.isLoading = true;
    this.datacontext
      .getData(`/search/?city=${city}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        console.log(value);
        if (value.success) {
          this.data = [
            ...value.data.map((item: any) => ({
              city: item.nameAr,
              id: item.id,
            })),
            ...value.data.map((item: any) => ({
              city: item.nameEn,
              id: item.id,
            })),
          ];
        }
        this.isLoading = false;
      });
  }

  searchingCity(e: string) {
    this.getCities(e);
  }

  initSearchTripForm() {
    this.searchTrip = this.fb.group({
      choosenDate: [[], Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      destination: ['', Validators.required],
      adults: [2, [Validators.min(1)]],
      children: [0],
    });
  }
  keyword = 'city';

  selectEvent(item: any) {
    console.log(this.searchTrip.get('destination'));
    this.searchTrip.get('destination')?.setValue(item.city);
  }

  onSearchTrip() {
    const startDate = this.searchTrip.controls['startDate'].value;
    const endDate = this.searchTrip.controls['endDate'].value;
    if (
      this.searchTrip.controls['destination'].value &&
      this.searchTrip.controls['adults'].value === 0 &&
      (!startDate || !endDate)
    ) {
      const currentDate = new Date();
      const initDate = new Date();
      const nextDate = new Date(initDate.setDate(initDate.getDate() + 3));
      this.handelSearchDateConvert(currentDate, nextDate);
      this.searchTrip.controls['adults'].setValue(1);
    }

    if (
      this.searchTrip.controls['destination'].value &&
      startDate &&
      endDate &&
      this.searchTrip.controls['adults'].value === 0
    ) {
      this.searchTrip.controls['adults'].setValue(1);
    }

    if (
      this.searchTrip.controls['destination'].value &&
      !startDate &&
      !endDate &&
      this.searchTrip.controls['adults'].value != 0
    ) {
      const currentDate = new Date();
      const initDate = new Date();
      const nextDate = new Date(initDate.setDate(initDate.getDate() + 3));
      this.handelSearchDateConvert(currentDate, nextDate);
    }

    if (this.searchTrip.invalid) {
      return this.datacontext.showError(
        '',
        this.translate.instant('please_select_destination')
      );
      // this.router.navigate(['/search']);
    } else {
      const city = this.searchTrip.value.destination;
      const cityItem: any = this.data.find((item: any) => item.city === city);
      const cityId = cityItem?.id && cityItem ? cityItem?.id : undefined;
      this.router.navigate(['/search'], {
        queryParams: {
          destination: cityId,
          // destination: this.searchTrip.value.destination,
          startDate: this.formatDateToLocalDateString(
            this.searchTrip.value.choosenDate[0]
          ),
          endDate: this.formatDateToLocalDateString(
            this.searchTrip.value.choosenDate[1]
          ),
          adults: this.searchTrip.value.adults,
          children: this.searchTrip.value.children,
          rooms: this.searchTrip.value.rooms,
        },
      });
    }
  }

  onInputClered() {
    this.searchTrip.controls['destination'].setValue('');
  }

  private formatDateToLocalDateString(date: Date) {
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
    const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
    const year = date.getFullYear(); // Get full year

    return `${day}/${month}/${year}`;
  }

  handelNextStarDate() {
    const months =
      this.translate.currentLang != 'en'
        ? [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
    const result = this.searchTrip.get('choosenDate')?.value;
    const newDate = new Date(result[0]);
    const lastDate = new Date(result[1]);
    newDate.setDate(newDate.getDate() + 1);
    //check if the endDate is less than the currentDate
    if (newDate.getTime() < lastDate.getTime()) {
      result[0] = newDate;

      const formatDate = (date: Date) => {
        return `${months[date.getMonth()]} ${date.getDate()}`;
      };

      const startDate = formatDate(newDate);
      const endDate = formatDate(lastDate);

      this.searchTrip.get('startDate')?.setValue(startDate);
      this.searchTrip.get('endDate')?.setValue(endDate);
      this.searchTrip.get('choosenDate')?.setValue(result);
    } else {
      this.datacontext.showError(
        '',
        this.translate.instant('check_in_date_invalid')
      );
    }
  }

  handelPrevStartDate() {
    const months =
      this.translate.currentLang != 'en'
        ? [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
    const result = this.searchTrip.get('choosenDate')?.value;
    const newDate = new Date(result[0]);
    const lastDate = new Date(result[1]);
    newDate.setDate(newDate.getDate() - 1);
    //check if the endDate is less than the currentDate
    if (newDate.getDate() < new Date().getDate()) {
      this.datacontext.showError(
        '',
        this.translate.instant('check_in_date_invalid')
      );
    } else {
      result[0] = newDate;

      const formatDate = (date: Date) =>
        `${months[date.getMonth()]}${date.getDate()}`;

      const startDate = formatDate(newDate);
      const endDate = formatDate(result[1]);

      this.searchTrip.get('startDate')?.setValue(startDate);
      this.searchTrip.get('endDate')?.setValue(endDate);
      this.searchTrip.get('choosenDate')?.setValue(result);
    }
  }

  handelPrevEndDate() {
    const months =
      this.translate.currentLang != 'en'
        ? [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];

    const result = this.searchTrip.get('choosenDate')?.value;
    const newDate = new Date(result[0]);
    const lastDate = new Date(result[1]);
    lastDate.setDate(lastDate.getDate() - 1);
    //check if the endDate is less than the currentDate
    if (
      (lastDate.getDate() <= newDate.getDate() &&
        lastDate.getMonth() <= newDate.getMonth()) ||
      (lastDate.getDate() <= newDate.getDate() &&
        lastDate.getMonth() === newDate.getMonth())
    ) {
      this.datacontext.showError(
        '',
        this.translate.instant('check_in_date_invalid')
      );
    } else {
      result[1] = lastDate;
      const formatDate = (date: Date) =>
        `${months[date.getMonth()]}${date.getDate()}`;

      const startDate = formatDate(result[0]);
      const endDate = formatDate(result[1]);

      this.searchTrip.get('startDate')?.setValue(startDate);
      this.searchTrip.get('endDate')?.setValue(endDate);
      this.searchTrip.get('choosenDate')?.setValue(result);
    }
  }

  handelNextEndDate() {
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];
    const englishMonths = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const result = this.searchTrip.get('choosenDate')?.value;
    const lastDate = new Date(result[1]);
    lastDate.setDate(lastDate.getDate() + 1);
    result[1] = lastDate;

    const formatDate = (date: Date) =>
      `${months[date.getMonth()]}${date.getDate()}`;

    const startDate = formatDate(result[0]);
    const endDate = formatDate(result[1]);

    this.searchTrip.get('startDate')?.setValue(startDate);
    this.searchTrip.get('endDate')?.setValue(endDate);
    this.searchTrip.get('choosenDate')?.setValue(result);
  }

  plus(type: string) {
    switch (type) {
      case 'adults':
        this.searchTrip
          .get('adults')
          ?.setValue(this.searchTrip.get('adults')?.value + 1);
        break;
      case 'children':
        this.searchTrip
          .get('children')
          ?.setValue(+this.searchTrip.get('children')?.value + 1);
        break;
      case 'rooms':
        this.searchTrip
          .get('rooms')
          ?.setValue(+this.searchTrip.get('rooms')?.value + 1);
        break;
    }
  }

  minus(type: string) {
    switch (type) {
      case 'adults':
        if (this.searchTrip.get('adults')?.value <= 1) {
          this.searchTrip.get('adults')?.setValue(0);
          this.searchTrip.get('children')?.setValue(0);
          this.searchTrip.get('rooms')?.setValue(0);
          return;
        }
        this.searchTrip
          .get('adults')
          ?.setValue(this.searchTrip.get('adults')?.value - 1);
        break;
      case 'children':
        if (this.searchTrip.get('children')?.value === 0) return;
        this.searchTrip
          .get('children')
          ?.setValue(+this.searchTrip.get('children')?.value - 1);
        break;
      case 'rooms':
        if (this.searchTrip.get('rooms')?.value === 0) return;
        this.searchTrip
          .get('rooms')
          ?.setValue(+this.searchTrip.get('rooms')?.value - 1);
        break;
    }
  }

  openDatePicker() {
    // this.picker.nativeElement.click()
    const dialogRef = this.dialog.open(DatepickerComponent, {
      //some configuration
      maxWidth: '1124px',
    });

    dialogRef.afterClosed().subscribe((result: Date[]) => {
      // const months = [
      //   'يناير',
      //   'فبراير',
      //   'مارس',
      //   'أبريل',
      //   'مايو',
      //   'يونيو',
      //   'يوليو',
      //   'أغسطس',
      //   'سبتمبر',
      //   'أكتوبر',
      //   'نوفمبر',
      //   'ديسمبر',
      // ];
      // if (result) {
      //   const startDate = `${months[result[0].getMonth()]} ${
      //     (result[0], result[0].toLocaleDateString().split('/')[1])
      //   } `;
      //   const endDate = `${months[result[1].getMonth()]} ${
      //     (result[1], result[1].toLocaleDateString().split('/')[1])
      //   } `;
      //   this.searchTrip.get('startDate')?.setValue(startDate),
      //     this.searchTrip.get('endDate')?.setValue(endDate);

      //   this.searchTrip.get('choosenDate')?.setValue(result);
      // }
      const months =
        this.translate.currentLang != 'en'
          ? [
              'يناير',
              'فبراير',
              'مارس',
              'أبريل',
              'مايو',
              'يونيو',
              'يوليو',
              'أغسطس',
              'سبتمبر',
              'أكتوبر',
              'نوفمبر',
              'ديسمبر',
            ]
          : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];

      if (result) {
        const startDate = `${
          months[result[0].getMonth()]
        } ${result[0].getDate()} `;
        const endDate = `${
          months[result[1].getMonth()]
        } ${result[1].getDate()} `;

        this.searchTrip.get('startDate')?.setValue(startDate);
        this.searchTrip.get('endDate')?.setValue(endDate);
        this.searchTrip.get('choosenDate')?.setValue(result);
      }
    });
  }

  handelSearchDateConvert(startDate: Date, endDate: Date) {
    const months =
      this.translate.currentLang != 'en'
        ? [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];

    const startDateOut = `${months[startDate.getMonth()]} ${
      startDate.toLocaleDateString().split('/')[1]
    } `;
    const endDateOut = `${months[endDate.getMonth()]} ${
      endDate.toLocaleDateString().split('/')[1]
    } `;
    this.searchTrip.get('startDate')?.setValue(startDateOut),
      this.searchTrip.get('endDate')?.setValue(endDateOut);

    this.searchTrip.get('choosenDate')?.setValue([startDate, endDate]);
  }
}
