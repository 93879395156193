<div
  class="container"
  [attr.dir]="!isFlipped ? 'rtl' : 'ltr'"
  style="text-align: start"
>
  <div class="header" style="text-align: start">
    <h4 class="label">{{ "label" | translate }}</h4>
    <h6 class="details">{{ "manage_bookings_and_trips" | translate }}</h6>
  </div>

  <div class="reservation">
    <ng-container *ngIf="reservationList && reservationList.length > 0">
      <div
        class="reserve-body hovered-pointer"
        (click)="reservationDetails(reservation)"
        *ngFor="let reservation of reservationList; let i = index"
      >
        <div class="reserve-content">
          <div class="image">
            <img [src]="reservation.Property.mainImage" alt="" />
          </div>
          <div class="name">
            <h6
              class="name-h6 hovered-pointer"
              style="text-align: start"
              (click)="reservationDetails(reservation)"
            >
              {{
                !isFlipped
                  ? reservation.Property.nameAr
                  : reservation.Property.nameEn
              }}
            </h6>
            <h6 class="date-h6">
              {{ reservation.startDate | arabicDate : true }} -
              {{ reservation.endDate | arabicDate : true }}
            </h6>

            <!-- <h6 class="statues" [ngClass]="getStatusClass(reservation.paymentStatus)">
                {{ reservation.reservationStatus === 'pending' ? 'جاري' : 'قادم' }}
              </h6> -->
            <h6
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ reservation.reservationStatus | translate }}
            </h6>
            <!-- <h6
              *ngIf="reservation.reservationStatus == 'pendingPayment'"
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "pending_payment" | translate }}
            </h6>
            <h6
              *ngIf="reservation.reservationStatus == 'pending'"
              class="statues"
              style="color: darkgreen !important"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "suspended" | translate }}
            </h6>
            <h6
              *ngIf="reservation.reservationStatus == 'cancelled'"
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "booking_cancelled" | translate }}
            </h6>
            <h6
              *ngIf="reservation.reservationStatus == 'pendingPaymentTimeout'"
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "unpaid" | translate }}
            </h6>
            <h6
              *ngIf="reservation.reservationStatus == 'completed'"
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "booking_ended" | translate }}
            </h6>
            <h6
              *ngIf="reservation.reservationStatus == 'rejected'"
              class="statues"
              [ngClass]="getStatusClass(reservation.reservationStatus)"
            >
              {{ "booking_rejected" | translate }}
            </h6> -->
          </div>
        </div>
        <div
          class="price-content"
          [ngClass]="{
            'align-items-center':
              !reservation.pendingRating && !reservation.isReviewed
          }"
        >
          <div class="price">
            <div style="margin-top: 30%" class="d-flex gap-1">
              <h6 class="price-h6">{{ "ASR" | translate }}</h6>
              <h6 class="price-h6">
                {{ reservation.netPrice | number : "1.2-2" }}
              </h6>
            </div>
            <h6 class="rate color-green" *ngIf="reservation.isReviewed">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              {{ "reviewed" | translate }}
            </h6>
            <h6
              role="button"
              (click)="RatingDetails(reservation)"
              class="rate hovered-pointer coming-color"
              *ngIf="reservation.pendingRating && !reservation.isReviewed"
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              {{ "rate_now" | translate }}
            </h6>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      *ngIf="reservationList && reservationList.length === 0"
      class="text-right"
    >
      {{ "no_bookings" | translate }}
    </div>
  </div>
</div>
