import { Component, OnInit } from '@angular/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { wishlistDTO } from './wishlish-dto';
import { PropertyDTO } from 'app/new-property/property-dto';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, map, retry, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-whishlist',
  templateUrl: './whishlist.component.html',
  styleUrls: ['./whishlist.component.css'],
})
export class WhishlistComponent implements OnInit {
  fav: string = 'المفضلة';
  wishlist: wishlistDTO[] | null = null;
  token: string = '';
  isChecked:boolean
  @BlockUI('root') rootBlocker!: NgBlockUI;

  constructor(private datacontext: DataContextService) {}

  ngOnInit(): void {
    this.token = this.datacontext.getRouteParams('token')!;

    this.getWishlists();
  }

  private getWishlists() {
    // this.rootBlocker.start();
    this.datacontext
      .getData(`/wishlist/list?token=${this.token}`)
      .pipe(
        retry(2)
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.wishlist = value.data.map((items: PropertyDTO) => {
            return {
              ...items,
              isLiked: true,
            };
          });
        }
        // this.rootBlocker.stop();
      });
  }

  handelToggleLike(propertyCard: wishlistDTO) {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .getData(
        `/wishlist/add-remove/${propertyCard.Property.id}?token=${token}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          // this.datacontext.showSuccess('تم', 'تم تحديث المفضلة');
          this.getWishlists();
        }
        // this.rootBlocker.stop();
      });
  }
}
