<div class="cont">
    <div class="center">
        <img src="assets/aroovia-icons/Group 676.svg" alt="" class="download-img">
        <div class="text">
            <p class="title"> {{'download_arovia_app_ios_android' | translate}}</p>
            <p class="subtitle">{{'app_supports_version_14+'|translate}}</p>
        </div>
        <div class="images">
            <img src="assets/aroovia-icons/Group 105.svg" alt="">
            <img src="assets/aroovia-icons/Group 107.svg" alt="">
        </div>
    </div>
</div>