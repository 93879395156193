import { Component, OnInit } from '@angular/core';
import {OrderInterface} from '../orders-table/order-interface'
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderDTO } from 'app/all-orders/order-dto';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  ordersList:OrderDTO[]
  totalPages:number = 0
  @BlockUI('root') rootBlocker!:NgBlockUI
  constructor(private datacontext:DataContextService){}



  ngOnInit(): void {
    this.gettingOrders()
  }

  private gettingOrders(){
    const token = this.datacontext.getRouteParams('token')

    // this.rootBlocker.start()
    this.datacontext.getData(`/requests/list?token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          // this.rootBlocker.stop()
          this.datacontext.showError('',err.error.message)
        })
      })
    ).subscribe((value:any)=>{
      if(value.success){
        this.ordersList = value.data
      }
      // this.rootBlocker.stop()
    })

  }
  currentNumber: number = 1;
  next(): void {
    ++this.currentNumber;
  }

  previous(): void {

    if (this.currentNumber > 1) {
      --this.currentNumber;
    }
  }

  onHandelFilterOrders(status:string){
    if(status === 'all-orders'){
      this.gettingOrders()
    }else{
      this.filterWithStatus(status)
    }
  }

  private filterWithStatus(status:string){
    const token = this.datacontext.getRouteParams('token')
    this.datacontext.getData(`/requests/list/filter?reservationStatus=${status}&token=${token}`).pipe(
      catchError((err:HttpErrorResponse)=>{
        return throwError(()=>{
          this.datacontext.showError('',err.error.message)
        })
      })
    ).subscribe((value:any) => {
      if(value.success){
        this.ordersList = value.data
      }
    })
  }

  onReloadOrders(){
    this.gettingOrders()
  }
}
