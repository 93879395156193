import { CanActivateFn, Router} from '@angular/router';
import { inject } from '@angular/core';
import { DataContextService } from '../global-context/data-context.service';

export const authGuardGuard: CanActivateFn = (route, state) => {
  const router  = inject(Router)
  const userData = inject(DataContextService)
  const userInfo = JSON.parse(userData.getRouteParams('userInfo')!)
  if(userInfo){
    userData.userData = userInfo
    return true;
  }
  router.navigate(['/'])
  return false;
};
