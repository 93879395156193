import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.css']
})
export class HeaderCarouselComponent implements OnInit {
  constructor(public datacontext:DataContextService,    private translate: TranslateService){}
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    
    nav:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  isFlipped=false

  slidesStore:any[]=[

  {
    src:"assets/aroovia-icons/carousel-image.png"
  },
]

ngOnInit(): void {
  this.isFlipped = this.translate.currentLang === 'en';

  // Listen for language change events
  this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
  });
}

}
