import { Component, OnInit } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {CardInterface} from '../card/card-interface';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, retry, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SearchDTO } from 'app/search-details/search-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],

})
export class FilterComponent implements OnInit {
  title:string=''
  filtertitle:string=''
  searchResult:SearchDTO[]
  isFlipped=false
  @BlockUI('root') rootBlocker!:NgBlockUI
  constructor(private datacontext:DataContextService ,private translate: TranslateService ){}

  ngOnInit(): void {
    // Set initial value based on current language
    this.getSearchRandom()

    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }

  private getSearchRandom() {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token')
    let url;
    if(token){
      url =`/search/random?token=${token}`
    }else{
      url = `/search/random`
    }
    this.datacontext
      .getData(url)
      .pipe(
        retry(2),
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.searchResult = value.data;
          this.searchResult.forEach(item =>{
            if(item.isFavorite){
              this.datacontext.toggleFavorite(item.id)
            }
          })
        }
        // this.rootBlocker.stop();
      });
  }
}
