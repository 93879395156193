// import { state } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DatepickerComponent } from 'app/datepicker/datepicker.component';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { SigninComponent } from 'app/signin/signin.component';
import { defineLocale, enGbLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css'],
})
export class ReservationComponent implements OnInit, OnDestroy {
  @Input() reservationDetails: SearchDTO | null = null;
  @Input() sharedNetPrice_BE: number;
  @Output() netPriceChange: EventEmitter<number> = new EventEmitter();
  @Output() onReserve: EventEmitter<any> = new EventEmitter();

  bsConfig!: Partial<BsDatepickerConfig>;
  // locales = listLocales();
  currentStartedDate: any = null;
  tempSearchResult: any;
  nightCount: number = 0;
  dateChosen: string = '';
  locales = listLocales();
  isFlipped = false;

  constructor(
    private localeService: BsLocaleService,
    private datacontext: DataContextService,
    private router: Router,
    private dialog: MatDialog,
    private ActivatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.bsConfig = {
      dateInputFormat: 'MMMM-D',
      isAnimated: true,
      rangeInputFormat: 'MMMM-D',
      minDate: new Date(),
      showWeekNumbers: false,
      preventChangeToNextMonth: true,
    };
    const isSearchResult = JSON.parse(localStorage.getItem('searchResult')!);

    if (isSearchResult) {
      let f = this.datacontext.convertDateAPI(isSearchResult.startDate);
      let l = this.datacontext.convertDateAPI(isSearchResult.endDate);
      let startDate = this.convertDate(new Date(f));
      let endDate = this.convertDate(new Date(l));

      let c = this.datacontext.convertDateAPI(startDate);
      let d = this.datacontext.convertDateAPI(endDate);
      this.currentStartedDate = [new Date(c), new Date(d)];
      this.tempSearchResult = isSearchResult;
      this.calcNights();
    } else {
      this.currentStartedDate = [];
      this.nightCount == 0;
      const data = {
        destination: '',
        adults: 0,
        children: 0,
        infants: 0,
      };

      this.tempSearchResult = data;
    }
    this.customRangeLocale();
  }

  private convertDate(date: Date): string {
    // swaping the date and return a valid date
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`; // Return the date in DD/MM/YYYY format
  }

  customRangeLocale() {
    let ar = { ...enGbLocale };
    const months =
      this.translate.currentLang != 'en'
        ? [
            'يناير',
            'فبراير',
            'مارس',
            'أبريل',
            'مايو',
            'يونيو',
            'يوليو',
            'أغسطس',
            'سبتمبر',
            'أكتوبر',
            'نوفمبر',
            'ديسمبر',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];
    const days = [
      'الأحد',
      'الاثنين',
      'الثلاثاء',
      'الأربعاء',
      'الخميس',
      'الجمعة',
      'السبت',
    ];
    ar.months = months;
    ar.monthsShort = months;
    ar.weekdays = days;
    ar.weekdaysMin = days;
    ar.weekdaysShort = days;
    defineLocale('ar', ar);
    this.localeService.use('ar');
  }

  openDatepicker() {
    // picker.toggle()
    let dialogRef = this.dialog.open(DatepickerComponent, {
      //some configuration
      maxWidth: '1124px',
    });

    dialogRef.afterClosed().subscribe((result: Date[]) => {
      // const months = [
      //   'يناير',
      //   'فبراير',
      //   'مارس',
      //   'أبريل',
      //   'مايو',
      //   'يونيو',
      //   'يوليو',
      //   'أغسطس',
      //   'سبتمبر',
      //   'أكتوبر',
      //   'نوفمبر',
      //   'ديسمبر',
      // ];
      // if (result) {
      //   const startDate = `${months[result[0].getMonth()]} ${
      //     (result[0], result[0].toLocaleDateString().split('/')[1])
      //   } `;
      //   const endDate = `${months[result[1].getMonth()]} ${
      //     (result[1], result[1].toLocaleDateString().split('/')[1])
      //   } `;
      //   this.searchTrip.get('startDate')?.setValue(startDate),
      //     this.searchTrip.get('endDate')?.setValue(endDate);

      //   this.searchTrip.get('choosenDate')?.setValue(result);
      // }
      const months =
        this.translate.currentLang != 'en'
          ? [
              'يناير',
              'فبراير',
              'مارس',
              'أبريل',
              'مايو',
              'يونيو',
              'يوليو',
              'أغسطس',
              'سبتمبر',
              'أكتوبر',
              'نوفمبر',
              'ديسمبر',
            ]
          : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];

      if (result) {
        const startDate = `${
          months[result[0].getMonth()]
        } ${result[0].getDate()} `;
        const endDate = `${
          months[result[1].getMonth()]
        } ${result[1].getDate()} `;
        this.currentStartedDate = [result[0], result[1]];
        this.dateChosen = startDate + endDate;
        this.calcNights();
        // this.searchTrip.get('startDate')?.setValue(startDate);
        // this.searchTrip.get('endDate')?.setValue(endDate);
        // this.searchTrip.get('choosenDate')?.setValue(result);
      }
    });
  }

  calcNights() {
    if (this.currentStartedDate.length > 0) {
      // const months = [
      //   'يناير',
      //   'فبراير',
      //   'مارس',
      //   'أبريل',
      //   'مايو',
      //   'يونيو',
      //   'يوليو',
      //   'أغسطس',
      //   'سبتمبر',
      //   'أكتوبر',
      //   'نوفمبر',
      //   'ديسمبر',
      // ];
      //   const startDate = `${
      //     (this.currentStartedDate[0], this.currentStartedDate[0].toLocaleDateString().split('/')[1])
      //   }  ${months[this.currentStartedDate[0].getMonth()]} `;
      //   const endDate = `${
      //     (this.currentStartedDate[1], this.currentStartedDate[1].toLocaleDateString().split('/')[1])
      //   } ${months[this.currentStartedDate[1].getMonth()]}  `;
      //   this.dateChosen = `${startDate} - ${endDate }`

      const months =
        this.translate.currentLang != 'en'
          ? [
              'يناير',
              'فبراير',
              'مارس',
              'أبريل',
              'مايو',
              'يونيو',
              'يوليو',
              'أغسطس',
              'سبتمبر',
              'أكتوبر',
              'نوفمبر',
              'ديسمبر',
            ]
          : [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ];

      const startDate = `${this.currentStartedDate[0].getDate()} ${
        months[this.currentStartedDate[0].getMonth()]
      }`;
      const endDate = `${this.currentStartedDate[1].getDate()} ${
        months[this.currentStartedDate[1].getMonth()]
      }`;

      this.dateChosen = `${startDate} - ${endDate}`;
      this.nightCount = this.calculateNights(
        this.currentStartedDate[0],
        this.currentStartedDate[1]
      );
    }
  }

  calculateNights(startDate: Date, endDate: Date): number {
    const diffInTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // Convert milliseconds to days
    return diffInDays;
  }

  plus(type: string) {
    if (type === 'adults') {
      // if(this.reservationDetails && this.reservationDetails.Rooms[0].maxAdults === this.tempSearchResult.adults){
      //   this.datacontext.showError('',`Max Adults Is ${this.reservationDetails.Rooms[0].maxAdults}`)
      //   return;
      // }
      this.tempSearchResult.adults = parseInt(this.tempSearchResult.adults) + 1;
    }

    if (type === 'children') {
      // if(this.reservationDetails && this.reservationDetails.Rooms[0].maxChilderns === this.tempSearchResult.children){
      //   this.datacontext.showError('',`Max children Is ${this.reservationDetails.Rooms[0].maxChilderns}`)
      //   return;
      // }
      this.tempSearchResult.children =
        parseInt(this.tempSearchResult.children) + 1;
    }

    if (type === 'infants') {
      this.tempSearchResult.infants =
        parseInt(this.tempSearchResult.infants) + 1;
    }
  }

  minus(type: string) {
    if (type === 'adults') {
      if (this.tempSearchResult.adults <= 0) return;

      this.tempSearchResult.adults = parseInt(this.tempSearchResult.adults) - 1;
      if (this.tempSearchResult.adults === 0) {
        this.tempSearchResult.children = 0;
        this.tempSearchResult.infants = 0;
      }
    }

    if (type === 'children') {
      if (this.tempSearchResult.children <= 0) return;
      this.tempSearchResult.children =
        parseInt(this.tempSearchResult.children) - 1;
    }

    if (type === 'infants') {
      this.tempSearchResult.infants =
        parseInt(this.tempSearchResult.infants) - 1;
    }
  }


  onHandelReservation(reservationDetails:SearchDTO | null){
    this.onReserve.emit()

    if(!this.datacontext.userData) {
      this.datacontext.showError('',this.translate.instant('please_register'));

      this.dialog.open(SigninComponent, {
        width: '450px',
        disableClose: true,
        data: { url: this.router.url },
      });
      return;
    }

    if (reservationDetails) {
      if (
        this.currentStartedDate.length > 0 &&
        (this.tempSearchResult.adults > 0 || this.tempSearchResult.children > 0)
      ) {
        const checkAvilibality = {
          startDate: this.convertDate(this.currentStartedDate[0]),
          endDate: this.convertDate(this.currentStartedDate[1]),
          children: this.tempSearchResult.children,
          adults: this.tempSearchResult.adults,
          infants: this.tempSearchResult.infants,
        };
        const constReservtionData = reservationDetails;
        const UserInfo = JSON.parse(localStorage.getItem('userInfo')!);

        // const selectedRooms=roomDetails.rooms.map((room:any)=>{return {id:room.id, quantity:room.roomsCount }})
        const token = this.datacontext.getRouteParams('token');
        const reserveData = new FormGroup({
          PropertyId: new FormControl(constReservtionData.id),
          email: new FormControl(UserInfo.email),
          rooms: new FormControl(
            constReservtionData.Rooms.map((room: any) => {
              return { id: room.id, quantity: room.roomCount };
            }).filter((room: any) => room.quantity > 0)
          ),
          startDate: new FormControl(checkAvilibality.startDate),
          endDate: new FormControl(checkAvilibality.endDate),
          adults: new FormControl(checkAvilibality.adults),
          infants: new FormControl(checkAvilibality.infants),
          childerns: new FormControl(checkAvilibality.children),
          firstName: new FormControl(UserInfo.firstName),
          lastName: new FormControl(UserInfo.lastName),
          mobile: new FormControl(UserInfo.mobile),
        });
        if (reserveData.value.rooms?.some((room: any) => room.quantity > 0)) {
          this.datacontext
            .postData(`/kamel/cost?token=${token}`, reserveData.value)
            .subscribe({
              next: (response: any) => {
                let data = response.data;
                this.sharedNetPrice_BE = data.netPrice;
                this.netPriceChange.emit(data.netPrice);
                localStorage.setItem('reserveData', JSON.stringify(data));
              },
              error: (err: HttpErrorResponse) => {
                this.datacontext.showError('', err.error.message);
              },
              complete: () => {},
            });
        }

        localStorage.setItem('searchResult', JSON.stringify(checkAvilibality));
        const option = document.getElementById('roomOptions') as HTMLElement;
        option.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.datacontext.showError(
          '',
          this.translate.instant('please_select_date_and_guests')
        );
      }
    }
  }

  ngOnDestroy(): void {
    const isSearchResult = JSON.parse(localStorage.getItem('searchResult')!);
    if (isSearchResult) {
      localStorage.removeItem('searchResult');
    }
  }
}
