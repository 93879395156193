import { Component, Input } from '@angular/core';
import { propertyStastics } from 'app/new-property/property-dto';

@Component({
  selector: 'app-summary-overview',
  templateUrl: './summary-overview.component.html',
  styleUrls: ['./summary-overview.component.css']
})
export class SummaryOverviewComponent {
 @Input() propertiesStatistics:propertyStastics
}
