<div class="container" id="over-print">
  <div id="nav-hidden">
    <app-navigation></app-navigation>
  </div>

  <div class="heading" id="navigation-print">
    <h2 class="title">{{'payments'|translate}}</h2>
    <span class="details">{{'manage_payments_withdrawals'|translate}}</span>
  </div>
  <div class="row " *ngIf="listStatistc" id="navigation-print">
    <div class="col-sm-12 col-md-4 col-lg-4 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
        <p class="mb-0 main-p">{{'total_amount'|translate}} </p>
        <p class="mb-0 sec-p">{{ listStatistc.total| number : "1.2-2" }}</p>
        <span>{{'sar'|translate}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
        <p class="mb-0 main-p">{{'withdrawal_balance'|translate}}</p>
        <p class="mb-0 p-secondary">{{ listStatistc.available| number : "1.2-2" }}</p>
             <span>{{'sar'|translate}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-3 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
        <p class="mb-0 main-p">{{'withdrawn'|translate}} </p>
        <p class="mb-0 p-green">{{ listStatistc.paidout| number : "1.2-2" }}</p>
             <span>{{'sar'|translate}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
        <p class="mb-0 main-p"> {{'arrovia_fee'|translate}} </p>
        <p class="mb-0 text-primary" style="font-size: 23px;">{{listStatistc.fee}}</p>
      </div>
    </div>
  </div>

  <div class="d-flex  align-items-center justify-content-between gap-3 mt-4" id="top-bar">
    <div class="d-flex  align-items-center gap-3 my-4">
      <p class="title mb-0"> {{'previous_transfers'|translate}} </p>
      <div class="view-all back mb-0 d-flex align-items-center  gap-2">
        <p role="button" routerLink="/profile/orders" class="view-all-txt mb-0"> {{'view_all'|translate}} </p>
        <img src="../../assets/Icon feather-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="d-flex  align-items-center gap-3 my-4">
      <div class="search-input">
        <div class="position-relative">
          <input (input)="onSearchOrders($event)" type="text" placeholder="بحث..." style="width: 271px;">
          <img src="../../assets/aroovia-icons/Icon feather-search.svg" alt="" class="position-absolute"
            style="left: 10px;width: 19px;">
        </div>
      </div>
      <div class="actions ">
        <p class="txt mb-0">{{'export'|translate}}</p>
        <!-- <img src="../../assets/aroovia-icons/" alt=""> -->
        <div class="actions ">
          <div class="export-img hovered-pointer" (click)="onExportExcel()">
            <img src="../../assets/New folder/Group 573.svg" alt="">
          </div>
          <div class="export-img hovered-pointer " (click)="onPDFExport()">

            <img src="../../assets/New folder/Group 572.svg" alt="">
          </div>
          <div class="export-img hovered-pointer " (click)="windowPrint()">

            <img src="../../assets/New folder/Group 571.svg" alt="">
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="table-responsive" id="table-container">
    <table class="custom-table">
      <tr class="header text-center">
        <th>{{'serial'|translate}}</th>
        <th>{{'bank_account_holder_name'|translate}}</th>
        <th>{{'bank'|translate}}</th>
        <th>{{'total_withdrawal'|translate}}</th>
        <th>{{'phone'|translate}}</th>
        <th>{{'status'|translate}}</th>
        <!-- <th>تفاصيل</th> -->
      </tr>
      <tbody>
        <tr class="body-design text-center" *ngFor="let vendor of vendorList; let i = index">
          <td>#{{(i+ 1).toString().padStart(5,'0') }}</td>
          <td>{{vendor.vendorName}}</td>
          <td>{{vendor.bank}}</td>
          <td>{{vendor.value}}</td>
          <td dir="ltr">{{vendor.mobile}}</td>
          <td>
            <!--  [ngClass]="{'blue-filter':order.category=='طلبات جديدة', 'mint-filter':table.category=='الطلبات المكتملة','pink-filter':table.category=='الطلبات الملغاة','pink-filter ':table.category=='الطلبات الملغاة', 'purple-filter':table.category=='ضيوف مقيمين','peach-filter':table.category=='بانتظار الوصول', 'grey-filter':table.category=='جميع الطلبات','light-purple-filter':table.category=='الطلبات المسترجعة'}" -->
            <span class="purple-filter pill-shape p-2">{{vendor.status === 'pending' ? 'جاري' : 'تم التنفيذ'}}</span>
          </td>
          <!-- <td>
            <a style="cursor: pointer;">
              <mat-icon>more_vert</mat-icon>
            </a>
          </td> -->
        </tr>
        <ng-container *ngIf="vendorList && vendorList.length === 0">
          <p class=" mt-4"> {{'no_transfers_available'|translate}}  </p>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>