<div class="container" *ngIf="reservationRatingDetails">
  <app-navigation></app-navigation>
  <div class="row mt-5">
    <div class="col-sm-12 col-lg-5"></div>
    <div class="col-sm-12 col-lg-7 custom-rating p-3">
      <div class="d-flex align-items-center ">
        <!--IMAGE BOX-->
        <div class="image-box w-50">
            <div class="d-flex gap-4 ">
              <img [src]="reservationRatingDetails.Property.mainImage" [alt]="reservationRatingDetails.Property.nameAr" style="width: 130px; border-radius: 20px;">
              <div class="d-flex gap-2 flex-column">
                <h3 class="mb-0 heading fw-bold">{{!isFlipped? reservationRatingDetails.Property.nameAr:reservationRatingDetails.Property.nameEn}}</h3>
                <span > {{ reservationRatingDetails.startDate | arabicDate:true}} - {{reservationRatingDetails.endDate | arabicDate:true }}</span>
                <h6 class="statues" [ngClass]="getStatusClass(reservationRatingDetails.paymentStatus)" >
                  {{isCompleted(reservationRatingDetails.paymentStatus , reservationRatingDetails.endDate)}}
                </h6>
              </div>
            </div>
        </div>
        <div class="w-50 d-flex gap-2">
          <h6 class="price-h6">{{'sar'|translate}} </h6>
          <h6 class="price-h6"> {{  reservationRatingDetails.price | number:'1.2-2'}} </h6>
        </div>
      </div>
    </div>
  </div>

  <div class="row my-5">
    <div class="col-sm-12 col-lg-5"></div>
    <div class="col-sm-12 col-lg-7 custom-rating">
        <div class="my-4">
          <div class="d-flex  pb-2 pt-3">
            <div class="col-sm-12 d-none d-lg-block col-lg-2"></div>
            <h4 style="font-size: 14px; font-family: 'heavy';">{{'leave_rating'|translate}}</h4>
          </div>
          <form [formGroup]="ratingForm">
            <div class="d-flex align-items-center  gap-4 my-2">
              <label>{{'cleanliness'|translate}}</label>
              <ngx-star-rating formControlName="clean" [id]="'clean'"></ngx-star-rating>
            </div>
            <div class="d-flex align-items-center  gap-4 my-2">
              <label>{{'amenities'|translate}}</label>
              <ngx-star-rating formControlName="amenities" [id]="'amenities'"></ngx-star-rating>
            </div>
            <div class="d-flex align-items-center  gap-4 my-2">
              <label>{{'host'|translate}}</label>
              <ngx-star-rating formControlName="staff" [id]="'staff'"></ngx-star-rating>
            </div>
            <div class="d-flex align-items-center  gap-4 my-2">
              <label>{{'value'|translate}}</label>
              <ngx-star-rating formControlName="value" [id]="'value'"></ngx-star-rating>
            </div>
            <div class="d-flex align-items-center  gap-4 my-2">
              <label>{{'location'|translate}}</label>
              <ngx-star-rating formControlName="location" [id]="'location'"></ngx-star-rating>
            </div>

            <div class="comment">
              <p>{{'additional_comment'|translate}}</p>
              <div class="w-100">
                <textarea class="text-area" formControlName="comment" name="" rows="5" id=""></textarea>
              </div>
            </div>

            <button role="button" type="button" class="action-button" [ngClass]="{'btn-disabled':!ratingForm.valid}" [disabled]="!ratingForm.valid" (click)="addRating()">{{'add_rating'|translate}}</button>
          </form>
        </div>
    </div>
  </div>
</div>
