<!-- <div class="customContainer">
    <img src="../../assets/aroovia-icons/carousel-image.png" alt="">
</div> -->

      <div class="customContainer">
        <!-- <img [src]="slide.src" style="height: 100%;"> -->
        <div style="background-color: #FAFAFA; height: 450px;"></div>
        <div class="text" [ngStyle]="!isFlipped ? { 'right': '14%' } : { 'left': '14%' }">
          <div class="text-inner">
            <p class="title l-h-1" [ngStyle]="!isFlipped ? { 'text-align': 'right' } : { 'text-align': 'left' }" >
              {{ 'HELLO' | translate }} {{datacontext.userData?.firstName}}
            </p>
            <p class="subtitle" dir="auto">{{'What is your next destination?'| translate}}</p>
          </div>
        </div>
      </div>
