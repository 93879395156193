<div
    style="display: flex; flex-direction: column; align-items: center;"
  class="filter-container "
>
  <div class="header text-start2">
    <h2>{{ filterText.title }}</h2>
  </div>
  <form>
    <div class="price-range text-start2">
      <h3>{{ filterText.priceRange }}</h3>

      <div class="price-inputs">
        <input
          type="number"
          (input)="onMinPriceChange($event)"
          formControlName="minPrice"
          [placeholder]="filterText.minPrice"
        />
        <input
          type="number"
          (input)="onMaxPriceChange($event)"
          formControlName="maxPrice"
          [placeholder]="filterText.maxPrice"
        />
      </div>
    </div>
    <div class="sort-by text-start2">
      <h3>{{ filterText.sortBy }}</h3>
      <select
        style="border-radius: 3%; padding: 5px; border: 0px"
        (change)="onSortChange($event)"
        formControlName="sortBy"
      >
        <option value="lowPrice">{{ filterText.lowToHigh }}</option>
        <option value="highPrice">{{ filterText.highToLow }}</option>
        <option value="highRate">{{ filterText.rateHighToLow }}</option>
        <option value="lowRate">{{ filterText.rateLowToHigh }}</option>
      </select>
    </div>

    <div class="rooms-and-beds text-start2">
      <h3>{{ filterText.roomsAndBeds }}</h3>
      <div class="room-options">
        <h4>{{ filterText.bedrooms }}</h4>
        <div class="option-buttons">
          <button
            type="button"
            (click)="selectOption('bedrooms', 'any')"
            [class.selected]="form.bedrooms === 'any'"
          >
            {{ filterText.any }}
          </button>
          <button
            type="button"
            [class.selected]="form.bedrooms === '1'"
            (click)="selectOption('bedrooms', '1')"
          >
            1
          </button>
          <button
            type="button"
            [class.selected]="form.bedrooms === '2'"
            (click)="selectOption('bedrooms', '2')"
          >
            2
          </button>
          <button
            type="button"
            [class.selected]="form.bedrooms === '3'"
            (click)="selectOption('bedrooms', '3')"
          >
            3
          </button>
          <button
            type="button"
            [class.selected]="form.bedrooms === '4'"
            (click)="selectOption('bedrooms', '4')"
          >
            4
          </button>
          <button
            type="button"
            [class.selected]="form.bedrooms === '5'"
            (click)="selectOption('bedrooms', '5')"
          >
            5
          </button>

          <button
            type="button"
            [class.selected]="form.bedrooms === '6+'"
            (click)="selectOption('bedrooms', '6+')"
          >
            6+
          </button>
        </div>
      </div>

      <div class="room-options text-start2">
        <h4>{{ filterText.beds }}</h4>
        <div class="option-buttons">
          <button
            type="button"
            [class.selected]="form.beds === 'any'"
            (click)="selectOption('beds', 'any')"
          >
            {{ filterText.any }}
          </button>
          <button
            type="button"
            [class.selected]="form.beds === '1'"
            (click)="selectOption('beds', '1')"
          >
            1
          </button>
          <button
            type="button"
            [class.selected]="form.beds === '2'"
            (click)="selectOption('beds', '2')"
          >
            2
          </button>
          <button
            type="button"
            [class.selected]="form.beds === '3'"
            (click)="selectOption('beds', '3')"
          >
            3
          </button>
          <button
            type="button"
            [class.selected]="form.beds === '4'"
            (click)="selectOption('beds', '4')"
          >
            4
          </button>
          <button
            type="button"
            [class.selected]="form.beds === '5'"
            (click)="selectOption('beds', '5')"
          >
            5
          </button>

          <button
            type="button"
            [class.selected]="form.beds === '6+'"
            (click)="selectOption('beds', '6+')"
          >
            6+
          </button>
        </div>
      </div>

      <div class="room-options text-start2">
        <h4>{{ filterText.bathrooms }}</h4>
        <div class="option-buttons">
          <button
            type="button"
            [class.selected]="form.bathrooms === 'any'"
            (click)="selectOption('bathrooms', 'any')"
          >
            {{ filterText.any }}
          </button>
          <button
            type="button"
            [class.selected]="form.bathrooms === '1'"
            (click)="selectOption('bathrooms', '1')"
          >
            1
          </button>
          <button
            type="button"
            [class.selected]="form.bathrooms === '2'"
            (click)="selectOption('bathrooms', '2')"
          >
            2
          </button>
          <button
            type="button"
            [class.selected]="form.bathrooms === '3'"
            (click)="selectOption('bathrooms', '3')"
          >
            3
          </button>
          <button
            type="button"
            [class.selected]="form.bathrooms === '4'"
            (click)="selectOption('bathrooms', '4')"
          >
            4
          </button>
          <button
            type="button"
            [class.selected]="form.bathrooms === '5'"
            (click)="selectOption('bathrooms', '5')"
          >
            5
          </button>

          <button
            type="button"
            [class.selected]="form.bathrooms === '6+'"
            (click)="selectOption('bathrooms', '6+')"
          >
            6+
          </button>
        </div>
      </div>
    </div>

    <div class="filter-actions">
      <button type="submit" class="submit-button" (click)="applyFilters()">
        {{ filterText.search }}
      </button>
    </div>
  </form>
</div>
