import { Component, Input } from '@angular/core';
import { ReservationInfo } from './reservation-info';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { PropertyDTO } from 'app/new-property/property-dto';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

enum status {
  unlisted,
  active,
}
@Component({
  selector: 'app-reservation-management-table',
  templateUrl: './reservation-management-table.component.html',
  styleUrls: ['./reservation-management-table.component.css'],
})
export class ReservationManagementTableComponent {
  @Input() propertyList: PropertyDTO[] = [];
  isSlideToggleChecked: boolean = false;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private router: Router,
    private datacontext: DataContextService,
    private translate: TranslateService
  ) {}
  isFlipped = false;

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  onEditRoom(id: number) {
    this.router.navigate(['/profile', 'new-property'], {
      queryParams: { new: 1, id: id },
    });
  }

  lowesPriceRooms(property: PropertyDTO) {
    return property.Rooms.sort((a: any, b: any) => a - b)[0].price;
  }

  onChangeStatus(cardProperty: PropertyDTO) {
    let dataBody;
    if (cardProperty.status === 'active') {
      dataBody = {
        id: cardProperty.id,
        status: 'unlisted',
      };
    } else {
      dataBody = {
        id: cardProperty.id,
        status: 'active',
      };
    }
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start()
    this.datacontext
      .postData(`/property/change-status?token=${token}`, dataBody)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            cardProperty.status =
              cardProperty.status === 'active' ? 'unlisted' : 'active';
            this.datacontext.showSuccess(
              '',
              this.translate.instant('update_successful')
            );
          }
          // this.rootBlocker.stop()
        },
      });
  }
}
