<div class="container" *ngIf="searchRes" dir="auto" style="text-align: start;">
<h3 class="title"> {{'available_facilities_in'|translate}} {{searchRes.nameAr}} </h3>
  <div class="row" >
    <div class="col-sm-6 col-md-3 col-lg-2 my-3" *ngFor="let aminity of searchRes.PropertyAmenities">
      <div class="flex  gap-1" dir="auto" style="text-align: start;">
        <img [src]="aminity.Amenity.image" />
        <p class="description">{{!isFlipped?aminity.Amenity.nameAr:aminity.Amenity.nameEn}}</p>
      </div>
    </div>
  </div>
</div>
