<div class="hotel-rate" *ngIf="searchRes"  [dir]="isFlipped ? 'rtl' : 'ltr'" style="text-align: start;">
  <div class="hotel-div1"  [dir]="isFlipped ? 'rtl' : 'ltr'" style="text-align: start;">
    <h6 class="num-rate2">{{searchRes.TotalRatings}} {{'review'|translate}}</h6>

    <!-- <h6 class="num_rate_1">جيد جداً</h6> -->
    <h6 class="num1">{{searchRes.averageRating.toFixed(1)}}</h6>
  </div>

  <div class="row ">
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
      <h4 class="rate-label">{{'overall_rating'|translate}}</h4>
      <div class="rate-flex">
        <div class="bar-container">
          <div class="bar-5" [style.width]="searchRes.RatingsStars[4]"></div>
        </div>
        <p class="bar-num">5</p>
      </div>
      <div class="rate-flex">
        <div class="bar-container">
          <div class="bar-4" [style.width]="searchRes.RatingsStars[3]"></div>
        </div>
        <p class="bar-num">4</p>
      </div>
      <div class="rate-flex">
        <div class="bar-container">
          <div class="bar-3" [style.width]="searchRes.RatingsStars[2]"></div>
        </div>
        <p class="bar-num">3</p>
      </div>
      <div class="rate-flex">
        <div class="bar-container">
          <div class="bar-2" [style.width]="searchRes.RatingsStars[1]"></div>
        </div>
        <p class="bar-num">2</p>
      </div>
      <div class="rate-flex">
        <div class="bar-container">
          <div class="bar-1" [style.width]="searchRes.RatingsStars[0]"></div>
        </div>
        <p class="bar-num">1</p>
      </div>
    </div>
    <div
      class="col-xs-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 d-flex align-items-center flex-column"
    >
      <h4 class="raiting-title">{{'location'|translate}}</h4>
      <h4 class="rate-dec">{{searchRes.RatingItems.location.toFixed(1)}}</h4>
      <img src="../../assets/location.svg" class="loc" />
    </div>
    <div
      class="col-xs-6 col-sm-6 col-md-2 col-lg-2 d-flex align-items-center flex-column"
    >
      <h4 class="raiting-title">{{'value'|translate}}</h4>
      <h4 class="rate-dec">{{searchRes.RatingItems.value.toFixed(1)}}</h4>
      <img src="../../assets/_x32_3_Diamond.svg" class="diamond" />
    </div>
    <div
      class="col-xs-6 col-sm-6 col-md-2 col-lg-2 d-flex align-items-center flex-column"
    >
      <h4 class="raiting-title">{{'work_method'|translate}}</h4>
      <h4 class="rate-dec">{{searchRes.RatingItems.staff.toFixed(1)}}</h4>
      <img src="../../assets/group.svg" class="people" />
    </div>
    <div
      class="col-xs-6 col-sm-6 col-md-2 col-lg-2 d-flex align-items-center flex-column"
    >
      <h4 class="raiting-title">{{'facilities'|translate}}</h4>
      <h4 class="rate-dec">{{searchRes.RatingItems.amenities.toFixed(1)}}</h4>
      <img src="../../assets/reception.svg" class="reception" />
    </div>
    <div
      class="col-xs-12 col-sm-12 col-md-2 col-lg-2 d-flex align-items-center flex-column"
    >
      <h4 class="raiting-title">{{'cleanliness'|translate}}</h4>
      <h4 class="rate-dec">{{searchRes.RatingItems.clean.toFixed(1)}}</h4>
      <img src="../../assets/outline.svg" class="spray" />
    </div>
  </div>
</div>
