import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { CancellationPolicyDTO } from 'app/rooms-managment/rooms-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.css'],
})
export class CancellationPolicyComponent {
  html: SafeHtml = '';
  lang = 'en';
  constructor(
    private DataContextService: DataContextService,
    private DomSanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    this.lang = this.translate.currentLang;
    this.DataContextService.websiteInfo.subscribe((d: WebsiteInfoDTO) => {
      this.translate.currentLang === 'en'
        ? d.cancellationPolicies && d.cancellationPolicies.length > 0
          ? (this.html = DomSanitizer.bypassSecurityTrustHtml(
              this.formatCancellationPolicy(d.cancellationPolicies)
            ))
          : ''
        : d.cancellationPolicies && d.cancellationPolicies.length > 0
        ? (this.html = DomSanitizer.bypassSecurityTrustHtml(
            this.formatCancellationPolicy(d.cancellationPolicies)
          ))
        : '';
    });
  }

  formatCancellationPolicy(
    cancellationPolicy: CancellationPolicyDTO[]
  ): string {
    let str = '';
    cancellationPolicy.forEach((policy: CancellationPolicyDTO) => {
      str += `<b>${
        this.translate.currentLang === 'en' ? policy.nameEn : policy.nameAr
      }</b><br>`;
      str += `${
        this.translate.currentLang === 'en' ? policy.discEn : policy.discAr
      }<br><br><br>`;
    });
    return str;
  }
}
