import { Component, Input, OnInit } from '@angular/core';


import {CategoryItemInterface} from '../category-item/category-item-interface'
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  @Input() websiteGeneralInfo:WebsiteInfoDTO

  categoriesDetails:CategoryItemInterface[]=[]
  // [
  //   {

  //     categoryTitle:'فندق',
  //     id:1,
  //   },
  //   {

  //     categoryTitle:'قصور',
  //     id:2
  //   },
  //   {

  //     categoryTitle:'فيلا',
  //     id:3
  //   },
  //   {

  //     categoryTitle:'شقة',
  //     id:4
  //   },
  //   {

  //     categoryTitle:'شواطئ',
  //     id:5
  //   },
  //   {

  //     categoryTitle:'مخيم',
  //     id:6
  //   },
  //   {

  //     categoryTitle:'جبلي',
  //     id:7
  //   },
  //   {

  //     categoryTitle:'استراحة',
  //     id:8
  //   },
  //   {

  //     categoryTitle:'ريف',
  //     id:9
  //   },
  //   {

  //     categoryTitle:'مسابح',
  //     id:10
  //   },
  // ]


// @BlockUI('root') rootBlocker!:NgBlockUI

constructor(private datacontext:DataContextService){
  this.gettingCategories()
}

private gettingCategories(){
    this.datacontext.getData('/categories/list').subscribe((value:any) => {
      this.categoriesDetails = value.data.reverse()
    })
  }
}
