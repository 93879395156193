import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { retry } from 'rxjs';

@Component({
  selector: 'app-reservation-info',
  templateUrl: './reservation-info.component.html',
  styleUrls: ['./reservation-info.component.css']
})
export class ReservationInfoComponent implements OnChanges {
constructor(private translate: TranslateService,private datacontext:DataContextService,private activatedRoute:ActivatedRoute) {
  this.id = this.activatedRoute.snapshot.params['id'];

}
  @Input() searchRes: SearchDTO | null;
  id=0;
  searchDataResult:any
  resreveInfo = {
    checkInTime: 'من 12:00 مساءً إلى 3:00 مساءً',
    checkOutTime: 'من 10:00 صباحاً إلى 12:00 مساءً',
    cancellationPolicy:'',
    checkIn: 'تسجيل الوصول',
    checkOut: 'تسجيل المغادرة',
    cancellation: 'إلغاء الحجز',
    reserveTitle: 'معلومات مهمة',
    mapTitle: 'اعرض خريطة الوصول',
    mapImage: '../../assets/Screenshot 2023-12-27 at 9.25.22ΓÇ»PM.png'
  };

  options: google.maps.MapOptions = {
    mapId: "Arrovia",
    zoom: 6,
  };

  circleCenter: google.maps.LatLngLiteral = { lat: 23.885942, lng: 45.079162 };
  circleRadius: number = 30000; // Adjust the radius as needed

  circleOptions: google.maps.CircleOptions = {
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
  };
  ngOnInit(): void {
    const token = this.datacontext.getRouteParams('token')
    let url;
    if(token){
      url =`/search/find/${this.id}?token=${token}`
    }else{
      url = `/search/find/${this.id}`
    }
  this.datacontext.getData(url).pipe(
    retry(2)).subscribe({
    next:(value:any) =>{
      if(value.success){
        const searchDataResult = value.data[0]
        this.searchDataResult=searchDataResult
        console.log(searchDataResult,'hey iam hazem')
  if(searchDataResult.Rooms.length){
    this.resreveInfo.cancellationPolicy =this.translate.currentLang=='en'? searchDataResult? searchDataResult.Rooms[0].CancellationPolicy.discEn||'':'':searchDataResult? searchDataResult.Rooms[0].CancellationPolicy.discAr||'':''

  }

      }
      // this.rootBlocker.stop()
    }
  })
    console.log(this.searchRes)
  // this.resreveInfo.cancellationPolicy =this.translate.currentLang=='en'? this.searchRes? this.searchRes.CancellationPolicy.discEn||'':'':this.searchRes? this.searchRes.CancellationPolicy.discAr||'':''
}
  ngOnChanges(changes: SimpleChanges): void {
  // this.resreveInfo.cancellationPolicy =this.translate.currentLang=='en'? this.searchRes? this.searchRes.CancellationPolicy.discEn||'':'':this.searchRes? this.searchRes.CancellationPolicy.discAr||'':''
  console.log(this.searchRes)
    
    if (changes['searchRes'] && this.searchRes?.mapLon && this.searchRes.mapLat) {
      this.options.center = { lat: +this.searchRes?.mapLat, lng: +this.searchRes?.mapLon };
      this.circleCenter = { lat: +this.searchRes?.mapLat, lng: +this.searchRes?.mapLon };
    }
  }
}
