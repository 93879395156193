import { Component, Input } from '@angular/core';
import { RatingsCommentsDTO, SearchDTO } from 'app/search-details/search-dto';

@Component({
  selector: 'app-user-reviews',
  templateUrl: './user-reviews.component.html',
  styleUrls: ['./user-reviews.component.css']
})
export class UserReviewsComponent {
  @Input() ratingComments:SearchDTO | null = null

  reviews = {

    name: 'يزيد العمري',
    image: '../../assets/user.jfif',
};
}
