import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OrderDTO } from 'app/all-orders/order-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, throwError } from 'rxjs';
@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.css'],
})
export class OrdersTableComponent implements OnInit {
  @Input() ordersList: OrderDTO[] = [];
  isAllOrders: boolean = false;
  @Output() filterStatus: EventEmitter<string> = new EventEmitter();
  @Output() handelReloadData: EventEmitter<any> = new EventEmitter();
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private route: Router,
    private datacontext: DataContextService,
    private translate: TranslateService
  ) {
    this.route.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // just for render the all offers button
        this.isAllOrders = e.url.includes('/all-orders');
      }
    });
  }
  isFlipped: boolean = false;

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';
    console.log(this.ordersList);
    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is English
    });
  }
  ngOnChanges(): void {
    console.log(this.ordersList?.[0]);
  }
  windowPrint() {
    window.print();
  }

  onExportExcel() {
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start()

    if (this.ordersList.length === 0) {
      return this.datacontext.showError('', 'لا يوجد بيانات');
    }
    this.datacontext
      .downloadExcelFile(`/requests/export-excel?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        const blob = new Blob([value], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'orders.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // this.rootBlocker.stop()
      });
  }

  onPDFExport() {
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start()
    if (this.ordersList.length === 0) {
      return this.datacontext.showError('', 'لا يوجد بيانات');
    }
    this.datacontext
      .downloadExcelFile(`/requests/export-pdf?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        const blob = new Blob([value], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'orders.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // this.rootBlocker.stop()
      });
  }

  timerId: any;
  onSearchOrders(e: Event) {
    const inputValue = (e.target as HTMLInputElement).value;

    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      this.searchOrders(inputValue);
    }, 1000);
  }

  private searchOrders(name: string) {
    // this.rootBlocker.start()
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .getData(`/requests/list/${name}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.ordersList = value.data;
        }
        // this.rootBlocker.stop()
        // console.log(this.ordersList)
      });
  }

  handelOrderDetails(order: OrderDTO) {
    localStorage.setItem('orderDetails', JSON.stringify(order));
    localStorage.setItem('reservationDetails', JSON.stringify(order));
    this.route.navigate(['/profile', 'reservation-details', order.id]);
  }

  backHistory() {
    window.history.back();
  }

  filterOrders(status: string) {
    this.filterStatus.emit(status);
  }

  onAcceptOrder(order: OrderDTO) {
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .postData(`/kamel/accept/${order.id}?token=${token}`, {})
      .subscribe((data) => {
        const response = data as any;

        if (response.success) {
          this.datacontext.showSuccess('', 'تم قبول الطلب');
          this.handelReloadData.emit();
        }
      });
  }

  onRejectOrder(order: OrderDTO) {
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .postData(`/kamel/reject/${order.id}?token=${token}`, {})
      .subscribe((data) => {
        const response = data as any;

        if (response.success) {
          this.datacontext.showSuccess('', 'تم رفض الطلب');
          this.handelReloadData.emit();
        }
      });
  }
}
