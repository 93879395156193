<div class="container mx-auto my-4">
  <app-navigation
    [back]="queryId == 3 ? '/profile/reservation-management' : '/'"
  ></app-navigation>
  <div *ngIf="step !== 4" class="progress-container">
    <!-- Labels for each step -->
    <div class="progress-labels">
      <div class="progress-label">{{ "review_booking" | translate }}</div>
      <div class="progress-label">
        {{ "bbooking_confirmation" | translate }}
      </div>
      <div class="progress-label">{{ "complete_payment" | translate }}</div>
    </div>
    <!-- Progress bar with dynamic classes based on the current step -->
    <ul id="progressbar" class="progress-container custom-progress-bar">
      <div class="progress-li">
        <!-- First step -->
        <li [ngClass]="step >= 1 ? 'active ' : 'inactive'"></li>

        <!-- Second step -->
        <li [ngClass]="step >= 2 ? 'active' : 'inactive'"></li>

        <!-- Third step -->
        <li [ngClass]="step == 3 ? 'active' : 'inactive'"></li>
      </div>
    </ul>
  </div>
</div>

<div class="container-s">
  <app-review-reservations
    (onHandelConfirmReservation)="handelConfirmation($event)"
    *ngIf="step === 1"
  ></app-review-reservations>
</div>

<div class="cont">
  <div class="orientation">
    <!-- <div class="mysr-form mysr-form-moyasarForm" style="width: 450px; margin: auto; margin-bottom: 20px;" id="mysr-form-form-el" payment-form="true">
          <div class="mysr-form-methodButtons">
        </div>
        <div>
          <div class="mysr-form-method m-auto text-center">
            <form class="m-auto text-center">
              <div class="mysr-form-inputGroup">
                <div class="mysr-form-labelGroup">
                  <label class="mysr-form-label" for="mysr-cc-name">Name on card</label>
                  <span class="mysr-form-requiredAlert mysr-form-requiredAlertHidden">Required</span>
                </div>
                <div>
                  <input id="mysr-cc-name" class="mysr-form-input" placeholder="Name on card" autocomplete="ccname" dir="ltr">
                  <div class="mysr-form-alertContainer">
                    <span class="mysr-form-inputAlert mysr-form-alertDanger mysr-form-inputAlertHidden"></span>
                  </div>
                </div>
              </div>
              <div class="mysr-form-inputGroup">
                <div class="mysr-form-labelGroup">
                  <label class="mysr-form-label" for="mysr-cc-number">Card information</label>
                  <span class="mysr-form-requiredAlert mysr-form-requiredAlertHidden">Required</span>
                </div>
                <div class="mysr-form-cardInfo">
                  <div class="mysr-form-cardInfoElement mysr-form-ccInputGroup mysr-form-cardInfo" dir="ltr">
                    <input id="mysr-cc-number" type="text" class="mysr-form-input mysr-form-bottomLeftRadius0 mysr-form-bottomRightRadius0" placeholder="1234 5678 9101 1121" autocomplete="cc-number" inputmode="numeric">
                    <div class="mysr-form-ccIconsGroup">
                      <span class="mysr-form-ccIcon mysr-form-ccIconAmex"></span>
                      <span class="mysr-form-ccIcon mysr-form-ccIconMada"></span>
                      <span class="mysr-form-ccIcon mysr-form-ccIconVisa"></span>
                      <span class="mysr-form-ccIcon mysr-form-ccIconMastercard"></span>
                    </div>
                  </div>
                  <div class="mysr-form-cardInfoElement mysr-form-ccInputGroup mysr-form-cardInfo" dir="ltr">
                    <input class="mysr-form-input mysr-form-cardInfoElement mysr-form-cardInfoHalfWidth mysr-form-topLeftRadius0 mysr-form-topRightRadius0 mysr-form-bottomRightRadius0" type="text" placeholder="MM / YY" autocomplete="cc-exp" inputmode="numeric">
                    <input class="mysr-form-input mysr-form-cardInfoElement mysr-form-cardInfoHalfWidth mysr-form-topLeftRadius0 mysr-form-topRightRadius0 mysr-form-bottomLeftRadius0" type="text" placeholder="CVC" autocomplete="cc-csc" inputmode="numeric">
                  </div>
                  <div class="mysr-form-alertContainer"></div>
                </div>
              </div>
              <button (click)="onPaymentConfirm()" class="confirm-payment" style="font-size: 17px;font-family: 'black';">اتمام الدفع&nbsp;{{reserveData.netPrice| number:'1.2-2'}}</button>
            </form>
          </div>
        </div>
        </div> -->
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      "
      *ngIf="timeLeft > 0"
    >
      {{ "timeLeft" | translate }}: {{ minutes }}:{{ seconds | number : "2.0" }}
    </div>

    <br />
    <br />
    <form
      class="mysr-form"
      [dir]="!isFlipped ? 'rtl' : 'ltr'"
      [ngStyle]="{ visibility: step !== 2 ? 'hidden' : 'visible' }"
    >
      <!-- Your payment form fields -->
    </form>

    <div *ngIf="step == 2">
      <button
        *ngIf="createdAt === 'undefined'"
        class="action-button btn-back mt-4"
        (click)="onHandelPrevious()"
      >
        {{ "go_back" | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf="step === 3">
  <app-final-review-reservation></app-final-review-reservation>
</div>
<div style="margin: 50px auto" *ngIf="step === 4">
  <div class="thank-you-page container">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
      class="thank-you-content"
    >
      <h2 class="thank-you-heading">
        {{ "thank_you_for_your_request" | translate }}
      </h2>
      <p class="thank-you-message">{{ "we_will_inform_you" | translate }}</p>
      <button
        style="
          background-color: #ef3c23;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
        "
        class="btn btn-lg"
        routerLink="/"
      >
        {{ "back_to_home" | translate }}
      </button>
    </div>
  </div>
</div>
