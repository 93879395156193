import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {CategoryItemInterface} from '../category-item/category-item-interface'
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, map, of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoryDTO } from './category-dto';
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  categoriesDetails:CategoryItemInterface[]=[]
  // [
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 688.svg',
  //     categoryTitle:'فندق',
  //     id:1
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 687.svg',
  //     categoryTitle:'قصور',
  //     id:2
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 686.svg',
  //     categoryTitle:'فيلا',
  //     id:3,
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/bed.svg',
  //     categoryTitle:'شقة',
  //     id:4
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 685.svg',
  //     categoryTitle:'شواطئ',
  //     id:5
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 684.svg',
  //     categoryTitle:'مخيم',
  //     id:6
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 683.svg',
  //     categoryTitle:'جبلي',
  //     id:7
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 682.svg',
  //     categoryTitle:'استراحة',
  //     id:8
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 681.svg',
  //     categoryTitle:'ريف',
  //     id:9
  //   },
  //   {
  //     categoryImage:'assets/aroovia-icons/Group 680.svg',
  //     categoryTitle:'مسابح',
  //     id:10
  //   },
  // ]
  constructor(private datacontext:DataContextService){}

    ngOnInit(): void {
      this.gettingCategories()
    }

    private gettingCategories(){
      this.datacontext.getData('/categories/list').subscribe((value:any) => {
        this.categoriesDetails = value.data.reverse()
      })
    }
}
