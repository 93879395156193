import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { SharedStateService } from 'app/sharedStateService/sharedState.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
declare var Moyasar: any;
@Component({
  selector: 'app-reservations-stepper',
  templateUrl: './reservations-stepper.component.html',
  styleUrls: ['./reservations-stepper.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ReservationsStepperComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  targetDate: Date; // The date to start the countdown from
  timeLeft: number; // Time left in milliseconds
  interval: any;
  minutes: number = 0;
  seconds: number = 0;
  createdAt: string;
  reservationPrice: number;
  reserevationId: number;
  BaseURL = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private datacontent: DataContextService,
    private translate: TranslateService,
    private sharedStateService: SharedStateService
  ) {}

  isFlipped = false;

  queryId: number = 0;
  reserveData: any;
  reservationDetails: any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const reserevationId = params['reserevationId'];
      const reservationPrice = params['price'];
      const createdAt = params['createdAt'];
      console.log('hey', reserevationId, reservationPrice);

      if (reserevationId && reservationPrice) {
        console.log('hey', reserevationId, reservationPrice);
        const UserInfo = JSON.parse(localStorage.getItem('userInfo')!);
        const userId = UserInfo.id;
        this.BaseURL = window.location.protocol + '//' + window.location.host; //+this.reservationDetails.id;
        this.startCountdown(this.parseDate(createdAt)); // Provide the date to count down from
        Moyasar.init({
          element: '.mysr-form',
          amount: reservationPrice * 100,
          currency: 'SAR',
          // on_cancelled: () => {
          //   this.datacontent.showError('', 'Payment has been cancelled');
          //   this.router.navigate(['/home']); // Navigate to the home page
          // },
          metadata: {
            reserevationId,
            userId,
          },
          description: 'Order ID: ',

          callback_url:
            this.BaseURL + '/profile/reservation-details/' + reserevationId,
          publishable_api_key:
            'pk_test_PezJk4BYk21umRxDJ3RbP4GUcXkATbixr9CYpn45',
          methods: ['applepay', 'creditcard'],
          language: this.translate.currentLang || 'ar',
          apple_pay: {
            country: 'SA',
            label: 'Apple Pay',
            validate_merchant_url:
              'https://api.moyasar.com/v1/applepay/initiate',
          },

          // on_completed: this.PaymentResult,
          // on_completed: (payment: any) => {
          //   return new Promise((resolve, reject) => {
          //     this.handlePaymentCompletion(payment)
          //       .then(() => resolve({}))
          //       .catch(() => reject());
          //   });
          // },
        });
        this.step = 2;
      } else {
        const roomReservation = JSON.parse(
          localStorage.getItem('constReservtionData')!
        );
        this.reservationDetails = JSON.parse(
          localStorage.getItem('reservationDetails')!
        );
        console.log(this.reservationDetails);
        this.BaseURL = window.location.protocol + '//' + window.location.host; //+this.reservationDetails.id;
        this.activatedRoute.queryParams.subscribe((query) => {
          const step = query['step'];
          this.queryId = query['step'];
          if (step === '3' && this.reservationDetails) {
            this.step = 3;
          } else if (!roomReservation || !this.reservationDetails) {
            console.log('hey');
            this.router.navigate(['/']);
          }
        });
      }
    });

    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  parseDate(dateString: string): Date {
    // Check if Safari-compatible date string is needed
    const parsedDate = new Date(dateString);

    // If `new Date(dateString)` fails, fallback to manually parsing the date
    if (isNaN(parsedDate.getTime())) {
      // Use regex to handle the date string and manually parse it if needed
      const dateParts = dateString.split(/[^0-9]/); // Split by non-numeric characters
      return new Date(
        Date.UTC(
          parseInt(dateParts[0], 10), // Year
          parseInt(dateParts[1], 10) - 1, // Month (0-indexed)
          parseInt(dateParts[2], 10), // Day
          parseInt(dateParts[3], 10), // Hour
          parseInt(dateParts[4], 10), // Minute
          parseInt(dateParts[5], 10) // Second
        )
      );
    }

    return parsedDate;
  }
  startCountdown(startDate: Date): void {
    // Set the target time to 10 minutes from the given date
    const tenMinutesInMs = 10 * 60 * 1000;
    const targetTime = new Date(startDate.getTime() + tenMinutesInMs);

    // Start the countdown
    this.interval = setInterval(() => {
      this.timeLeft = targetTime.getTime() - new Date().getTime();

      if (this.timeLeft <= 0) {
        window.location.href = '/home';
        clearInterval(this.interval);
        this.timeLeft = 0;
      } else {
        this.minutes = Math.floor((this.timeLeft / 1000 / 60) % 60);
        this.seconds = Math.floor((this.timeLeft / 1000) % 60);
      }
    }, 1000);
  }

  step: number = 1;
  reservationsData: any;
  respon: any;
  async handelConfirmation(confirm: FormGroup) {
    this.sharedStateService.updateDisabledState(true);

    console.log('confirmed');

    this.reserveData = JSON.parse(localStorage.getItem('reserveData')!);
    if (confirm.value) {
      const roomReservation = JSON.parse(
        localStorage.getItem('constReservtionData')!
      );
      const rooms = JSON.parse(localStorage.getItem('roomsDetails')!);
      // [{id, quantity, adults, childerns, infants}]/

      if (rooms) {
        let filteredRooms = rooms.map((room: any) => {
          return {
            id: room.room.id,
            quantity: room.room.roomCount,
          };
        });

        const dataBody = {
          startDate: roomReservation.startDate,
          endDate: roomReservation.endDate,
          firstName: confirm.value.firstName,
          lastName: confirm.value.lastName,
          email: confirm.value.email,
          PropertyId: roomReservation.property.id,
          mobile: '+966' + confirm.value.phoneNumber,
          rooms: filteredRooms,
          infants: roomReservation.infants,
          adults: roomReservation.adults,
          childerns: roomReservation.children,
        };

        if (
          !dataBody.mobile ||
          !dataBody.email ||
          !dataBody.firstName ||
          !dataBody.lastName
        ) {
          this.sharedStateService.updateDisabledState(false);
          this.datacontent.showError(
            '',
            this.translate.instant('fill_all_fields')
          );
          return;
        }

        this.reservationsData = dataBody;

        localStorage.setItem(
          'reservationInfodataBody',
          JSON.stringify(dataBody)
        );
        const reserveData = JSON.parse(localStorage.getItem('reserveData')!);
        const token = this.datacontent.getRouteParams('token');
        const reservationsData = JSON.parse(
          localStorage.getItem('reservationInfodataBody')!
        );
        const userInfo = JSON.parse(localStorage.getItem('userInfo')!);
        console.log(userInfo, 'userInfo');
        console.log(reservationsData, 'hello');
        try {
          const res: any = await this.datacontent
            .postData(`/kamel/create?token=${token}`, reservationsData)
            .toPromise();

          console.log(res); //reservation-details/123
          console.log('iam here');
          if (res.success == false) {
            this.sharedStateService.updateDisabledState(false);
            this.datacontent.showError(
              '',
              this.translate.instant(`${res.message}`)
            );
            return;
          }
          const constReservtionData = JSON.parse(
            localStorage.getItem('constReservtionData')!
          );
          const reserveData = JSON.parse(localStorage.getItem('reserveData')!);
          console.log(constReservtionData.property.isAutoAccepted);
          if (constReservtionData.property.isAutoAccepted == false) {
            this.step = 4;

            // Check if the page is being reloaded
            if (typeof window !== 'undefined') {
              window.addEventListener('beforeunload', () => {
                // Redirect to the home page
                console.log('beforeunload');
                this.router.navigate(['/']);
              });
            }

            return;
          }
          console.log('hey', res.data.createdAt);
          this.startCountdown(this.parseDate(res.data.createdAt));
          // Provide the date to count down from
          Moyasar.init({
            element: '.mysr-form',
            amount: reserveData.netPrice * 100,
            currency: 'SAR',
            // on_cancelled: () => {
            //   this.datacontent.showError('', 'Payment has been cancelled');
            //   this.router.navigate(['/home']); // Navigate to the home page
            // },
            metadata: {
              reserevationId: res.data.id,
              userId: userInfo?.id,
            },
            description:
              'Order ID: ' +
              res.data.id +
              ' BY ' +
              this.reservationDetails.firstName,

            callback_url:
              this.BaseURL + '/profile/reservation-details/' + res.data.id,
            publishable_api_key:
              'pk_test_PezJk4BYk21umRxDJ3RbP4GUcXkATbixr9CYpn45',
            methods: ['applepay', 'creditcard'],
            language: this.translate.currentLang || 'ar',
            apple_pay: {
              country: 'SA',
              label: 'Apple Pay',
              validate_merchant_url:
                'https://api.moyasar.com/v1/applepay/initiate',
            },

            // on_completed: this.PaymentResult,
            // on_completed: (payment: any) => {
            //   return new Promise((resolve, reject) => {
            //     this.handlePaymentCompletion(payment)
            //       .then(() => resolve({}))
            //       .catch(() => reject());
            //   });
            // },
          });

          this.step++;

          localStorage.setItem('reservationDetails', JSON.stringify(res.data));
        } catch (err: any) {
          console.log(err.error.message);
          console.log(err);
          this.sharedStateService.updateDisabledState(false);
          return this.datacontent.showError(
            '',
            this.translate.instant(`${err.error.message}`)
          );
        }
      }
      this.sharedStateService.updateDisabledState(false);
    }
  }

  formVisa = new FormGroup({
    name: new FormControl('', [Validators.required]),
    number: new FormControl('', [
      Validators.required,
      Validators.minLength(16),
      Validators.maxLength(16),
    ]),
    month: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.min(1),
      Validators.max(12),
    ]),
    year: new FormControl('', [Validators.required]),
    cvc: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(4),
    ]),
  });
  PaymentResult(payment: any) {
    console.log(payment);
    debugger;
    if (payment.status === 'paid') {
      // Payment was successful
      this.datacontent.showSuccess(
        '',
        this.translate.instant('Payment successful')
      );
      // Redirect to reservation details page
      this.router.navigate([
        '/profile/reservation-details',
        this.reservationDetails.id,
      ]);
    } else {
      // Payment failed or was cancelled
      this.datacontent.showError(
        '',
        this.translate.instant('Payment was not completed')
      );
      // Redirect to home page
      this.router.navigate(['/home']);
    }
  }
  onPaymentConfirm() {
    // if(this.formVisa.valid){
    // let URL_Now = this.router.parseUrl(this.router.url);
    // debugger;
    const url = 'https://api.moyasar.com/v1/payments';
    const username = 'sk_test_45pmebWRCoFUNA1sffx7wLTx3QQdwCLgKxsdDBEX';
    const password = '';

    const credentials = `${username}:${password}`;
    const encodedCredentials = btoa(credentials);
    // Moyasar.init({
    //   element: '.mysr-form',
    //   // Amount in the smallest currency unit.
    //   // For example:
    //   // 10 SAR = 10 * 100 Halalas
    //   // 10 KWD = 10 * 1000 Fils
    //   // 10 JPY = 10 JPY (Japanese Yen does not have fractions)
    //   amount: 1000,
    //   currency: 'SAR',
    //   description: 'Coffee Order #1',
    //   publishable_api_key: 'pk_test_PezJk4BYk21umRxDJ3RbP4GUcXkATbixr9CYpn45',
    // pk_live_ikpu9PygufhCChL1aKx3U4h3EGrvMFv8boXbJT1W
    //   callback_url: 'https://moyasar.com/thanks',
    //   methods: ['applepay', 'creditcard'],
    //   language: this.translate.currentLang || 'ar',
    //   apple_pay: {
    //     country: 'SA',
    //     label: 'Awesome Cookie Store',
    //     validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
    //   },
    // });
    // let visaData = {
    //   amount: this.reserveData.netPrice*100,
    //   currency: "SAR",
    //   description: "Order ID: " + this.reservationDetails.id + " BY " + this.reservationDetails.firstName,
    //   publishable_api_key: "pk_test_PezJk4BYk21umRxDJ3RbP4GUcXkATbixr9CYpn45",
    //   callback_url: this.BaseURL+"/profile/reservation-details/"+this.reservationDetails.id,//()=>{window.location.assign(this.BaseURL);return this.BaseURL},//+ this.reservationDetails.id,
    //   source: {
    //     type: "creditcard",
    //     name: this.formVisa.controls['name'].value,
    //     number: this.formVisa.controls['number'].value,
    //     month: this.formVisa.controls['month'].value,
    //     year: this.formVisa.controls['year'].value,
    //     cvc: this.formVisa.controls['cvc'].value,
    //     transaction_url: "https://api.moyasar.com/v1/transaction_auths/9c76e1f6-5f13-44ee-922b-b5d9aecdb702/form?token=auth_tJRo8PQJAk9JBSK5BxZaKxeyr46mdzMLP9K"
    //   },
    //   // merchantCapabilities: ['supports3DS'],
    // }
    //   fetch(url,
    //     {
    //       headers: {
    //         'Authorization': `Basic ${encodedCredentials}`,
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //       },
    //       method: "post",
    //       body: JSON.stringify(visaData)
    //     }
    //   )
    //   .then(response => response.json())
    //   .then((data) => {
    //     localStorage.setItem('moyasarData', JSON.stringify(data));
    //     this.completeReserve(data);
    //     return data;
    //   })
    //   // .then(async (data:any)=>{
    //   //   const token = this.datacontent.getRouteParams('token')
    //   //   // let id = this.reservationsData.PropertyId;
    //   //   let id = this.reservationDetails.id
    //   //   await this.datacontent.postData(`/property/change-status?token=${token}`,{id:id, status:'active'}).subscribe({
    //   //   })
    //   //   return data;
    //   // })
    //   // .then((data)=>{
    //   //   const token = this.datacontent.getRouteParams('token');
    //   //   this.datacontent.getData(`/validate-payment/${data.id}?token=${token}`).subscribe({
    //   //   })
    //   // })
    //   .catch(error => {
    //     // debugger;
    //     this.datacontent.showError('', error.error.message)
    //   })
    // }
    // else{
    //   Object.keys(this.formVisa.controls).forEach(key => {
    //     const controlErrors: ValidationErrors|null|undefined = this.formVisa.get(key)?.errors;
    //     if (controlErrors != null) {
    //       Object.keys(controlErrors).forEach(keyError => {
    //        this.datacontent.showError('', 'Key control: ' + key + ', keyError: ' + keyError + ', err value: '+ controlErrors[keyError])
    //       });
    //     }
    //   });
    // }
  }

  completeReserve(data: any) {
    const token = this.datacontent.getRouteParams('token');
    this.datacontent
      .postData(`/kamel/create?token=${token}`, this.reservationsData)
      .subscribe({
        next: async (res: any) => {
          this.respon = res;
          this.datacontent.showSuccess(
            '',
            this.translate.instant('Booking successful')
          );
          // debugger;
          localStorage.setItem('reservationDetails', JSON.stringify(res.data));
          // let id = this.reservationDetails.id
          // this.completeReserve2(id);
          window.location.assign(data.source.transaction_url);
        },
        error: (err: HttpErrorResponse) => {
          // debugger;
          this.datacontent.showError(
            '',
            this.translate.instant(`${err.error.message}`)
          );
        },
      });
  }

  async completeReserve2(id: any) {
    const token = this.datacontent.getRouteParams('token');
    // debugger;

    this.datacontent
      .postData(`/property/change-status?token=${token}`, {
        id,
        status: 'active',
      })
      .subscribe({
        next: (d) => {},
        error: (er) => {},
      });
    return id;
  }

  // createLocalReservation() {
  //   const token = this.datacontent.getRouteParams('token');
  //   // debugger;
  //   this.datacontent.postData(`/kamel/create?token=${token}`, this.reservationsData).pipe(
  //     catchError((err: HttpErrorResponse) => {
  //       // debugger;
  //       return throwError(() => {
  //         // this.rootBlocker.stop()
  //         this.datacontent.showError('', err.error.message)
  //       })
  //     })
  //   ).subscribe((value: any) => {
  //     if (value.success) {
  //       // debugger;
  //       this.datacontent.showSuccess('', 'تم الحجز بنجاح')
  //       localStorage.setItem('reservationDetails', JSON.stringify(value.data))
  //       // this.rootBlocker.stop()
  //       // this.step++
  //       // this.router.navigate(['/profile/reservation-details/'+this.reservationDetails.id])
  //     }else{
  //     }
  //   })

  // }

  onHandelPrevious() {
    this.step--;
  }

  onApplePayButtonClicked() {}

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    const roomReservation = JSON.parse(
      localStorage.getItem('constReservtionData')!
    );
    // const reservationDetails = JSON.parse(localStorage.getItem('reservationDetails')!)
    const reservationInfo = JSON.parse(
      localStorage.getItem('reservationInfo')!
    );
    if (roomReservation) {
      localStorage.removeItem('constReservtionData');
    }

    // if(reservationDetails){
    //   localStorage.removeItem('reservationDetails')
    // }
    if (reservationInfo) {
      localStorage.removeItem('reservationInfo');
    }
  }

  // Add this new method to the component class
  // Add this new method to the component class
  private getCallbackUrl(): string {
    if (this.isReservationSuccessful()) {
      // If the reservation was successful, go to the reservation details page
      return (
        this.BaseURL +
        '/profile/reservation-details/' +
        this.reservationDetails.id
      );
    } else {
      // If the reservation was not successful, go to the home page
      return this.BaseURL + '/home';
    }
  }

  // You'll need to implement this method based on your logic
  private isReservationSuccessful(): boolean {
    // Implement your logic here to determine if the reservation was successful
    // You can check a status from the reservationDetails object or other relevant data
    return (
      this.reservationDetails && this.reservationDetails.status === 'success'
    );
  }

  // Add this method to your component class
  private handlePaymentCompletion(payment: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const token = this.datacontent.getRouteParams('token');
      this.datacontent
        .getData(`/kamel/validate-payment/${payment.id}?token=${token}`)
        .subscribe({
          next: (response: any) => {
            console.log(response);
            if (response.success) {
              // Payment was successful
              this.datacontent.showSuccess(
                '',
                this.translate.instant('Payment successful')
              );
              // Redirect to reservation details page
              this.router.navigate([
                '/profile/reservation-details',
                this.reservationDetails.id,
              ]);
              resolve();
            } else {
              // Payment failed or was cancelled
              this.datacontent.showError(
                '',
                this.translate.instant('Payment was not completed')
              );
              // Redirect to home page
              this.router.navigate(['/home']);
              reject();
            }
          },
          error: (error) => {
            console.error('Error validating payment:', error);
            this.datacontent.showError(
              '',
              this.translate.instant('Error validating payment')
            );
            // Redirect to home page on error
            this.router.navigate(['/home']);
            reject();
          },
        });
    });
  }
}
