import { ReservationInfo } from 'app/reservation-management-table/reservation-info';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PropertyDTO, propertyStastics } from 'app/new-property/property-dto';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css'],
})
export class ReservationsComponent implements OnInit {
  @BlockUI('root') rootBlocker!: NgBlockUI;

  constructor(
    private router: Router,
    private datacontext: DataContextService
  ) {}
  propertyList: PropertyDTO[];
  propertyStastics: propertyStastics;

  currentPage = 1;
  limit = 5;
  ngOnInit(): void {
    this.getPropertyList();
  }
  totalPages: number;
  private getPropertyList() {
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start();
    this.datacontext
      .getData(
        `/property/list?token=${token}&page=${this.currentPage}&limit=${this.limit}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.propertyList = value.data;
            this.propertyStastics = value.propertyStastics;
            this.totalPages = value.totalPages;
          }
          // this.rootBlocker.stop();
        },
      });
  }
  addNewProperty() {
    this.router.navigate(['/profile', 'new-property'], {
      queryParams: { new: 0 },
    });
  }

  previous(): void {
    if (this.currentPage > 1) {
      --this.currentPage;
      this.getPropertyList();
    }
  }
  next(): void {
    ++this.currentPage;
    this.getPropertyList();
  }
}
