import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { removeDialCode } from 'app/new-property/removeCodePhone';
import { userData } from 'app/services/auth/authentication.service';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { defineLocale, listLocales } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { arLocale, enGbLocale } from 'ngx-bootstrap/locale';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements AfterViewInit, OnInit {
  nationsSelectMenu: any[];
  otherTravelersForm!: FormGroup;
  nameForm: boolean = false;
  // nameInfo:boolean=true;
  emailFormBool: boolean = false;
  phoneFormBool: boolean = false;
  dateFormBool: boolean = false;
  nationalityFormBool: boolean = false;
  genderFormBool: boolean = false;
  idFormBool: boolean = false;
  addressBool: boolean = false;
  otherTravelers: boolean = false;
  codeFormBool: boolean = false;
  isEditing: boolean = false;
  addressForm = new FormGroup({
    address: new FormControl('sas', [Validators.required]),
  });
  NameForm = new FormGroup({
    firstName: new FormControl(' ', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
  });
  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  phoneForm = new FormGroup({
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(10),
      Validators.pattern(/^(0[0-9]{9}|[1-9][0-9]{8}|5[0-9]{9})$/), 
    ]),
  });
  codeForm = new FormGroup({
    validationCodePhoneOne: new FormControl('', [
      Validators.required,
      Validators.max(1),
    ]),
    validationCodePhoneTwo: new FormControl('', [
      Validators.required,
      Validators.max(1),
    ]),
    validationCodePhoneThree: new FormControl('', [
      Validators.required,
      Validators.max(1),
    ]),
    validationCodePhoneFour: new FormControl('', [
      Validators.required,
      Validators.max(1),
    ]),
  });
  dateForm = new FormGroup({
    date: new FormControl('', [Validators.required]),
  });
  nationalityForm = new FormGroup({
    nationality: new FormControl('', [Validators.required]),
  });
  genderForm = new FormGroup({
    gender: new FormControl('', [Validators.required]),
  });
  idForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
  });
  imageForm = new FormGroup({
    image: new FormControl('', [Validators.required]),
  });
  addPassenger() {
    this.passenger.push(
      new FormGroup({
        name: new FormControl(''),
        address: new FormControl(''),
        number: new FormControl(''),
        email: new FormControl(''),
      })
    );
  }
  remainingTime: number = 120; // 2 minutes in seconds
  timer: any;
  @ViewChild('dateInput') dateInput!: ElementRef;

  @BlockUI('fully-name') fullyNameBlocker!: NgBlockUI;
  @BlockUI('email-blocker') emilBlocker!: NgBlockUI;
  @BlockUI('phone-blocker') phoneBlocker!: NgBlockUI;
  @BlockUI('date-blocker') dateBlocker!: NgBlockUI;
  @BlockUI('nationality-blocker') nationalityBlocker!: NgBlockUI;
  @BlockUI('gender-blocker') genderBlocker!: NgBlockUI;
  @BlockUI('address-blocker') addressBlocker!: NgBlockUI;
  @BlockUI('passport-blocker') passportBlocker!: NgBlockUI;
  token: string = '';
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  bsConfig!: Partial<BsDatepickerConfig>;
  locales = listLocales();
  isEdit: boolean = false;
  isFlipped = false;
  currentLang: string;
  image: any;
  imageUrl: any = '';
  months: string[] = [];
  days: string[] = [];
  choosenDate: Date[] | null = null;

  constructor(
    private translate: TranslateService,
    public datacontext: DataContextService,
    private localeService: BsLocaleService
  ) {
    this.customRangeLocale();
    this.bsConfig = {
      dateInputFormat: 'D-MMMM-YYYY',
      isAnimated: false,
      rangeInputFormat: 'D-MMMM-YYYY',
      rangeSeparator: '',
      showWeekNumbers: false,
    };

    this.translate.onLangChange.subscribe(() => {
      this.customRangeLocale();
    });
  }
  imageBool: boolean = false;
  ngOnInit() {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.startTimer();
    this.otherTravelersForm = new FormGroup({
      passenger: new FormArray([
        new FormGroup({
          otherTravelers: new FormControl('salwa', [Validators.required]),
        }),
      ]),
    });

    this.currentLang = this.translate.currentLang;

    // Optionally, subscribe to changes if language switching is allowed during runtime
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });

    this.restoreFormData();
    this.token = this.datacontext.getRouteParams('token')!;
    this.loadEnglishTranslations();

    this.translate.onLangChange.subscribe(() => {
      this.customRangeLocale();
    });
    const token = this.datacontext.getRouteParams('token');

    this.datacontext
      .getData(
        `/auth/user/nationalities/${
          this.translate.currentLang || 'ar'
        }?token=${token}`
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.nationsSelectMenu = value.data;
        }
      });
  }
  showImageForm() {
    this.imageBool = !this.imageBool;
  }
  loadEnglishTranslations() {
    this.translate.get(['months', 'days']).subscribe((translations) => {
      if (translations['months'] && translations['days']) {
        // Assuming 'months' and 'days' are arrays of English names in the translation JSON
        this.months = translations['months'];
        this.days = translations['days'];
        this.customRangeLocale(); // Call after translations are loaded
      } else {
        console.error('Translations for months or days are not defined.');
      }
    });
  }

  customRangeLocale() {
    const currentLang = this.translate.currentLang; // Get the current language

    let localeData = currentLang === 'ar' ? { ...arLocale } : { ...enGbLocale };

    // Set months and days based on the current language
    localeData.months = this.months;
    localeData.monthsShort = this.months;
    localeData.weekdays = this.days;
    localeData.weekdaysMin = this.days;
    localeData.weekdaysShort = this.days;

    defineLocale(currentLang, localeData);
    this.localeService.use(currentLang);
  }

  // HandelCloseDialog() {
  //   this.dialogRef.close()
  // }

  // HandleConfirm() {
  //   if (this.choosenDate) {
  //     this.dialogRef.close(this.choosenDate)
  //   } else {
  //     this.translate.get('error.select_date').subscribe(message => {
  //       this.datacontext.showError(
  //         this.translate.instant('error.title'),
  //         message
  //       );
  //     });
  //   }
  // }

  onDateChange(value: any) {
    this.choosenDate = value;
  }

  private restoreFormData() {
    this.NameForm.get('firstName')?.setValue(
      !this.datacontext.userData?.firstName
        ? ''
        : this.datacontext.userData.firstName
    );
    this.NameForm.get('lastName')?.setValue(
      !this.datacontext.userData?.lastName
        ? ''
        : this.datacontext.userData.lastName
    );
    this.emailForm
      .get('email')
      ?.setValue(
        !this.datacontext.userData?.email ? '' : this.datacontext.userData.email
      );
    this.phoneForm
      .get('phoneNumber')
      ?.setValue(
        !this.datacontext.userData?.mobile
          ? ''
          : removeDialCode(this.datacontext.userData.mobile)
      );
    this.dateForm
      .get('date')
      ?.setValue(
        !this.datacontext.userData?.birthdate
          ? ''
          : this.datacontext.userData.birthdate
      );
    this.nationalityForm
      .get('nationality')
      ?.setValue(
        !this.datacontext.userData?.nationality
          ? ''
          : this.datacontext.userData.nationality
      );
    this.genderForm
      .get('gender')
      ?.setValue(
        !this.datacontext.userData?.gender
          ? ''
          : this.datacontext.userData.gender
      );
    this.idForm
      .get('id')
      ?.setValue(
        !this.datacontext.userData?.nationalIdStatus
          ? ''
          : this.datacontext.userData.nationalIdStatus
      );

    this.addressForm
      .get('address')
      ?.setValue(
        !this.datacontext.userData?.address
          ? ''
          : this.datacontext.userData.address
      );

    this.dateForm
      .get('date')
      ?.setValue(
        !this.datacontext.userData?.birthdate
          ? ''
          : (new Date(this.datacontext.userData.birthdate) as any)
      );
  }

  handelResetName() {
    if (this.datacontext.userData?.firstName) {
      this.nameForm = false;
      this.isEditing = false;
      this.NameForm.controls.firstName.setValue(
        this.datacontext.userData.firstName
      );
    } else {
      this.nameForm = false;
      this.isEditing = false;
      this.NameForm.controls.firstName.setValue('');
    }

    if (this.datacontext.userData?.lastName) {
      this.nameForm = false;
      this.isEditing = false;
      this.NameForm.controls.lastName.setValue(
        this.datacontext.userData.lastName
      );
    } else {
      this.nameForm = false;
      this.isEditing = false;
      this.NameForm.controls.lastName.setValue('');
    }
  }

  handelResetEmail() {
    if (this.datacontext.userData?.email) {
      this.emailFormBool = false;
      this.isEditing = false;
      this.emailForm.controls.email.setValue(this.datacontext.userData.email);
    } else {
      this.emailFormBool = false;
      this.isEditing = false;
      this.emailForm.controls.email.setValue('');
    }
  }
  handelResetPhone() {
    if (this.datacontext.userData?.mobile) {
      this.phoneFormBool = false;
      this.codeFormBool = false;
      this.remainingTime = 120;
      this.isEditing = false;
      this.phoneForm.controls.phoneNumber.setValue(
        removeDialCode(this.datacontext.userData.mobile)
      );
    } else {
      this.phoneFormBool = false;
      this.codeFormBool = false;
      this.remainingTime = 120;
      this.isEditing = false;
      this.phoneForm.controls.phoneNumber.setValue('');
    }
  }

  handelResetBirthDate() {
    if (this.datacontext.userData?.birthdate) {
      this.dateFormBool = false;
      this.isEditing = false;
      this.dateForm.controls.date.setValue(
        new Date(this.datacontext.userData.birthdate) as any
      );
    } else {
      this.dateFormBool = false;
      this.isEditing = false;
      this.dateForm.controls.date.setValue('');
    }
  }

  handelResetNationality() {
    if (this.datacontext.userData?.nationalId) {
      this.nationalityFormBool = false;
      this.isEditing = false;
      this.nationalityForm.controls.nationality.setValue(
        this.datacontext.userData.nationality
      );
    } else {
      this.nationalityFormBool = false;
      this.isEditing = false;
      this.nationalityForm.controls.nationality.setValue('');
    }
  }

  handelResetGender() {
    if (this.datacontext.userData?.gender) {
      this.genderFormBool = false;
      this.isEditing = false;
      this.genderForm.controls.gender.setValue(
        this.datacontext.userData.gender
      );
    } else {
      this.genderFormBool = false;
      this.isEditing = false;
      this.genderForm.controls.gender.setValue('');
    }
  }

  handelResetAddress() {
    if (this.datacontext.userData?.address) {
      this.addressBool = false;
      this.isEditing = false;
      this.addressForm.controls.address.setValue(
        this.datacontext.userData.address
      );
    } else {
      this.addressBool = false;
      this.isEditing = false;
      this.addressForm.controls.address.setValue('');
    }
  }
  handleResetImage() {
    if (this.datacontext.userData?.address) {
      this.imageBool = false;
      this.isEditing = false;
    } else {
      this.imageBool = false;
      this.isEditing = false;
    }
  }
  get passenger(): FormArray {
    return this.otherTravelersForm.get('passenger') as FormArray;
  }
  showOtherTravelers() {
    this.otherTravelers = !this.otherTravelers;
    this.isEditing = true;
  }
  saveOtherTravelers() {
    if (this.otherTravelersForm.valid) {
      this.otherTravelers = false;
      this.isEditing = false;
    }
  }
  showAdressForm() {
    this.addressBool = !this.addressBool;
    this.isEditing = true;
  }
  onImageChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.imageUrl = file?.name;
    this.image = file;
  }

  saveImage() {
    if (this.imageForm.valid) {
      console.log(this.imageForm.value, 'hey');
      const formData = new FormData();
      formData.append('image', this.image as any);
      // this.addressBlocker.start();
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, formData)
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.imageBool = false;
              this.isEditing = false;
            }
            // this.addressBlocker.stop();
          },
        });
    }
  }
  saveAddress() {
    if (this.addressForm.valid) {
      // this.addressBlocker.start();
      this.datacontext
        .postData(
          `/auth/user/update?token=${this.token}`,
          this.addressForm.value
        )
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.addressBool = false;
              this.isEditing = false;
            }
            // this.addressBlocker.stop();
          },
        });
    }
  }
  showIdForm() {
    this.idFormBool = !this.idFormBool;
    this.isEditing = true;
  }
  saveId() {
    if (this.idForm.valid) {
      // this.passportBlocker.start()
      let formBody = new FormData();
      formBody.append('nationalId', this.selectedFile);
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, formBody)
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.idFormBool = false;
              this.isEditing = false;
            }
            // this.passportBlocker.stop()
          },
        });
    }
  }

  selectedFile!: Blob;
  onPassportSelected(e: Event) {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      this.selectedFile = file;
    }
  }
  showGenderForm() {
    this.genderFormBool = !this.genderFormBool;
    this.isEditing = true;
  }
  saveGender() {
    if (this.genderForm.valid) {
      // this.genderBlocker.start();
      this.datacontext
        .postData(
          `/auth/user/update?token=${this.token}`,
          this.genderForm.value
        )
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.genderFormBool = false;
              this.isEditing = false;
            }
            // this.genderBlocker.stop();
          },
        });
    }
  }
  showNationalityForm() {
    this.nationalityFormBool = !this.nationalityFormBool;
    this.isEditing = true;
  }
  saveNationality() {
    if (this.nationalityForm.valid) {
      // this.nationalityBlocker.start();
      this.datacontext
        .postData(
          `/auth/user/update?token=${this.token}`,
          this.nationalityForm.value
        )
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.nationalityFormBool = false;
              this.isEditing = false;
            }
            // this.nationalityBlocker.stop();
          },
        });
    }
  }
  saveDate() {
    if (this.dateForm.valid) {
      // this.dateBlocker.start();
      const dataBody = {
        birthdate: new Date(this.dateForm.value.date!),
      };
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, dataBody)
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.dateFormBool = false;
              this.isEditing = false;
            }
            // this.dateBlocker.stop();
          },
        });
    }
  }
  showDateForm() {
    this.dateFormBool = !this.dateFormBool;
    this.isEditing = true;
  }
  saveName() {
    if (this.NameForm.valid) {
      // this.fullyNameBlocker.start();
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, this.NameForm.value)
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.nameForm = false;
              this.isEditing = false;
            }
            // this.fullyNameBlocker.stop();
          },
        });
    } else {
      this.datacontext.showError(
        this.translate.instant('errorr'),
        this.translate.instant('incorrect_data')
      );
    }
  }
  showNameForm() {
    this.nameForm = !this.nameForm;
    this.isEditing = true;
  }
  saveEmail() {
    if (this.emailForm.valid) {
      // this.emilBlocker.start();
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, this.emailForm.value)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              if (err.error.message === 'Email Already Exists') {
                this.datacontext.showError(
                  '',
                  this.translate.instant('email_already_registered')
                );
              }
            });
          })
        )
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated')
              );
              this.emailFormBool = false;
              this.isEditing = false;
              this.datacontext.userData = value.data;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
            }

            // this.emilBlocker.stop();
          },
        });
    } else {
      this.datacontext.showError(
        this.translate.instant('errorr'),
        this.translate.instant('incorrect_data')
      );
    }
  }
  showEmailForm() {
    this.emailFormBool = !this.emailFormBool;
    this.isEditing = true;
  }
  showPhoneForm() {
    this.phoneFormBool = !this.phoneFormBool;
    this.isEditing = true;
  }
  savePhone() {
    const code4 = this.codeForm.value.validationCodePhoneFour;
    const code3 = this.codeForm.value.validationCodePhoneThree;
    const code2 = this.codeForm.value.validationCodePhoneTwo;
    const code1 = this.codeForm.value.validationCodePhoneOne;

    if (this.phoneForm.valid && code4 && code3 && code2 && code1) {
      // this.phoneBlocker.start();
      const bodyData = {
        otp: code1 + code2 + code3 + code4,
        mobile:
          (this.phoneForm.value.phoneNumber as any).dialCode +
          (this.phoneForm.value.phoneNumber as any).number.replaceAll(' ', ''),
      };
      this.datacontext
        .postData(`/auth/user/update?token=${this.token}`, bodyData)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              if (err.error.message === 'Mobile Already Exists') {
                this.datacontext.showError(
                  '',
                  this.translate.instant('number_already_registered')
                );
              }
            });
          })
        )
        .subscribe({
          next: (value: any) => {
            if (value.success) {
              this.datacontext.showSuccess(
                '',
                this.translate.instant('data_updated_successfully')
              );
              this.datacontext.userData = value.data as userData;
              this.datacontext.setLocalStroage(
                'userInfo',
                JSON.stringify(value.data)
              );
              this.phoneFormBool = false;
              this.codeFormBool = false;
              this.remainingTime = 120;
              this.isEditing = false;
            }
          },
        });
    } else {
      this.datacontext.showError(
        this.translate.instant('number_already_registered'),
        ''
      );
    }
  }

  @ViewChild('phoneNumber') phoneNumberValue: any;
  otpId: string = '';
  onSendOTP() {
    // this.phoneBlocker.start()
    const dataBody = {
      mobile:
        (this.phoneForm.value.phoneNumber as any).dialCode +
        this.phoneNumberValue.phoneNumber,
    };
    this.datacontext
      .postData(`/auth/user/update?token=${this.token}`, dataBody)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.phoneBlocker.stop()
            if (err.error.message === 'Mobile Already Exists') {
              return this.datacontext.showError(
                '',
                this.translate.instant('number_already_registered')
              );
            }
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.datacontext.showSuccess(
              '',
              this.translate.instant('verification_code_sent_to_phone')
            );
            this.codeFormBool = true;
            this.otpId = value.id;
          }
          // this.phoneBlocker.stop()
        },
      });
  }
  saveCode() {
    if (this.codeForm.valid) {
      this.isEditing = false;
      this.phoneFormBool = false;
    }
  }
  validateInput(event: any) {
    const inputValue = event.target.value;
    const sanitizedInput = inputValue.replace(/[^0-9]/g, '');
    event.target.value = sanitizedInput;
  }

  validateOneInteger(event: any) {
    const inputValue = event.target.value;

    const sanitizedInput = inputValue.replace(/\D/g, '');

    const firstDigit = sanitizedInput.charAt(0);
    event.target.value = firstDigit;
  }
  onCancel() {
    this.isEditing = false;
  }

  startTimer() {
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
  }

  updateTimer() {
    if (this.remainingTime > 0) {
      this.remainingTime--;
    } else {
      clearInterval(this.timer);
    }
  }
  formatTime(seconds: number): string {
    const minutes: string = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds: string = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  }

  moveToNextField(event: Event, nextField: HTMLInputElement): void {
    event.preventDefault();

    nextField.focus();
  }
  moveToNextFieldAuto(nextField: HTMLInputElement): void {
    nextField.focus();
  }
  updateNationalityOption() {
    const selectedOptionValue = this.nationalityForm.get('nationality')?.value;
  }

  ngAfterViewInit() {
    if (this.dateFormBool) {
      this.dateInput.nativeElement.value = this.dateForm.controls.date.value;
    }
  }

  nationalitiesInArabic() {
    return this.nationsSelectMenu;
  }
}
