<div class="row " *ngIf="ratingComments">
    <div class="col-xs-6 col-sm-12 col-md-4 card" *ngFor="let comment of ratingComments.RatingsComments">
        <div class="d-flex align-items-center justify-content-end">
            <p class="name">{{ comment.name }}</p>
            <p class="img"><img [src]="reviews.image" /></p>
        </div>
        <div class="text">
            <p>{{comment.comment}}</p>
        </div>
    </div>
</div>
