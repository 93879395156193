import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SigninComponent } from 'app/signin/signin.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { AuthenticationService } from 'app/services/auth/authentication.service';
import { SignoutComponent } from 'app/signout/signout.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @ViewChild("subMenu") subMenu!: ElementRef;
  @ViewChild("subMenuTwo") subMenuTwo!: ElementRef;
  userName: string = 'يزيد العمري'
  profileList: boolean = false;
  languageList: boolean = false;
  languge: string = 'EN';
  loginFormPhone: FormGroup;
  openProfileList() {
    this.profileList = !this.profileList;
  }
  openLanguageList() {
    this.languageList = !this.languageList;
  }
  isLoggedin: boolean = false;
  isHomeRoute: boolean = false;
  isSearchRoute: boolean = false;
  isProfileRoute:boolean=false;
  isSignInRoute:boolean = false;
  isNotificationVisible: boolean = false;
  token: string = '';
  dropdownOpen: boolean = false;
  currentLang = 'ar';
  isDropdownOpen = false;
  dropdown: boolean = false;
  isFlipped = false;
  constructor(private router: Router, public dialog: MatDialog,private translate: TranslateService,public datacontext:DataContextService,private authService:AuthenticationService, private renderer: Renderer2, private el: ElementRef) {
    this.HomeRoute();
    this.searchRoute();
    this.profileRoute();
    this.currentLang = localStorage.getItem('lang') || 'ar';

    this.translate.addLangs(['en', 'ar']);
    this.translate.setDefaultLang('ar');
    localStorage.getItem('lang') ? this.translate.use(localStorage.getItem('lang')!) : this.setDirection('ar');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.isDropdownOpen = false; // Close the dropdown on route change
    });
  }
  ngOnInit(): void {
    this.token = this.datacontext.getRouteParams('token')!
    this.getNotifications()
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  

  toggleDropdown(event: Event): void {
    event.stopPropagation(); // Prevents event bubbling
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  @HostListener('document:click', ['$event'])
  closeDropdown(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false; // Close dropdown if clicked outside
    }
  }

  switchLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.currentLang = lang;
    this.setDirection(lang);
    this.dropdownOpen = false;  // Close dropdown after language switch
    window.location.reload();

  }

    setDirection(lang: string) {
    const htmlTag = document.documentElement;
    if (lang === 'ar') {
      htmlTag.setAttribute('dir', 'rtl');
    } else {
      htmlTag.setAttribute('dir', 'ltr');
    }
  }
  toggleDropdownn(event: Event): void {
    event.stopPropagation();
    this.dropdownOpen = !this.dropdownOpen;
  }
  HomeRoute() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isHomeRoute = event.urlAfterRedirects === '/home';

    });
  }
  searchRoute() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(
      (event: NavigationEnd) => {
        this.isSearchRoute = event.urlAfterRedirects.startsWith('/search')
        this.isSignInRoute = event.urlAfterRedirects.startsWith('/signin')
      });

  }
  profileRoute() {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe(
      (event: NavigationEnd) => {
        this.isProfileRoute = event.urlAfterRedirects.includes('profile')
      });

  }
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: Event): void {
    const clickedInside = this.subMenu?.nativeElement?.contains(targetElement);
    const clickedInsidetwo = this.subMenuTwo?.nativeElement?.contains(targetElement);
    const clickedInsidehree = this.subMenuTwo?.nativeElement?.contains(targetElement);
    const clickedInsidefour = this.subMenuTwo?.nativeElement?.contains(targetElement);

    if (!clickedInside && this.profileList) {
      this.profileList = false;

    } if (!clickedInsidetwo && this.languageList) {
      this.languageList = false;

    }if (!clickedInsidehree && this.dropdownOpen) {
      this.dropdownOpen = false;

    }if (!clickedInsidefour && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SigninComponent, {
      width:'450px',
      disableClose:true
    });
  }


  addNewReal(){
    if(!this.datacontext.userData){
     this.datacontext.showError('',this.translate.instant('please_register'))
     this.dialog.open(SigninComponent, {
      width:'450px',
      disableClose:true
    });
    return;
    } 
    this.router.navigate(['/profile','new-property'],{queryParams:{new:0,id:0}})
  }

  @ViewChild('modelLogOut') modelLogOut:ElementRef<any>
  logout(){
    this.dialog.open(SignoutComponent,{
      disableClose:true
    })
  }
  toggleNotificationVisibility() {
    this.isNotificationVisible = this.isNotificationVisible;
  }
  private getNotifications(){
    // this.root.start();
    let notifications:any[] = []
    this.datacontext.getData(`/notification/list?token=${this.token}`).subscribe({
      next:(value:any) => {
        if(value.success){
         notifications = value.data
         if(notifications.length > 0&&notifications.some((notification:any)=>!notification.readAt)){
         this.isNotificationVisible = true
        }
        // this.root.stop()
      }}
    })

  }

}
