import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedStateService {
  // Initialize the `disabled` state with a default value of `false`
  private disabledSource = new BehaviorSubject<boolean>(false);
  currentDisabledState = this.disabledSource.asObservable();

  // Method to update the `disabled` state
  updateDisabledState(isDisabled: boolean) {
    this.disabledSource.next(isDisabled);
  }
}
