import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdsDTO } from 'app/app.component';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css'],
})
export class PopUpComponent implements OnInit {
  adsDetails: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AdsDTO,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.adsDetails = this.data;
  }
  onHandelReservation() {
    switch (this.adsDetails.type) {
      case 'category':
        localStorage.setItem(
          'typeId',
          JSON.stringify(this.adsDetails.details.id)
        );
        const routeParam = this.router.url.includes('/search');
        if (routeParam) {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/search']);
            });
        } else {
          this.router.navigate(['/search']);
        }
        break;
      case 'property':
        this.router.navigate(['/details', this.adsDetails.details.id]);
        break;
      default:
    }
    this.dialog.closeAll();
  }
}
