import { Component, Input, OnInit } from '@angular/core';
import { CardInterface } from '../../app/card/card-interface';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { catchError, retry, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SearchDTO } from 'app/search-details/search-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-might-like',
  templateUrl: './might-like.component.html',
  styleUrls: ['./might-like.component.css'],
})
export class MightLikeComponent implements OnInit {
  title: string = 'قد يعجبك';
  mighttitle: string = 'تصفية';
  isFlipped=false

  constructor(private datacontext: DataContextService,private translate: TranslateService) {}

  ngOnInit(): void {
    this.getSearchRandom()
    
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }

  searchResult: SearchDTO[];
  @BlockUI('root') rootBlocker!: NgBlockUI;
  private getSearchRandom() {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token')
    let url;
    if(token){
      url =`/search/you-may-like?token=${token}`
    }else{
      url = `/search/you-may-like`
    }
    this.datacontext
      .getData(url)
      .pipe(
        retry(2)
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.searchResult = value.data;
          this.searchResult.forEach(item=>{
            if(item.isFavorite){
              this.datacontext.toggleFavorite(item.id)
            }
          })
        }
        // this.rootBlocker.stop();
      });
  }
}
