import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.css']
})
export class ChatItemComponent {
   @Input() index!: number;
  @Input() oneProp:any;
  
  @Output() onSelectEvent = new EventEmitter();
  constructor(private router:Router,private translate: TranslateService,private cdr: ChangeDetectorRef){}
  isFlipped=false
  activeButtonIndex:number| null = null;

  ngOnInit(): void {

    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  //OnCLick On Property [To Show Chat]
  getChat(selected:any){
    this.onSelectEvent.emit(selected);
    // this.router.navigate(['/profile/chats'],{queryParams:{reservationData:this.oneProp}})

    // const token = this.serv.getRouteParams('token');
    // this.serv.getChatList(`/chat/list?token=${token}`).subscribe({
    //   next:(data)=>{
    //     console.log(data)
    //   },
    //   error:(err:HttpErrorResponse)=>{
    //     this.serv.showError('',err.error.message)
    //   },
    //   complete:()=>{}
    // })
  }

   // Check if the current button is active
  isActive(index: number): boolean {
    console.log(this.activeButtonIndex === index)
    return this.activeButtonIndex === index;
  }

    // Set the clicked button as active
    setActive(index: number): void {
      this.activeButtonIndex = index;
    }

  

}
