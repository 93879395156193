import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { ChatComponent } from './chat/chat.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { NewPropertyComponent } from './new-property/new-property.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentHistoryListComponent } from './payment-history-list/payment-history-list.component';
import { PaymentsListManageComponent } from './payments-list-manage/payments-list-manage.component';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { RatingManageComponent } from './rating-manage/rating-manage.component';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { ReservationManagementComponent } from './reservation-management/reservation-management.component';
import { ReservationsStepperComponent } from './reservations-stepper/reservations-stepper.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { RoomsManagmentComponent } from './rooms-managment/rooms-managment.component';
import { SearchDetails } from './search-details/search-details.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { authGuardGuard } from './services/auth/auth-guard.guard';
import { SigninComponent } from './signin/signin.component';
import { TechnicalSupportComponent } from './technical-support/technical-support.component';
import { TosComponent } from './tos/tos.component';
import { UsagePolicyComponent } from './usage-policy/usage-policy.component';
import { WhishlistComponent } from './whishlist/whishlist.component';
import { FilterComponent } from './search-filter/search-filter.component';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  { path: '', pathMatch: 'prefix', redirectTo: 'home' },
  { path: 'home', component: HomeComponent }, //revised
  { path: 'signin', component: SigninComponent }, //revised
  { path: 'search-filter', component: FilterComponent },
  { path: 'search', component: SearchResultsComponent },
  { path: 'details/:id', component: SearchDetails }, //revised
  { path: 'datepicker', component: DatepickerComponent },

  {
    path: 'profile', // Parent route
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'profile' },
      {
        path: 'notifications',
        component: NotificationsComponent,
      }, //revised
      {
        path: 'profile',
        component: ProfileComponent,
      }, //revised
      {
        path: 'all-orders',
        component: AllOrdersComponent,
      }, //revised
      {
        path: 'chats',
        component: ChatComponent,
      }, //revised
      // { path: 'rooms-list', component: RoomsListComponent }, //revised
      { path: 'rooms-manage', component: RoomsManagmentComponent }, //revised
      {
        path: 'reservation-management',
        component: ReservationManagementComponent,
      }, //revised
      { path: 'add-rating', component: RatingManageComponent },
      {
        path: 'reservation-details/:id',
        component: ReservationDetailsComponent,
      },
      { path: 'new-property', component: NewPropertyComponent }, //revised
      { path: 'wishlist', component: WhishlistComponent }, //revised
      { path: 'technical-support', component: TechnicalSupportComponent }, //revised
      { path: 'property-manage', component: ReservationsComponent },
      { path: 'payments-list', component: PaymentsListComponent },
      { path: 'payments-history', component: PaymentHistoryListComponent },
      { path: 'payments-list/add', component: PaymentsListManageComponent },
      { path: 'faq', component: FaqComponent }, //revised
      {
        path: 'orders',
        component: OrdersComponent,
      },
      { path: '**', redirectTo: 'home' },
    ],
    canActivate: [authGuardGuard],
  },

  {
    path: 'checkout',
    component: ReservationsStepperComponent,
    canActivate: [authGuardGuard],
  }, //revised
  // { path: 'popup', component: PopupComponent },//revised -> mobile not done

  // { path: 'reservation-order', component: ReservationOrderComponent },//revised
  // { path: 'image-viewer', component: ImagesViewerComponent },//dont know if complete
  //revised
  // { path: 'orders', component: OrdersComponent },//revised
  // { path: 'new-room', component: CreateRoomComponent },//revised
  // { path: 'date', component: DatepickerComponent }, //revised
  // { path: 'other', component: ReservationDetailsComponent },//revised
  // { path: 'pics', component: PictureViewerComponent },
  // { path: 'img', component: ImagesViewerComponent }

  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'tos', component: TosComponent },
  { path: 'usage-policy', component: UsagePolicyComponent },
  { path: 'cancellation-policy', component: CancellationPolicyComponent },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
