import { Component } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
downloadTitle:string='حمل تطبيق أروڤيا لأبل وأندرويد'
downloadSubTitle:string='التطبيق يدعم الإصدار 14+'
}
