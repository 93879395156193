import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  chatList: any[] = [];
  constructor(private serv:DataContextService,  private translate:TranslateService){}
  @ViewChild('messageInput') messageInput!: ElementRef;
  FilteredReservationList:any;
  userId = 0;
  // selectedHostID = 0;
  Chats:any='null';
  isdispInput=false;
selectedChatId:number=0;
chatType:'guest'|'host'|undefined;
  //Load All Properties[Reservation in progress||Pendding] At Start
  isFlipped=false;
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;


  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
      // Example data; replace with your actual data fetching logic
      this.chatList = [
        { id: 1, message: 'Hello' },
        { id: 2, message: 'How are you?' },
        // Add more chat items as needed
      ];
    }
    // this.userId = JSON.parse(localStorage.getItem("userInfo")!).id
    // const token = this.serv.getRouteParams('token')
    // this.serv.getData(`/chat/list?token=${token}`).subscribe({
    //   next:(props:any)=>{
    //     this.FilteredReservationList = props.data.map((chat:{id:number,from:number,to:number,status:string,createdAt:string,updatedAt:string,user_from:{
    //       image:string,
    //       firstName:string,
    //       lastName:string,
    //     },
    //     user_to:{
    //       logo:string,
    //       nameEn:string,
    //       nameAr:string
    //     }
    //   })=>{
    //       return {
    //         Property:{
    //           id:chat.id,
    //           PropertyId:chat.id,
    //           nameAr:chat.user_to.nameAr,
    //           mainImage:chat.user_to.logo,
    //         },
    //         to:chat.to,
    //         from:chat.from,
    //         PropertyId:chat.id,

    //         createdAt:chat.createdAt,

    //       }
    //     })
    //     console.log(this.FilteredReservationList);
    //   },
    //   error:(err:HttpErrorResponse)=>{
    //     this.serv.showError('',err.error.message)
    //   },
    //   complete:()=>{console.log()}
    // })

  fetchChats(){
    console.log('heu')
    this.userId = JSON.parse(localStorage.getItem("userInfo")!).id
    const token = this.serv.getRouteParams('token')
    this.serv.getData(`/chat/${this.chatType}?token=${token}`).subscribe({
      next:(props:any)=>{
        const propsData:{
          id:number,
          createdAt:string,
          Property:{
            mainImage: string;
            id: number;
            nameAr: string;
            nameEn: string;
            User: {
              firstName: string | null;
              lastName: string | null;
            };
          };
          Chats:[]
        }[]= props.data;
        console.log(props)
        this.FilteredReservationList = propsData.map((chat)=>{
          return {
            Property:{
              id:chat.id,
              PropertyId:chat.id,
              nameAr:chat.Property.nameAr,
              mainImage:chat.Property.mainImage,
            },
            to:chat.id,
            from:chat.id,
            PropertyId:chat.id,
            Chats:chat.Chats,
            createdAt:chat.createdAt,
            User:{
              firstName:chat.Property.User.firstName,
              lastName:chat.Property.User.lastName
            }

          }
        })
        console.log(this.FilteredReservationList);
      },
      error:(err:HttpErrorResponse)=>{
        this.serv.showError('',err.error.message)
      },
      complete:()=>{console.log()}
    })
  }
  
  setChatType(type:'guest'|'host'){
    // console.log('first')
    this.selectedChatId = 0;
    this.Chats='null';
    this.isdispInput=false
    this.chatType = type;
    this.fetchChats()
  }
  triggerInputKeyup() {
    const event = new KeyboardEvent('keyup', {
      bubbles: true,
      cancelable: true,
      key: 'Enter'
    });

    // Manually trigger keyup on the message input
    this.messageInput.nativeElement.dispatchEvent(event);
  }
  //On Enter At Input Message ==> Send Message To Property Host
  sendMessage(e:KeyboardEvent){
    if(e.key == 'Enter'){
      var message = ((e.target) as HTMLInputElement).value;
      // console.log(message);
      this.MessageNow(message);
      ((e.target) as HTMLInputElement).value = "";
    }
  }


  MessageNow(message: string){
    const token = this.serv.getRouteParams('token')
    console.log(message)
console.log(this.FilteredReservationList,'asd')
    let data = new FormGroup({
      // to:new FormControl(this.FilteredReservationList[0].PropertyId),
      to:new FormControl(this.selectedChatId),
      type:new FormControl("text"),
      body:new FormControl(message),
      image:new FormControl(null),
    })
console.log( this.Chats,data)
if(this.Chats=='undefined'){
  this.Chats = [];
}
    this.Chats.push({...data.value,from:this.chatType});
    let chattt = document.getElementsByClassName("div-showMessages")[0] as HTMLDivElement;
    // chattt.scrollTop = chattt.scrollHeight;
    console.log(chattt.scrollTop);
    console.log(chattt.scrollHeight);
    setTimeout(() => {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Error scrolling to bottom', err);
      }
    }, 0);
    this.serv.postMessage(`/chat/message?token=${token}`,data.value).subscribe({
      next:(d)=>{
        console.log(d);
        chattt.animate({
          scrollTop: chattt.scrollHeight
      }, 500);
      },
      error:(err:HttpErrorResponse)=>{
        this.serv.showError('',err.error.message)
      },
      complete:()=>{}
    })
  }



  getSelectedID(selected:any){
    // this.selectedHostID = selected.PropertyId;
  this.selectedChatId = selected.to
  console.log(  this.selectedChatId )
    const token = this.serv.getRouteParams('token');
    console.log(this.FilteredReservationList)
    this.Chats = this.FilteredReservationList.find((d:any)=>d.to == selected.to)?.Chats;
    let chattt = document.getElementsByClassName("div-showMessages")[0] as HTMLDivElement;
    // setTimeout(()=>{chattt.scrollTop = chattt.scrollHeight},1000);
    chattt.scrollTo(0, chattt.scrollHeight);
    console.log(this.Chats)
    this.isdispInput = true;
    if(!this.Chats){
      this.Chats = 'undefined'
    }
    setTimeout(() => {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch (err) {
        console.error('Error scrolling to bottom', err);
      }
    }, 0);
        // this.serv.getChatList(`/chat/${this.chatType}?token=${token}`).subscribe({
        //   next:(dataArr:any)=>{
        //     // console.log(selected,dataArr.data);
          
        //   },
        //   error:(err:HttpErrorResponse)=>{
        //     this.serv.showError('',err.error.message)
        //   },
        //   complete:()=>{}
        // })
  }

}
