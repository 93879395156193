<div style="text-align: start;" [dir]="translation.currentLang === 'en' ? 'ltr' : 'rtl'" class="d-flex align-items-center flex-column justify-content-between">
  <div class="p-5">
    <h2 class="m-0 text-primary" [innerHTML]="translation.currentLang === 'en' ? cancelData.nameEn : cancelData.nameAr"></h2>
    <p class="mb-0 mt-2" [innerHTML]="translation.currentLang === 'en' ? cancelData.discEn : cancelData.discAr"></p>

  </div>
  <div class="d-flex align-items-center justify-content-center mb-2">
    <button class="button" (click)="closeDialog()">إغلاق</button>
  </div>
</div>
