import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { OrderDTO } from 'app/all-orders/order-dto';

import { ArabicDatePipe } from 'app/arabic-date.pipe';
import { DataContextService } from 'app/services/global-context/data-context.service';
@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.css'],
  providers: [ArabicDatePipe],
})
export class ReservationDetailsComponent implements OnInit, OnDestroy {
  options: google.maps.MapOptions = {
    mapId: 'Arrovia',
    center: { lat: 23.885942, lng: 45.079162 },
    zoom: 8,
  };
  markerPosition: google.maps.LatLngLiteral = {
    lat: 23.885942,
    lng: 45.079162,
  };
  ReservationDetails = {
    roomType: 'غرفة دبل',
    roomDetails: ['سرير كبير', 'سرير كبير', 'كنبة سرير'],
    roomDetailsImg: [''],
    roomDetail: [
      {
        quantity: 'X1',
        roomDetailss: 'سرير كبير',
        img: '../../assets/icons/Single Bed.svg',
      },
      {
        quantity: 'X2',
        roomDetailss: 'سرير كبير',
        img: '../../assets/icons/sofa-bed-s.svg',
      },
      {
        quantity: 'X1',
        roomDetailss: 'سرير كبير',
        img: '../../assets/icons/double-bed-icon.svg',
      },
    ],
    status: 'مكتمل',
    shaletName: 'شاليهات هايسيندا',
    shaletStartDate: '2024-02-11T11:09:26.593Z',
    shaletEndDate: '2024-02-11T11:09:26.593Z',
    guestNameTitle: 'اسم الضيف',
    guestName: 'يزيد بن عبدالله العمري',
    phoneNumberTitle: 'رقم الجوال',
    phoneNumber: '+966506060381',
    destinationTitle: 'الوجهة',
    destination: 'هايسندا باي، مرسى مطروح مصر',
    departureTitleOut: 'تسجيل المغادرة',
    departureTitleIn: 'تسجيل الوصول',
    departureDate: 'من 24 يناير 2023',
    arrivalDate: 'من 24 يناير 2023',
    geustsTitle: 'الضيوف',
    guests: '2 كبار، 1 طفل',
    mapTitle: 'map_location',
    priceDetailsTitle: 'تفاصيل السعر',
    vacationDetailsNights: ' 4 ليلة',
    vacationDetailsPrice: '500 ر.س ',
    addedTaxTitle: 'ضريبة القيمة المضافة',
    addedTaxPercent: '15%',
    discountTitle: 'الخصم',
    couponTitle: 'كوبون خصم',
    couponCode: 'FREECO',
    totalTitle: 'المبلغ الإجمالي',
    vacationDetailsTotal: '2000,00 ر.س',
    taxValue: '300،00 ر.س',
    discountValue: '400،00 ر.س',
    couponValue: '200,00 ر.س',
    totalValue: '1900،00 ر.س',
    paymentMethodLabel: 'طريقة الدفع',
    paymentMethod: 'أبل باي',
    confirmationLabel: 'تم تأكيد الحجز رقم',
    confirmation: '45340005',
    verificationLabel: 'سيصلك بريد إلكتروني للتأكيد على بريدكم المسجل',
    verification: 'yazid@gmail.com',
    onlinePayment1: 'Add to Apple Wallet',
    onlinePayment2: 'Add to Google Wallet',
    reservationDate: '2024-02-11T11:09:26.593Z',
    reservationDateLabel: 'تاريخ الحجز',
    choosedRoomLabel: 'الغرفة المختارة',
    mapImage: '../../assets/Screenshot -1.png',
    barcodeImage: '../../assets/32-facts-about-cairo-1688018994.png',

    shaletImage: '../../assets/32-facts-about-cairo-1688018994.png',

    shaletRoomImage: '../../assets/32-facts-about-cairo-1688018994.png',

    appleImage: '../../assets/apple-logo.svg',
    googleImage: '../../assets/apple-logo.svg',
  };

  reservationDetails: OrderDTO;
  status = '';
  ReservationID = 0;
  idFromMoyasar = '';

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private dataContext: DataContextService,
    private translate: TranslateService
  ) {
    this.status =
      this.route.getCurrentNavigation()?.extractedUrl.queryParams['status'];
    this.idFromMoyasar =
      this.route.getCurrentNavigation()?.extractedUrl.queryParams['id'];
    console.log(this.status);
    this.reservationDetails = JSON.parse(
      localStorage.getItem('reservationDetails')!
    );
    this.ReservationID = this.reservationDetails.PropertyId;
    console.log(this.reservationDetails);
  }
  isFlipped = false;
  kamalPayment: any;
  onCheckIn(order: any) {
    console.log(order);
    const token = localStorage.getItem('token');
    console.log(token);
    this.dataContext
      .postData(`/kamel/check-in/${order.reservation.id}?token=${token}`, {})
      .subscribe((data) => {
        console.log(data);
        this.reservationDetails.checkedInAt = new Date().toISOString();
      });
  }
  onCheckOut(order: any) {
    const token = localStorage.getItem('token');
    console.log(token);
    this.dataContext
      .postData(`/kamel/check-out/${order.reservation.id}?token=${token}`, {})
      .subscribe((data) => {
        console.log(data);
        this.reservationDetails.checkedOutAt = new Date().toISOString();
      });
  }
  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.reservationDetails = JSON.parse(
      localStorage.getItem('reservationDetails')!
    );
    console.log(this.reservationDetails);
    if (!this.reservationDetails) {
      this.route.navigate(['/profile', 'orders']);
    }
    if (this.status == 'paid') {
      const token = this.dataContext.getRouteParams('token');
      if (!this.idFromMoyasar) {
        this.idFromMoyasar = this.activeRoute.snapshot.paramMap.get('id') || '';
        // 'id' is the param name in the URL
      }

      console.log(this.idFromMoyasar);
      this.dataContext
        .getData(`/kamel/validate-payment/${this.idFromMoyasar}?token=${token}`)
        .subscribe({
          next: (d) => {
            console.log(d);
            this.kamalPayment = d;
            console.log(this.kamalPayment);
          },
          error: (er) => {
            console.log(er);
            this.kamalPayment = { reservation: this.reservationDetails };
          },
        });
      console.log(this.kamalPayment);

      // this.dataContext.postData(`/property/change-status?token=${token}`,{id:this.ReservationID, status:'active'},{Headers:{'Accept': 'application/json',"Content-Type":"application/json"}}).subscribe({
      //   next:(d)=>{
      //     this.ReservationDetails.status = 'active';
      //     console.log(d);
      //     // debugger;
      //   },
      //   error:(er)=>{
      //     console.log(er);
      //     // debugger;
      //   }
      // })
    }
    if (this.status == 'fail') {
      console.log('Failed Payment');
    } else if (this.status == undefined) {
      const token = this.dataContext.getRouteParams('token');

      this.idFromMoyasar = this.activeRoute.snapshot.paramMap.get('id') || '';
      // 'id' is the param name in the URL

      console.log(this.idFromMoyasar);
      this.dataContext
        .getData(`/kamel/${this.idFromMoyasar}?token=${token}`)
        .subscribe({
          next: (d: any) => {
            console.log(d);
            this.kamalPayment = { reservation: d.reservation[0] };
          },
          error: (er) => {
            console.log(er);
            this.kamalPayment = { reservation: this.reservationDetails };
          },
        });
    }
    this.options = {
      ...this.options,
      center: {
        lat: +this.reservationDetails.Property.mapLat,
        lng: +this.reservationDetails.Property.mapLon,
      },
    };
    this.markerPosition = {
      lat: +this.reservationDetails.Property.mapLat,
      lng: +this.reservationDetails.Property.mapLon,
    };
  }
  parseDate(dateString: string): Date {
    // Check if Safari-compatible date string is needed
    const parsedDate = new Date(dateString);

    // If `new Date(dateString)` fails, fallback to manually parsing the date
    if (isNaN(parsedDate.getTime())) {
      // Use regex to handle the date string and manually parse it if needed
      const dateParts = dateString.split(/[^0-9]/); // Split by non-numeric characters
      return new Date(
        Date.UTC(
          parseInt(dateParts[0], 10), // Year
          parseInt(dateParts[1], 10) - 1, // Month (0-indexed)
          parseInt(dateParts[2], 10), // Day
          parseInt(dateParts[3], 10), // Hour
          parseInt(dateParts[4], 10), // Minute
          parseInt(dateParts[5], 10) // Second
        )
      );
    }

    return parsedDate;
  }

  calcNights(startDate: any, endDate: any): number | null {
    if (startDate && endDate) {
      // Parse the start and end dates
      const parsedStartDate = this.parseDate(startDate);
      const parsedEndDate = this.parseDate(endDate);

      // Calculate the difference in milliseconds between the two dates
      const differenceInMs =
        parsedEndDate.getTime() - parsedStartDate.getTime();

      // Convert milliseconds to days (1 day = 86400000 ms)
      const nightCount = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

      return nightCount;
    }
    return null; // Return null if either date is missing
  }

  ngOnDestroy(): void {
    if (this.reservationDetails) {
      localStorage.removeItem('reservationDetails');
    }
  }
  onMapTitleClick() {
    const url = `https://www.google.com/maps?q=${this.markerPosition.lat},${this.markerPosition.lng}`;
    window.open(url, '_blank'); // Opens the URL in a new tab
  }
  onMapClick(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      const latitude = event.latLng.lat();
      const longitude = event.latLng.lng();
      const url = `https://www.google.com/maps?q=${this.markerPosition.lat},${this.markerPosition.lng}`;
      window.open(url, '_blank'); // Opens the URL in a new tab
    }
  }

  // Method to handle the Apple Wallet button click
  onAppleWalletClick() {
    console.log('Apple Wallet button clicked');
    // You can integrate Apple Wallet functionality here.
  }
  completePayment() {
    const token = this.dataContext.getRouteParams('token');
    const reservationId = this.reservationDetails.id;
    const price = this.reservationDetails.netPrice;
    console.log(`checkout?reserevationId=${reservationId}&price=${price}`);
    window.location.href = `checkout?reserevationId=${reservationId}&price=${price}`;
    // this.route.navigate([`checkout?reserevationId=${reservationId}&price=${price}`], {
    //   queryParams: { reservationId, price },
    // });
  }

  onAcceptOrder(order: any) {
    const token = this.dataContext.getRouteParams('token');
    this.dataContext
      .postData(`/kamel/accept/${order.reservation.id}?token=${token}`, {})
      .subscribe((data) => {
        const response = data as any;
        if (response.success) {
          this.dataContext.showSuccess('', 'تم قبول الطلب');
          window.location.reload(); // Reload the page
        }
      });
  }

  onRejectOrder(order: any) {
    const token = this.dataContext.getRouteParams('token');
    console.log(order);
    this.dataContext
      .postData(`/kamel/reject/${order.reservation.id}?token=${token}`, {})
      .subscribe((data) => {
        const response = data as any;
        if (response.success) {
          this.dataContext.showSuccess('', 'تم رفض الطلب');
          window.location.reload(); // Reload the page
        }
      });
  }
}
