
<!-- <router-outlet></router-outlet> -->
<!-- <app-popup></app-popup> -->
<div class="d-flex align-items-center justify-content-center" style="height: 100vh;" *ngIf="isMobileVersion" >Mobile Version is under development try our desktop version</div>
<ng-container *ngIf="!isMobileVersion">
  <div *blockUI="'root'" #desktop [dir]="isFlipped ? 'ltr' : 'rtl'">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-footer [websiteGeneralInfo]="websiteInfo"></app-footer>
  </div>
</ng-container>

<!-- <app-popup></app-popup> -->
<!-- <app-images-viewer></app-images-viewer> -->


