import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PropertyDTO } from 'app/new-property/property-dto';
import { ChangeDetectorRef } from '@angular/core';
import {
  ReservationDTO,
  ReservationRoomsDTO,
} from 'app/reservation-management/reservation-dto';
import { RoomsDTO } from 'app/rooms-managment/rooms-dto';
import { SearchDTO } from 'app/search-details/search-dto';
import { userData } from 'app/services/auth/authentication.service';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-final-review-reservation',
  templateUrl: './final-review-reservation.component.html',
  styleUrls: ['./final-review-reservation.component.css'],
})
export class FinalReviewReservationComponent implements OnInit, OnDestroy {
  @Input() hotelDetails: SearchDTO | null = null;
  property: PropertyDTO;
  rooms: RoomsDTO[];
  startDate: string;
  cancelReservationData: any;
  entDate: string;
  dateOfReservation: string;
  gustes: { children: number; adults: number; infants: number };
  userInfo: userData;
  reservationInfo: ReservationDTO; // only if he came from reservations details page
  reservationId: string;
  showCancelConfirmationModal = false;

  constructor(
    private router: Router,
    private datacontext: DataContextService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private dialog: MatDialog // Inject MatDialog
  ) {}

  options: google.maps.MapOptions = {
    mapId: 'Arrovia',
    center: { lat: 23.885942, lng: 45.079162 },
    zoom: 8,
    disableDoubleClickZoom: true,
    maxZoom: 8,
    disableDefaultUI: true,
    minZoom: 8,
  };
  markerPosition: google.maps.LatLngLiteral = {
    lat: 23.885942,
    lng: 45.079162,
  };
  isFlipped = false;
  openMapInNewTab(): void {
    const url = `https://www.google.com/maps?q=${this.options.center?.lat},${this.options.center?.lng}&z=${this.options.zoom}`;
    console.log(url);
    window.open(url, '_blank');
  }

  ngOnInit(): void {
    console.log(this.reservationInfo
    
    )
    const token = localStorage.getItem('token');
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    const details = JSON.parse(localStorage.getItem('reservationDetails')!);
    console.log(details);
    if (details) {
      this.property = details.Property;
      this.options.center!.lat = Number(this.property.mapLat);
      this.options.center!.lng = Number(this.property.mapLon);
      this.markerPosition = {
        lat: Number(this.property.mapLat),
        lng: Number(this.property.mapLon),
      };
      this.rooms = details.ReservationRooms.map(
        (resRoom: ReservationRoomsDTO) => resRoom.Room
      );
      this.dateOfReservation = this.dateFormate(new Date(details.createdAt));
      this.reservationInfo = details;
      // this.options = {
      //   ...this.options,
      //   center:{lat:details.Property.mapLat, lng:details.Property.mapLon}
      // }
      // this.markerPosition = {lat:details.Property.mapLat, lng:details.Property.mapLon}
    }
    this.userInfo = this.datacontext.userData!;
    console.log(this.reservationInfo);
    this.reservationId = this.activatedRoute.snapshot.paramMap.get('id')!;
    this.canCancelReservation();

  }

  getDateStartEnd(date: string) {
    return this.dateFormate(new Date(date));
  }

  completePayment() {
    const token = this.datacontext.getRouteParams('token');
    console.log(this.reservationInfo);
    const reservationId = this.reservationInfo.id;
    const price = this.reservationInfo.netPrice;
    console.log(`checkout?reserevationId=${reservationId}&price=${price}`);
    window.location.href = `checkout?reserevationId=${reservationId}&price=${price}&createdAt=${this.reservationInfo.payBefore}`;
    // this.route.navigate([`checkout?reserevationId=${reservationId}&price=${price}`], {
    //   queryParams: { reservationId, price },
    // });
  }

  dateFormate(date: Date): string {
    const months = [
      'يناير',
      'فبراير',
      'مارس',
      'أبريل',
      'مايو',
      'يونيو',
      'يوليو',
      'أغسطس',
      'سبتمبر',
      'أكتوبر',
      'نوفمبر',
      'ديسمبر',
    ];

    // const convertedDate = new Date(date);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  ngOnDestroy(): void {
    const details = JSON.parse(localStorage.getItem('reservationDetails')!);
    if (details) {
      localStorage.removeItem('reservationDetails');
    }
  }
  canCancelReservation() {
    const token = localStorage.getItem('token');
    const t = this.datacontext
      .postData(
        `/kamel/guest-cancel-check/${this.reservationInfo.id}?token=${token}`,
        {}
      )
      .subscribe(
        (data: any | null) => {
          console.log(data); // log response data
          this.cancelReservationData = data;
        },
        (error) => {
          console.error(error); // log error to console
        }
      );
  }
  cancelReservation() {
    console.log(this.reservationInfo.id);
    const token = localStorage.getItem('token');

    this.datacontext
      .postData(
        `/kamel/guest-cancel/${this.reservationInfo.id}?token=${token}`,
        {}
      )
      .subscribe(
        (data: any) => {
          // Refresh the page after successful cancellation
          console.log(data);
          this.changeModalStatus();
          this.canCancelReservation();
          // window.location.reload();
        },
        (error) => {
          console.log(error);
          // Error handling is now done in the interceptor
        }
      );
  }
  changeModalStatus() {
    this.showCancelConfirmationModal = !this.showCancelConfirmationModal;
  }
}
