<div class="contianer container" dir="auto">
    <div class="just flex margin align-items-center">
        <div class="flex">
            <div class="arrow-icon">
                <div class="purple-arrow">
                    <img src="assets/aroovia-icons/Icon feather-arrow-left.svg" alt=""  [ngClass]="{ 'flipped-arrow': isFlipped }">
                </div>
            </div>
            <div class="title">
              {{'featured_suggestions'| translate}} 
            </div>
        </div>

        <div class="flex filter-title-cont hovered-pointer">
          <!-- <div class="btn-group w-100 mt-2" dropdown #dropdown="bs-dropdown" [insideClick]="true" [autoClose]="true">
            <div dropdownToggle  class=" d-flex gap-1">
              <div class="filter-title">
                تصفية
              </div>

              <div class="filter-icon">
                  <div class="purple">
                      <img src="../../assets/aroovia-icons/Filter.svg" alt="" style="border-radius: 15px;">
                  </div>
              </div>
            </div>
            <ul
              *dropdownMenu
              id="dropdown-animated"
              class="dropdown-menu p-4 w-100 row"
              role="menu"
            >
              <li role="menuitem" class="my-3">
                  hello filter
              </li>
            </ul>
          </div> -->
        </div>


    </div>
    <div class="cards">
        <app-card [cardsSearch]="searchResult"></app-card>
    </div>
</div>

