import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
@Component({
  selector: 'app-usage-policy',
  templateUrl: './usage-policy.component.html',
  styleUrls: ['./usage-policy.component.css']
})
export class UsagePolicyComponent {
  html: SafeHtml = ""
  constructor(private DataContextService: DataContextService, private DomSanitizer: DomSanitizer ,private translate:TranslateService){
    this.DataContextService.websiteInfo.subscribe((d: WebsiteInfoDTO)=>{
      translate.currentLang==='en'? d.usagePolicyEn ? this.html = this.DomSanitizer.bypassSecurityTrustHtml(d.usagePolicyEn) : "" :
      d.usagePolicyAr ? this.html = this.DomSanitizer.bypassSecurityTrustHtml(d.usagePolicyAr) : ""
    })
  }
}
