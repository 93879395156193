<div class="cont" *ngIf="hotelDetails" id="roomOptions">
  <!-- responsive-table.component.html -->
  <div class="d-flex align-items-center justify-content-between">
    <!-- <h3 class="room-label w-100">{{'room_options'|translate}}</h3> -->
  </div>
  <!-- <p class="additional-options">خيارات إضافية</p> -->
  <div class="row">
    <!-- <div class="text-end mb-2  d-sm-block d-none" dir="ltr">
      <div class="w-25">
        <button class="action-button" (click)="handelReservation()">احجز</button>
      </div>
    </div> -->
    <!-- Table for Tablet and Desktop applicaiton -->
    <!-- <div class="col-sm-12 col-md-12 d-sm-block d-none">
      <div class="table-responsive" >
        <table >
          <thead class="spacing">
            <tr>
              <th></th>
              <th colspan="1" class="image-cell-label" class="title">{{'room_type'|translate}}</th>
              <th colspan="2" class="title"></th>
              <th colspan="1" class="title">{{'guests'|translate}}</th>
              <th colspan="1" class="title">{{'space'|translate}}</th>
              <th colspan="1" class="title">{{'room_count'|translate}}</th>
              <th colspan="1" class="title">{{'price'|translate}}</th>
              <th colspan="1">الاجمالي</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let room of hotelDetails?.Rooms">
              <td colspan="1" class="image-cell align-items-center" style="height:fit-content;">
                <div style="width: 60px;" class="mx-2">
                  <img [src]="room.images[0]" alt=""  style="width: 100%; height: 100%; object-fit: cover; border-radius: 5px;"/>
                </div>
                <div class="flexx">
                  <div class="center">{{room.name}}</div>
                  <div class="orient d-flex " style="height: fit-content;">
                    <img src="../../assets/icons/double-bed-icon.svg" alt="" />
                    <p class="list" style="width: max-content;">{{ room.bedCount }} {{'king_bed'|translate}}</p>
                  </div>
                </div>
              </td>
              <td colspan="2" class="room">

              </td>

              <td colspan="1" class="icon-cell">
                <div class="d-flex flex-column">
                  <div>
                    <img
                      src="../../assets/icons/parent-1.svg"
                      class="big-person-icon"
                    /><img
                      src="../../assets/icons/parent-1.svg"
                      alt=""
                      class="big-person-icon"
                    />+
                    <img
                    src="../../assets/icons/parent-1.svg"
                    class="small-person-icon"
                  /><img
                    src="../../assets/icons/parent-1.svg"
                    class="small-person-icon"
                  />
                  </div>
                </div>
              </td>
              <td colspan="1" class="space"> {{'meters'|translate}} {{ room.size }} </td>
              <td colspan="1">
                <div class="counter-container">
                  <div class="counter">
                    <mat-icon class="arrow hovered-pointer" (click)="incrementGuests(room)">
                      arrow_drop_up
                    </mat-icon>
                    <mat-icon class="arrow hovered-pointer" [ngClass]="{'pointer':room.roomCount === 0}" (click)="decrementGuests(room)">
                      arrow_drop_down
                    </mat-icon>
                  </div>
                  <input
                    [disabled]="true"
                    appOnlyNumbers
                    type="number"
                    min="1"
                    placeholder="1"
                    [(ngModel)]="room.roomCount"
                  />
                </div>

              </td>
              <td colspan="1" class="price">{{ room.price | number:'1.2-2' }} {{'ASR'|translate}}</td>
              <td colspan="1" class="price">
                <p>{{totalPrice}}</p>
              </td> 
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div
      style="
        display: grid;
        gap: 40px;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 20px;
      "
    >
      <div class="property-detail">
        <i class="fas fa-door-open"></i>
        <p>{{ "rooms" | translate }}: {{ hotelDetails?.rooms }}</p>
      </div>
      <div class="property-detail">
        <i class="fas fa-user-friends"></i>
        <p>{{ "guests" | translate }}: {{ hotelDetails?.guests }}</p>
      </div>
      <div class="property-detail">
        <i class="fas fa-bed"></i>
        <p>{{ "beds" | translate }}: {{ hotelDetails?.beds }}</p>
      </div>
      <div class="property-detail">
        <i class="fas fa-bath"></i>
        <p>{{ "bathrooms" | translate }}: {{ hotelDetails?.bathrooms }}</p>
      </div>
      <div class="property-detail">
        <i class="fas fa-ruler-combined"></i>
        <p>
          {{ "totalSpace" | translate }}: {{ hotelDetails?.totalSpace }}
          {{ "meters" | translate }}
        </p>
      </div>
    </div>
    <!-- <div style="display: flex;  gap:5px"><p>{{'fullSize'|translate}}</p> {{ getTotalSize() }} <div>{{'meters'|translate}}</div></div> -->
    <br />

    <button class="action-button" (click)="confirm()">
      {{ "confirm" | translate }}
    </button>
    <!--Card For mobile Application-->
    <div
      class="col-sm-12 d-sm-none"
      *ngFor="let room of hotelDetails?.Rooms; let i = index"
    >
      <div class="card-container">
        <div class="d-flex flex-column gap-2">
          <!--Upper Container-->
          <div class="d-flex justify-content-between align-items-center">
            <div class="upper-container d-flex gap-2">
              <!-- <div class="image-container">
                <img [src]="room.images[0]" [alt]="room.name" style="width: 60px; border-radius: 10px;">
              </div> -->
              <div class="heading">
                <div class="center">{{ room.name }}</div>
                <div class="orient">
                  <img src="../../assets/icons/double-bed-icon.svg" alt="" />
                  <p class="list">
                    {{ room.bedCount }} {{ "king_bed" | translate }}
                  </p>
                </div>
              </div>
            </div>
            <!--Guests and Area-->
            <div class="spacefication d-flex flex-column gap-2">
              <div class="guests d-flex gap-2">
                <span>{{ "guests" | translate }}</span>
                <div class="d-flex flex-column">
                  <div>
                    <img
                      [alt]="'parent-1'"
                      src="../../assets/icons/parent-1.svg"
                      class="big-person-icon"
                    /><img
                      [alt]="'parent-1'"
                      src="../../assets/icons/parent-1.svg"
                      alt=""
                      class="big-person-icon"
                    />+
                    <img
                      [alt]="'parent-1'"
                      src="../../assets/icons/parent-1.svg"
                      class="small-person-icon"
                    /><img
                      [alt]="'parent-1'"
                      src="../../assets/icons/parent-1.svg"
                      class="small-person-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="area d-flex gap-2">
              <span> {{ "space" | translate }} </span>
              <span>{{ room.size }} {{ "meters" | translate }} </span>
            </div>
            <div class="room-count">
              <div class="counter-container">
                <div class="counter">
                  <mat-icon
                    class="arrow hovered-pointer"
                    (click)="incrementGuests(room)"
                  >
                    arrow_drop_up
                  </mat-icon>
                  <mat-icon
                    class="arrow"
                    [ngClass]="{ pointer: room.roomCount === 0 }"
                    (click)="decrementGuests(room)"
                  >
                    arrow_drop_down
                  </mat-icon>
                </div>
                <input
                  [disabled]="true"
                  appOnlyNumbers
                  type="number"
                  min="1"
                  placeholder="1"
                  [(ngModel)]="room.roomCount"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <span>{{ "price" | translate }}</span>
            <span style="font-family: 'heavy'"
              >{{ room.price | number : "1.2-2" }} {{ "ASR" | translate }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!--End Card For mobile Applicaiton-->
    <!-- <div class=" col-xs-12 col-sm-12 col-md-2">
      <div class="sticky-total">
        <div class="d-flex align-item-center flex-column text-center">
          <h1>الاجمالي</h1>
          <p>{{totalPrice}}</p>
          <button class="action-button" (click)="handelReservation()">احجز</button>
        </div>
      </div>
    </div> -->
    <!-- <div class="text-end mb-2  d-sm-block d-none" dir="ltr">
      <div class="w-25">
        <button class="action-button" (click)="handelReservation()">احجز</button>
      </div>
    </div> -->
  </div>
  <div class="total-price py-4" *ngIf="totalPrice > 0 && isAvaliable">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-10">
          <div class="d-flex align-items-center gap-2">
            <h1>{{ "total" | translate }}</h1>
            <h2 style="font-family: 'heavy'">
              {{ sharedNetPrice_BE | number : "1.2-2" }} {{ "ASR" | translate }}
            </h2>
          </div>
        </div>
        <div class="col-sm-2">
          <button class="action-button" (click)="handelReservation()">
            {{ "book" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
