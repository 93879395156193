import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css'],
})
export class FilterComponent implements OnInit {
  constructor(private translate: TranslateService) {}
  form: any = {
    minPrice: 0,
    maxPrice: 300060,
    placeType: 'private',
    bedrooms: 'any',
    beds: 'any',
    bathrooms: 'any',
    sortBy: 'lowPrice',
  };
  filterText = {
    title: 'Filters',
    priceRange: 'Price Range',
    minPrice: 'Min price',
    maxPrice: 'Max price',
    sortBy: 'Sort By',
    lowToHigh: 'Price (Low to High)',
    highToLow: 'Price (High to Low)',
    rateHighToLow: 'Rate (High to Low)',
    rateLowToHigh: 'Rate (Low to High)',
    roomsAndBeds: 'Rooms and Beds',
    bedrooms: 'Bedrooms',
    beds: 'Beds',
    bathrooms: 'Bathrooms',
    any: 'Any',
    search: 'Search',
  };
  filterTextAr = {
    title: 'التصفيات',
    priceRange: 'المدى السعري',
    minPrice: 'السعر الأدنى',
    maxPrice: 'السعر الأقصى',
    sortBy: 'ترتيب حسب',
    lowToHigh: 'السعر (من الأقل إلى الأعلى)',
    highToLow: 'السعر (من الأعلى إلى الأقل)',
    rateHighToLow: 'التقييم (من الأعلى إلى الأقل)',
    rateLowToHigh: 'التقييم (من الأقل إلى الأعلى)',
    roomsAndBeds: 'الغرف والأجهزة',
    bedrooms: 'الغرف',
    beds: 'الأجهزة',
    bathrooms: 'الحمامات',
    any: 'أي',
    search: 'ابحث',
  };
  isFlipped: boolean = false;
  ngOnInit() {
    this.isFlipped = this.translate.currentLang === 'en';
    this.filterText = this.isFlipped ? this.filterText : this.filterTextAr;
    console.log(this.translate.currentLang);
  }

  selectOption(category: string, value: any) {
    this.form[category] = value;
  }

  clearAll() {}
  onSortChange(event: any) {
    this.form.sortBy = event.target.value;
  }
  onMaxPriceChange(event: any) {
    this.form.maxPrice = event.target.value;
  }
  closeFilter() {}
  onMinPriceChange(event: any) {
    this.form.minPrice = event.target.value;
  }
  applyFilters() {
    localStorage.setItem('filters', JSON.stringify(this.form));
  }
}
