import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent {
  html: SafeHtml = ""
  constructor(private DataContextService: DataContextService, private DomSanitizer: DomSanitizer,private translate:TranslateService ){
    this.DataContextService.websiteInfo.subscribe((d: WebsiteInfoDTO)=>{
      this.translate.currentLang==='en'? d.tosEn ? this.html = this.DomSanitizer.bypassSecurityTrustHtml(d.tosEn) : "" :
      d.tosAr ? this.html = this.DomSanitizer.bypassSecurityTrustHtml(d.tosAr) : ""
    })
  }
}
