<div class="container" id="over-print"  dir="auto" style="text-align: start;" >
  <div class="row my-4" id="container-hidden" *ngIf="statisticList">
    <p class="fs-2">{{'orders'|translate}}</p>
    <span>   {{'manage_incoming_orders'|translate}}</span>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-blue box  flex-wrap">
        <span class="fs-2">{{(statisticList.newOrders < 10) && (statisticList.newOrders > 0) ? '0'+statisticList.newOrders  : statisticList.newOrders}}</span>
        <p class="mb-0 fs-6 text-center">{{'new_orders'|translate}}</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-green box flex-wrap">
        <span class="fs-2">{{(statisticList.completedOrders < 10) && (statisticList.completedOrders > 0) ? '0'+statisticList.completedOrders  : statisticList.completedOrders}}</span>
        <p class="mb-0 fs-6 text-center">{{'completed_orders'|translate}}</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-pink box  flex-wrap">
        <span class="fs-2">{{(statisticList.cancelledOrders < 10) && (statisticList.cancelledOrders > 0) ? '0'+statisticList.cancelledOrders  : statisticList.cancelledOrders}}</span>
        <p class="mb-0 fs-6 text-center"> {{'canceled_orders'|translate}}</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-baby-blue box flex-wrap">
        <span class="fs-2">{{(statisticList.refundedOrders < 10) && (statisticList.refundedOrders > 0) ? '0'+statisticList.refundedOrders  : statisticList.refundedOrders}}</span>
        <p class="mb-0 fs-6 text-center"> {{'returned_orders'|translate}}</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-warm box  flex-wrap">
        <span class="fs-2">{{(statisticList.pendingOrders < 10) && (statisticList.pendingOrders > 0) ? '0'+statisticList.pendingOrders  : statisticList.pendingOrders}}</span>
        <p class="mb-0 fs-6 text-center"> {{'awaiting_arrival'|translate}} </p>
      </div>
    </div>
    <div class="col-sm-12 col-md-3 col-lg-2 my-4">
      <div class="d-flex flex-column align-items-center justify-content-center gap-3 bg-purple box  flex-wrap">
        <span class="fs-2">{{statisticList.guests}}</span>
        <p class="mb-0 fs-6 text-center"> {{'guests_staying'|translate}}</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-9 col-lg-12"></div>
    <div class="col-sm-12 col-md-4 col-lg-4 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
          <p class="mb-0 main-p"> {{'total_income'|translate}}</p>
          <p class="mb-0 sec-p">{{statisticList.totalRevenue | number:'1.2-2'}}</p>
          <span>{{'ASR'|translate}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
          <p class="mb-0 main-p"> {{'net_profit'|translate}}</p>
          <p class="mb-0 p-green">{{statisticList.netProfit | number:'1.2-2'}}</p>
          <span>{{'ASR'|translate}}</span>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
          <p class="mb-0 main-p"> {{'rating'|translate}}</p>
          <p class="mb-0 sec-p">{{statisticList.averageRating.toFixed(1)}}/5</p>
      </div>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2 my-4">
      <div class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap ">
          <p class="mb-0 main-p"> {{'guests'|translate}}</p>
          <p class="mb-0 sec-p">{{statisticList.guests}}</p>
      </div>
    </div>
  </div>

    <app-orders-table (handelReloadData)="onReloadOrders()" [ordersList]="ordersList" (filterStatus)="onHandelFilterOrders($event)"></app-orders-table>
<!-- <div class="navigation">
    <mat-icon class="pointer" (click)="previous()">chevron_right</mat-icon>
    <div class="active">
        <p>{{currentNumber}}</p>
    </div>
    <p>{{currentNumber+1}}</p>
    <mat-icon class="pointer" (click)="next()">chevron_left</mat-icon>
</div> -->
</div>
