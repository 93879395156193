 <div class="container-s container" dir="auto" style="text-align: start;">
  <div class="row">
    <div class="title" style="text-align: start;" >{{'technical_support'|translate}}</div>
    <div class="intro col-sm-12 col-md-3 hovered-pointer" [ngClass]="{'opacity-1':!isSupport}" (click)="handelSendTicket()">
        <div class="flex">
            <div class="mail-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#141414" d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7l8-5V6l-8 5l-8-5v2z"/></svg>
            </div>
            <div class="inner-text">
                <p class="inner-title" style="text-align: start;">
                    {{'send_email'|translate}}
                </p>
                <p class="inner-subtitle">{{'response_in_two_working_days'|translate}}                </p>
            </div>


        </div>
    </div>
    <div class="intro  col-sm-12 col-md-3 hovered-pointer" [ngClass]="{'opacity-1':isSupport}" (click)="handelSendCentral()">
      <div class="flex">
          <div class="mail-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#141414" d="M11 21v-2h8v-7.1q0-2.925-2.037-4.962T12 4.9T7.038 6.938T5 11.9V18H2v-5.725h1l.075-1.325q.2-1.7.988-3.15t1.975-2.525T8.762 3.6T12 3t3.225.6t2.725 1.663t1.975 2.512t1 3.15l.075 1.35h1V18h-1v3zm-2-7q-.425 0-.712-.288T8 13t.288-.712T9 12t.713.288T10 13t-.288.713T9 14m6 0q-.425 0-.712-.288T14 13t.288-.712T15 12t.713.288T16 13t-.288.713T15 14m-8.975-1.55Q5.85 9.8 7.625 7.9T12.05 6q2.225 0 3.913 1.412T18 11.026Q15.725 11 13.813 9.8t-2.938-3.25q-.4 2-1.687 3.563T6.025 12.45"/></svg>
          </div>
          <div class="inner-text">
              <p class="inner-title" style="text-align: start;">
                  {{'support_center'|translate}}
              </p>
              <p class="inner-subtitle" style="text-align: start;">
                {{'financial_and_damage_dispute_support_center'|translate}}
              </p>
          </div>


      </div>
    </div>
    <div class="intro col-sm-12 col-md-5"></div>
  </div>
    <div class="row">
        <form [formGroup]="supportForm" class="col-sm-12 col-lg-8 mb-4">
          <div class="col-sm-12 col-md-12 col-lg-12 mb-4" *ngIf="!isSupport">
            <mat-radio-group
              [color]="'primary'"
              aria-label="Select an option"
              formControlName="type"
              (change)="handelHostReservations()"
            >
              <mat-radio-button
                [value]="'guest'"
                ><a
                  style="
                    text-decoration: underline;
                    font-size: 14px;
                    font-family: 'black';
                  "
                  >{{'guests'|translate}}</a
                ></mat-radio-button
              >
              <mat-radio-button
              [value]="'host'"
              ><a
                style="
                  text-decoration: underline;
                  font-size: 14px;
                  font-family: 'black';
                "
                >{{'host'|translate}}</a
              ></mat-radio-button
            >
            </mat-radio-group>
          </div>
            <div class="reservations" *ngIf="!isSupport">
              <select class="custom-select" formControlName="reservationId"
              [ngClass]="{'border-red':this.supportForm.controls['reservationId'].status === 'INVALID' && this.supportForm.controls['reservationId'].touched && !isSupport,'bg-select-img':reservationList && reservationList.length > 0}">
                <option value=""> {{'select_booking'|translate}}</option>
                <option
                *ngFor="let res of reservationList"
                [value]="res.id"
                >
                  {{!isFlipped?res.Property.nameAr:res.Property.nameEn + ' '}} <span *ngIf="supportForm.controls['type'].value === 'host'">[{{res.firstName  + ' ' + res.lastName}}]</span>
                </option>
              </select>
            </div>
            <div class="reservations" *ngIf="!isSupport">
              <select class="custom-select" formControlName="settlementReasonId"
              [ngClass]="{'border-red':this.supportForm.controls['settlementReasonId'].status === 'INVALID' && this.supportForm.controls['settlementReasonId'].touched && !isSupport,
              'bg-select-img':resonseList && resonseList.length > 0}">
                <option value="">  {{'select_damage_type'|translate}}</option>
                <option *ngFor="let reason of resonseList" [value]="reason.id">{{!isFlipped?reason.nameAr:reason.nameEn}}</option>
              </select>
            </div>
            <div class="name">
                <mat-icon class="person-icon opacity-1">person</mat-icon>
                <input type="text" placeholder="{{'name'|translate}}" formControlName="name"
                    [ngClass]="{'border-red':this.supportForm.controls.name.status==='INVALID' && this.supportForm.controls.name.touched}">
            </div>
            <div class="mail">
                <mat-icon class="mail-icon opacity-1" >mail</mat-icon>
                <input formControlName="email" type="text" placeholder="{{'email'|translate}}"
                    [ngClass]="{'border-red':this.supportForm.controls.email.status==='INVALID'&& this.supportForm.controls.email.touched}">
            </div>
            <div class="message-title">
                <input type="text" placeholder="{{'message_subject'|translate}}" formControlName="title"
                    [ngClass]="{'border-red':this.supportForm.controls.title.status==='INVALID' && this.supportForm.controls.title.touched}">
            </div>
            <div class="message">
                <textarea name="" placeholder=" {{'message_body'|translate}}" id="" cols="30" rows="10" formControlName="body"
                    [ngClass]="{'border-red':this.supportForm.controls.body.status==='INVALID' && this.supportForm.controls.body.touched}"></textarea>
            </div>

        <p class="img-title">
            {{'attachments'|translate}}
        </p>
        <ngx-dropzone (change)="onSelect($event)" accept="image/*" dir="ltr">
          <div class="upload-title" #uploadImageCloud>
            <img
              src="../../assets/icons/Icon awesome-cloud-upload-icon.svg"
              alt=""
              class="upload-img"
            />
            <ngx-dropzone-label>  {{'drag_images_here'|translate}}</ngx-dropzone-label>
          </div>
          <div *ngFor="let file of files">
            <ngx-dropzone-preview
              [file]="file"
              *ngIf="file.type.startsWith('image/')"
              [removable]="true"
              (removed)="onRemove(file)"
            >
              <ngx-dropzone-label
                style="
                  overflow: hidden;
                  width: 180px;
                  height: 116px;
                  display: flex;
                  align-items: center;
                "
                ><img
                  style="object-fit: contain; width: 100%; height: 100%"
                  [src]="file.preview"
                  alt="Image Preview"
              /></ngx-dropzone-label>
            </ngx-dropzone-preview>
          </div>
          <!--
          <ngx-dropzone-preview
            *ngFor="let f of files"
            [removable]="true"
            (removed)="onRemove(f)"
          >
          <img [src]="f.preview" alt="Image Preview">
            <ngx-dropzone-label
              >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
            >
          </ngx-dropzone-preview> -->
        </ngx-dropzone>

            <div class="description">
                JPG, PNG، PDF
            </div>

            <button (click)="sendTicket()" class="submit-button">{{'send'|translate}}</button>
        </form>
        <div class="information-area col-sm-12 col-lg-4">
            <div class="info-area-cont">
              <div   [style.transform]="isFlipped ? 'rotate(180deg)' : 'rotate(0deg)'">
                <img  src="../../assets/Icon feather-arrow-left.svg" alt="" class="arrow-icon">

              </div>
                <div>
                    <p class="inner-title" > 
                         {{'technical_support'|translate}}
                    </p>
                    <a class="inner-subtitle hovered-pointer"  href="mailto:support@aroovia.com">
                        support&#64;aroovia.com
                    </a>
                </div>
            </div>
            <div class="info-area-cont">
              <div   [style.transform]="isFlipped ? 'rotate(180deg)' : 'rotate(0deg)'">

                <img src="../../assets/Icon feather-arrow-left.svg" alt="" class="arrow-icon">
                </div>
                <div>
                    <p class="inner-title">
                         {{'sales_and_marketing'|translate}}
                    </p>
                    <a class="inner-subtitle hovered-pointer" href="mailto:marketing@aroovia.com">
                        marketing&#64;aroovia.com
                    </a>
                </div>
            </div>
            <div class="info-area-cont">
              <div   [style.transform]="isFlipped ? 'rotate(180deg)' : 'rotate(0deg)'">

                <img src="../../assets/Icon feather-arrow-left.svg" alt="" class="arrow-icon">
              </div>
                <div>
                    <p class="inner-title">
                        {{'recruitment'|translate}} </p>
                    <a class="inner-subtitle" href="mailto:career@aroovia.com">
                        career&#64;aroovia.com
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
