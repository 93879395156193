import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CancelPolicyDialogComponent } from 'app/cancel-policy-dialog/cancel-policy-dialog.component';
import { PropertyDTO } from 'app/new-property/property-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, forkJoin, throwError } from 'rxjs';
import { BedsTypesDTO, CancellationPolicyDTO, RoomsDTO } from './rooms-dto';

@Component({
  selector: 'app-rooms-managment',
  templateUrl: './rooms-managment.component.html',
  styleUrls: ['./rooms-managment.component.css'],
})
export class RoomsManagmentComponent implements OnInit, OnDestroy {
  roomsInformationForm!: FormGroup;
  isEdit: boolean = false;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private datacontext: DataContextService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    ///check if he is editing or adding new room before the components run
    this.isEdit = this.datacontext.getRouteParams('isRoomEdit') == 'true';
  }
  isFlipped = false;

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    // getting the route params to make call api
    this.onInitRoomsForm();
    this.getPropertyRooms();
    this.activatedRoute.queryParams.subscribe((query) => {
      if (query['new'] == '0' && query['id'] == '0' && !this.isEdit) {
        this.isEdit = false;
      } else {
        this.isEdit = true;

        this.gettingRoomDetails(query['id']);
      }
    });
  }
  propertyTypeList: PropertyDTO[];
  bedsTypes: BedsTypesDTO[];
  cancellationPolicy: CancellationPolicyDTO[];
  private getPropertyRooms() {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token');
    const functions = [
      this.datacontext.getData(`/property/list?token=${token}`),
      this.datacontext.getData(`/room/list-rooms?token=${token}`),
      this.datacontext.getData('/cancellation-policy/list'),
    ];
    forkJoin(functions)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value[0].success) {
            this.propertyTypeList = value[0].data.map((prop: any) => {
              return {
                id: prop.id,
                nameAr: prop.nameAr,
              };
            });
          }

          if (value[1].success) {
            this.bedsTypes = value[1].data;
          }
          if (value[2].success) {
            this.cancellationPolicy = value[2].data;
            this.roomsInformationForm
              .get('refuse1')
              ?.setValue(this.cancellationPolicy[0].id);
          }
          // this.rootBlocker.stop();
        },
      });
  }
  onInitRoomsForm() {
    this.roomsInformationForm = this.fb.group({
      stateType: ['', [Validators.required]],
      roomName: ['', [Validators.required]],
      roomNumber: ['', [Validators.required]],
      maxRoomNumber: ['', [Validators.required]],
      maxChildrenNumber: ['', [Validators.required]],
      maxArea: ['', [Validators.required]],
      bedType: ['', [Validators.required]],
      bedNumber: ['', [Validators.required]],
      // dayPrice: ['', [Validators.required]],
      // priceAfterDiscount: ['', [Validators.required]],
      refuse1: ['1', [Validators.required]],
    });
  }

  private restoreRoomFormData(roomData: RoomsDTO) {
    this.roomsInformationForm.get('stateType')?.setValue(roomData.PropertyId);
    this.roomsInformationForm.get('roomName')?.setValue(roomData.name);
    this.roomsInformationForm.get('roomNumber')?.setValue(roomData.roomsCount);
    this.roomsInformationForm
      .get('maxRoomNumber')
      ?.setValue(roomData.maxAdults);
    this.roomsInformationForm
      .get('maxChildrenNumber')
      ?.setValue(roomData.maxChilderns);
    this.roomsInformationForm.get('maxArea')?.setValue(roomData.size);
    this.roomsInformationForm.get('bedType')?.setValue(roomData.BedTypeId);
    this.roomsInformationForm.get('bedNumber')?.setValue(roomData.bedCount);
    // this.roomsInformationForm.get('dayPrice')?.setValue(roomData.price);
    // this.roomsInformationForm
    //   .get('priceAfterDiscount')
    //   ?.setValue(roomData.priceAfterDiscount);
    this.roomsInformationForm
      .get('refuse1')
      ?.setValue(roomData.CancellationPolicyId);
  }

  private roomDetailsOnEdit: RoomsDTO;
  imagesOnEdit: string[];
  private gettingRoomDetails(id: string) {
    // this.rootBlocker.start();
    const token = this.datacontext.getRouteParams('token');
    this.datacontext
      .getData(`/room/${id}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.roomDetailsOnEdit = value.data;
            this.restoreRoomFormData(value.data);
            if (value.data.images) {
              this.imagesOnEdit = value.data.images.split(',');
            }
          }

          // this.rootBlocker.stop();
        },
      });
  }

  viewCancellationPolicy(cancel: CancellationPolicyDTO) {
    this.dialog.open(CancelPolicyDialogComponent, {
      data: cancel,
      width: '500px',
      // maxHeight:'400px'
    });
  }

  handelRemovePhoto(imgName: string, index: number) {
    const bodyData = {
      id: this.roomDetailsOnEdit.id,
      name: imgName,
    };
    const token = this.datacontext.getRouteParams('token');
    // this.rootBlocker.start();
    this.datacontext
      .postData(`/room/remove-image?token=${token}`, bodyData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        if (value.success) {
          this.imagesOnEdit.splice(index, 1);
          this.datacontext.showSuccess('', 'تم مسح الصورة');
        }
        // this.rootBlocker.stop();
      });
  }

  // private convertImagesToFile(images: string) {
  //   const convertedImage = images.split(',');
  //   const x = convertedImage.map(
  //     (path) =>
  //       new File(
  //         [`https://wovenclouds.com/arrovia/api/uploads?path=/${path}`],
  //         `${path}`,
  //         { type: `${path.split('.')[1]}` }
  //       )
  //   );
  //   this.files = x;
  // }

  // files: any[] = [];
  @ViewChild('uploadImageCloud') lableCloudUpload: ElementRef<HTMLDivElement>;
  // onSelect(event: any) {
  //   // this.files.push(...event.addedFiles);
  //   this.files.forEach((file) => {
  //     if (file.type.startsWith('image/')) {
  //       const reader = new FileReader();
  //       reader.onload = (e: any) => {
  //         file.preview = e.target.result;
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   });
  //   if (this.lableCloudUpload) {
  //     this.lableCloudUpload.nativeElement.style.opacity = '0';
  //   }
  // }

  // onRemove(event: any) {
  //   this.files.splice(this.files.indexOf(event), 1);
  //   if (this.files.length === 0) {
  //     this.lableCloudUpload.nativeElement.style.opacity = '1';
  //   }
  // }

  onAddNewRoom() {
    // if (
    //   this.roomsInformationForm.invalid ||
    //   (!this.isEdit && this.files.length === 0)
    // ) {
    //   this.datacontext.showError(
    //     this.translate.instant('errorr'),
    //     this.translate.instant('incomplete_information')
    //   );
    //   return;
    // }
    const token = this.datacontext.getRouteParams('token');
    let url;

    if (this.isEdit) {
      url = `/room/update/${this.roomDetailsOnEdit.id}?token=${token}`;
    } else {
      url = `/room/create?token=${token}`;
    }
    const dataBody = this.prepareFormData();
    // this.rootBlocker.start();
    this.datacontext
      .postData(url, dataBody)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop();
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            if (this.isEdit)
              this.datacontext.showSuccess('', 'تم تعديل الغرفة بنجاح');
            else this.datacontext.showSuccess('', 'تم اضافة الغرفة بنجاح');
            this.router.navigate(['/profile', 'rooms-list']);
          }
          // this.rootBlocker.stop();
        },
      });
  }

  private prepareFormData(): FormData {
    let formData = new FormData();

    formData.append('PropertyId', this.roomsInformationForm.value.stateType);
    formData.append('name', this.roomsInformationForm.value.roomName);
    formData.append('roomsCount', this.roomsInformationForm.value.roomNumber);
    formData.append('maxAdults', this.roomsInformationForm.value.maxRoomNumber);
    formData.append(
      'maxChilderns',
      this.roomsInformationForm.value.maxChildrenNumber
    );
    formData.append('size', this.roomsInformationForm.value.maxArea);
    formData.append('BedTypeId', this.roomsInformationForm.value.bedType);
    formData.append('bedCount', this.roomsInformationForm.value.bedNumber);
    // formData.append('price', this.roomsInformationForm.value.dayPrice);
    // formData.append(
    //   'priceAfterDiscount',
    //   this.roomsInformationForm.value.priceAfterDiscount
    // );
    formData.append(
      'CancellationPolicyId',
      this.roomsInformationForm.value.refuse1
    );

    // if (this.files.length > 0) {
    //   this.files.forEach((file) => {
    //     formData.append('images', file);
    //   });
    // }

    return formData;
  }

  ngOnDestroy(): void {
    if (this.isEdit) {
      this.datacontext.removeRouteParams('isRoomEdit');
    }
  }
}
