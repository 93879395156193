import { Router } from "@angular/router";
import { AuthenticationService, userData } from "../auth/authentication.service";
import { DataContextService } from "../global-context/data-context.service";
import { catchError, throwError } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { PopUpComponent } from "app/pop-up/pop-up.component";
import { PopupComponent } from "app/popup/popup.component";

export function initApp(authService: AuthenticationService, datacontext: DataContextService, router: Router) {
  const loggedIn = authService.getToken('token')
  return () => {
    new Promise((resolve, reject) => {

      //popUp


      if (loggedIn) {
        //calling api to check token before resolve the angular application
        datacontext.getData(`/auth/user/tokenCheck?token=${loggedIn}`).pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              datacontext.setUserData(null)
              datacontext.removeRouteParams('token')
              datacontext.removeRouteParams('userInfo')
              router.navigate(['/'])
            })
          })
        ).subscribe((value: any) => {
          
          if (value.success) {
            const userData: any = JSON.parse(localStorage.getItem('userInfo')!)
            datacontext.setUserData(userData)

          }
          resolve(true)

        })
      }

    })
  }
}
