<div class="body-content container" >
  <app-navigation [back]="'details/{{id}}'"></app-navigation>
  <app-image-gallery [hotelDetails]="searchDataResult"></app-image-gallery>
  <br />
  <br />

  <app-reservation (onReserve)="onReserve()"  (netPriceChange)="onSharedNetPriceChange($event)" [sharedNetPrice_BE]="sharedNetPrice_BE" [reservationDetails]="searchDataResult"></app-reservation>

  <br />
  <br />

  <!-- hotel-details.component.html -->

  <app-hotel-details [hotelInfo]="searchDataResult"></app-hotel-details>
  <br />
  <br />

  <app-room-options (netPriceChange)="onSharedNetPriceChange($event)"  [sharedNetPrice_BE]="sharedNetPrice_BE" [hotelDetails]="searchDataResult" ></app-room-options>

  <br />

  <app-chalets-options [searchRes]="searchDataResult"></app-chalets-options>
  <br />
  <br />
  <br />
  <app-reservation-info [searchRes]="searchDataResult"></app-reservation-info>
  <br />
  <br />
  <br />
  <hr />

  <app-hotel-rate [searchRes]="searchDataResult"></app-hotel-rate>
  <br />

  <app-user-reviews
    [ratingComments]="searchDataResult"
  ></app-user-reviews>

  <br />
  <br />
  <app-might-like></app-might-like>
  <p class="title-accordion" dir="auto" style="text-align: start;">{{'faq'|translate}}</p>
  <app-accordion></app-accordion>
</div>

<app-download></app-download>
