<div class="cust-cont" [dir]="isFlipped ? 'ltr' : 'rtl'" style="text-align: start;">
  <div *ngFor="let data of faqList; let i = index" [dir]="isFlipped ? 'ltr' : 'rtl'" style="text-align: start;">
    <div (click)="toggleActiveAccordion(i)" class="accordion" >     
       <span [innerHTML]="getSanitizedHtml(!isFlipped ? data.qAr : data.qEn)" class="ms-3"></span>
    </div>
    <div class="panel" [dir]="isFlipped ? 'ltr' : 'rtl'" style="text-align: start;">
      <p [innerHTML]="getSanitizedHtml(!isFlipped?data.aAr:data.aEn)"></p>
    </div>
    <!-- <img src="../../assets/icons/Icon ionic-ios-arrow-downs.svg" alt=""> -->
  </div>
</div>
