import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, forkJoin, Subscription, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { WebsiteInfoDTO } from 'app/home/website-info-dto';
import { ReservationDTO } from 'app/reservation-management/reservation-dto';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

interface reasonListDTO{
  id:number;
  nameAr:string;
  nameEn:string;
  status:string;
  type:string;
  updateAt:string;
  createdAt:string;
}
@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.css'],
})
export class TechnicalSupportComponent implements OnInit, OnDestroy {
  files: any[] = [];
  token: string = '';
  subscription: Subscription;
  @ViewChild('uploadImageCloud') lableCloudUpload: ElementRef<HTMLDivElement>;
  isSupport: boolean = true;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(private datacontext: DataContextService,private translate: TranslateService) {
    this.token = datacontext.getRouteParams('token')!;
  }
  isFlipped=false

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    this.subscription = this.datacontext.websiteInfo.subscribe((value) => {
      this.websiteInfo = value;
    });
  }

  supportForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    title: new FormControl('', [Validators.required]),
    body: new FormControl('', [Validators.required]),
    type: new FormControl(''),
    reservationId: new FormControl(''),
    settlementReasonId:new FormControl('')
  });

  websiteInfo: WebsiteInfoDTO;
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
    this.files.forEach((file) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          file.preview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    });
    if (this.lableCloudUpload) {
      this.lableCloudUpload.nativeElement.style.opacity = '0';
    }
  }
  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    if (this.files.length === 0) {
      this.lableCloudUpload.nativeElement.style.opacity = '1';
    }
  }

  sendTicket() {
    if (this.supportForm.invalid){
      this.supportForm.markAllAsTouched()
      return
    }

    const formData = new FormData();

    formData.append('email', this.supportForm.value.email!);
    formData.append('name', this.supportForm.value.name!);
    formData.append('title', this.supportForm.value.title!);
    formData.append('body', this.supportForm.value.body!);
    if(this.supportForm.controls['reservationId'].value){
      formData.append('reservationId',this.supportForm.controls['reservationId'].value)
    }
    if(this.supportForm.controls['settlementReasonId'].value){
      formData.append('settlementReasonId',this.supportForm.controls['settlementReasonId'].value)
    }
    if(this.supportForm.controls['type'].value){
      formData.append('type',this.supportForm.controls['type'].value)
    }
    if (this.files.length > 0) {
      this.files.forEach((file) => {
        formData.append('attachments', file);
      });
    }

    // this.rootBlocker.start()
   let url;
  if(!this.isSupport){
    url = `/support-settlements/create?token=${this.token}`
  }else{
    url = `/support-tickets/create?token=${this.token}`
  }
    this.datacontext
      .postData(url, formData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe({
        next: (value: any) => {
          if (value.success) {
            this.datacontext.showSuccess('', this.translate.instant('request_submitted_successfully'));
            this.supportForm.reset();
            this.supportForm.controls.reservationId.setValue('')
            this.supportForm.controls.settlementReasonId.setValue('')
            this.files = [];
          }
          // this.rootBlocker.stop()
        },
      });
  }

  handelSendTicket() {
    this.isSupport = true;
    this.supportForm.controls['reservationId'].removeValidators(Validators.required)
    this.supportForm.controls['reservationId'].setValue('');
    this.supportForm.controls['settlementReasonId'].removeValidators(Validators.required)
    this.supportForm.controls['settlementReasonId'].setValue('');
    this.supportForm.controls['type'].setValue('guest');
    this.supportForm.markAsUntouched()
    this.supportForm.markAsPristine()
    this.supportForm.reset()
  }

  reservationList: ReservationDTO[];
  handelSendCentral() {
    this.isSupport = false;

    this.supportForm.controls['reservationId'].addValidators(Validators.required)
    this.supportForm.controls['settlementReasonId'].addValidators(Validators.required)
    this.supportForm.controls['type'].setValue('guest');
    this.supportForm.markAsUntouched()
    this.supportForm.markAsPristine()
    const token = this.datacontext.getRouteParams('token');
    if (token) {
      const functions = [  this.datacontext.getData(`/kamel/list?token=${token}`),  this.datacontext.getData(`/support-settlements/list-reasons?token=${this.token}&type=${this.supportForm.controls['type'].value}`)]

      forkJoin(functions)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              // this.rootBlocker.stop()
              // this.datacontext.showError('', '');
            });
          })
        )
        .subscribe((value: any) => {
          if(value[0].success){
            this.reservationList = value[0].data;
          }
          if(value[1].success){
            this.resonseList =value[1].data
          }
        });
    }
  }

  handelHostReservations(){
    const guestType = this.supportForm.controls['type'].value
    if(guestType === 'guest'){
      this.handelSendCentral()
    }else if (guestType === 'host'){
      // host API List
      this.gettingHostList()
    }
  }

  resonseList:reasonListDTO[]
  private gettingHostList(){ 
    const token = this.datacontext.getRouteParams('token');
    const functions = [  this.datacontext.getData(`/kamel/host-list?token=${token}`),  this.datacontext.getData(`/support-settlements/list-reasons?token=${this.token}&type=${this.supportForm.controls['type'].value}`)]
    if (token) {
      forkJoin(functions)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              this.datacontext.showError('', err.error.message);
            });
          })
        )
        .subscribe((value: any) => {
          if(value[0].success){
            this.reservationList = value[0].data;
          }
          if(value[1].success){
            this.resonseList =value[1].data
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
