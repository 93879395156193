<div class="relative" id="hidden-nav-print"  [dir]="isFlipped ? 'ltr' : 'rtl'">
  <app-header-carousel *ngIf="isHomeRoute"></app-header-carousel>
  <div class="backgroundimg" *ngIf="!isHomeRoute">
    
    <!-- <img src="../../assets/aroovia-icons/nyc.png" alt="" lazy="true" height="100%"/> -->
  </div>
  
  <div class="customContainer bg-white" style="display: flex;"  >
   
    <div class="padding container">
      <div class="row-cont">
        <a routerLink="/" class="logo">
          <img width="180" height="70" src="assets/aroovia-icons/logo/logo.svg" alt="">
        </a>
        <div class="rowcust">
          <button (click)="addNewReal()" class="add-realestate" >
            <p style="cursor: pointer;">{{'add_your_property'|translate}}</p>
            
            
            <img src="../../assets/icons/icon-add.svg" alt="" />
          </button>
          <button
            class="login"
            *ngIf="!datacontext.userData && !isSignInRoute"
            mat-button
            (click)="openDialog()"
          >
            <p class="add-padding">{{'login_or_signup'|translate}}</p>
          </button>
          
          <div #subMenu *ngIf="datacontext.userData">
            <div class="profile">
              <div class="profile dropdown" (click)="openProfileList()">
                <div class="image me-1" >
                  <img [src]="datacontext.userData.image === null ? '../assets/user.jfif' : datacontext.userData.image" class="profile-image" />
                </div>
                <p class="username" *ngIf="datacontext.userData?.firstName !== null">{{ datacontext.userData.firstName }}</p>
                <p class="username" *ngIf="datacontext.userData?.email !== null && datacontext.userData?.firstName === null">{{ datacontext.userData.email }}</p>
                <p class="username" *ngIf="datacontext.userData?.mobile !== null && datacontext.userData?.firstName === null && datacontext.userData.email === null">{{'profile'|translate}}</p>
              </div>
              <div class="profile-list" *ngIf="profileList">
                <ul class="my-2">
                  <li routerLink="/profile/profile" (click)="profileList= !profileList">
                    <img
                      src="assets/aroovia-icons/Icon awesome-user-alt.svg"
                      alt=""
                    />
                    <p>{{'settings'|translate}}</p>
                  </li>
                  <li routerLink="/profile/notifications" (click)="profileList= !profileList">
                    <div style="position: relative;">
                      <img
                      src="assets/aroovia-icons/Icon ionic-ios-notifications.svg"
                      alt=""
                    />
                    <div *ngIf="isNotificationVisible"  style="position: absolute; top: 0; right: -3px;     background-color: #FF0000;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;"></div>
                    </div>
                    <p>{{'notifications'|translate}}</p>

                  </li>
                  <li (click)="logout()" (click)="profileList = !profileList">
                    <img
                      src="assets/aroovia-icons/Icon feather-log-out.svg"
                      alt=""
                    />
                    <p  data-bs-toggle="modal" data-bs-target="#exampleModal">{{'log_out'|translate}}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="lang-switcher" style="margin: 10px;">
            <button class="dropdown-button ms-2" (click)="toggleDropdownn($event)">
              {{ currentLang === 'en' ? 'En' : 'ع' }}
              <span class="arrow" [ngClass]="{'open': dropdownOpen}">&#9662;</span> <!-- Arrow character -->
            </button>
            <ul class="dropdown-menu" *ngIf="dropdownOpen" >
              <li (click)="switchLang('en')"  style="justify-content: center;">English</li>
              <li (click)="switchLang('ar')" style="justify-content: center;">العربية</li>
            </ul>
          </div>
          <!-- <div #subMenuTwo>
            <div class="language-container">
              <div class="language dropdown" (click)="openLanguageList()">
                <mat-icon>arrow_drop_down</mat-icon>
                <p class="languageLabel">{{ languge }}</p>
              </div>
              <div class="language-dropdown" *ngIf="languageList">
                <ul>
                  <li>english</li>
                  <li>arabic</li>
                </ul>
              </div>
            </div>
          </div> -->
        </div>

        
        
      </div>

    </div>

  </div>
  <div class=""  *ngIf="isProfileRoute" >
    <div class="adjust nav-link-container" >
      <div
        class="nav-link-s pointer"
        routerLink="/profile/profile"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'profile'|translate}}</p>
      </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/reservation-management"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'my_bookings'|translate}}</p>
      </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/chats"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'chat'|translate}}</p>
      </div>
      <div class="dropdown  property" (click)="toggleDropdown($event)" [dir]="isFlipped ? 'ltr' : 'rtl'">
        <p class="dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false" [dir]="isFlipped ? 'ltr' : 'rtl'">
          {{ 'manage_my_properties' | translate }}
          <span class="caret"></span>
        </p>
        <ul
          class="dropdown-menu"
          [ngClass]="{ 'show': isDropdownOpen }"
          [dir]="isFlipped ? 'ltr' : 'rtl'">

          <li role="menuitem">
            <!-- <a class="dropdown-item" routerLink="/profile/rooms-list">
              {{ 'manage_rooms' | translate }}
            </a> -->
          </li>
          <li role="menuitem">
            <a class="dropdown-item aaa" routerLink="/profile/property-manage">
              {{ 'manage_properties' | translate }}
            </a>
          </li>
        </ul>
      </div>        
      <div
      class="nav-link-s pointer"
      routerLink="/profile/payments-list"
      routerLinkActive="active-link"
      [dir]="isFlipped ? 'ltr' : 'rtl'"
    >
      <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'payments'|translate}}</p>
    </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/all-orders"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'orders'|translate}}</p>
      </div>
      <div
        class="nav-link-s notification pointer"
        routerLink="/profile/notifications"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'notifications_plural'|translate}}</p>
      </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/wishlist"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'favorites'|translate}}</p>
      </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/technical-support"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'technical_support_plural'|translate}}</p>
      </div>
      <div
        class="nav-link-s pointer"
        routerLink="/profile/faq"
        routerLinkActive="active-link"
        [dir]="isFlipped ? 'ltr' : 'rtl'"
      >
        <p [dir]="isFlipped ? 'ltr' : 'rtl'">{{'faq'|translate}}</p>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" #modelLogOut>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">{{'modal_title'|translate}}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">{{'save_changes'|translate}}</button>
      </div>
    </div>
  </div>
</div>