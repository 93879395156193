<div class="container">
    <div class="title-cont">
        <p class="title">{{'orders'|translate}}</p>
        <p class="subtitle">  {{'manage_incoming_orders'|translate}}</p>
    </div>
    <!-- <app-order-statistics></app-order-statistics> -->
    <app-orders-table (handelReloadData)="onReloadOrders()" [ordersList]="ordersList" (filterStatus)="onHandelFilterOrders($event)"></app-orders-table>
    <div class="navigation">
      <mat-icon class="pointer" (click)="previous()" [ngClass]="{'btn-opacity-1':currentNumber === 1}">chevron_right</mat-icon>
      <div class="active">
          <p>{{currentNumber}}</p>
      </div>
      <p *ngIf="totalPages > currentNumber">{{currentNumber+1}}</p>
      <mat-icon *ngIf="totalPages > currentNumber" class="pointer" (click)="next()">chevron_left</mat-icon>
  </div>
</div>
