<div class="container" dir="auto" style="text-align: start;">
  <h4 class="label">{{ 'notifications'|translate }}</h4>
  <div *blockUI="'notification-blocker'">
    <ng-container *ngIf="notifications&&notifications.length > 0">
      <div class="notification" *ngFor="let notification of notifications">
        <div class="notification-icon"></div>
        <h6 class="date">{{ notification.NotificationRaw.createdAt | date:'M/d/yyyy' }}</h6>
        <h6 class="title">{{ notification.NotificationRaw.title }}</h6>
        <h6 class="details">{{ notification.NotificationRaw.body }}</h6>
      </div>
    </ng-container>
    <ng-container *ngIf="notifications&&notifications.length === 0">
      <p>{{'no_notifications'|translate}}</p>
    </ng-container>
  </div>
</div>
