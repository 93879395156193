import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { catchError, find, throwError } from 'rxjs';
import { HostListener } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.css'],
})
export class ImageGalleryComponent implements OnInit, OnChanges {
  @Input() hotelDetails: SearchDTO | null = null;

  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private datacontext: DataContextService,
    private router: Router,
    private translate: TranslateService
  ) {}

  isFlipped = false;
  getGridTemplateRows(): string {
    const imageCount = this.imageGallery.images.length;
    const rows = Math.ceil(imageCount / 4); // Assuming 4 images per row
    console.log(imageCount);
    console.log(rows);
    console.log(this.imageGallery);
    return `repeat(${rows}, 1fr)`;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.hotelDetails) {
      console.log(this.hotelDetails);
      this.imageGallery.mainImage = this.hotelDetails.mainImage;
      this.imageGallery.images = this.hotelDetails.images;
      this.imageGallery.images.push(this.hotelDetails.mainImage);
    }
    console.log(this.imageGallery);
  }

  ngOnInit(): void {
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
  }
  imageGallery = {
    images: [] as string[],
    showAllImagesBtn: 'عرض كل الصور',
    heartIcon: '',
    mainImage: '',
    selectedImage: '' as any, // Track the selected image for displaying in a larger form
    showAllImages: false, // Initialize the property
  };
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the right arrow key (keyCode 39) is pressed
    if (event.key === 'ArrowRight') {
      this.onMoveNextImagePreview(this.imageGallery.selectedImage);
    } else if (event.key === 'ArrowLeft') {
      this.onMovePreveImagePreview(this.imageGallery.selectedImage);
    }
  }

  showAllImages() {
    // Implement logic to show all images in a row
    this.imageGallery.selectedImage = null; // Reset selectedImage
    this.imageGallery.showAllImages = true;
  }
  // Function to set the selected image when clicked
  selectImage(imagePath: string) {
    this.imageGallery.selectedImage = imagePath;
  }

  // Function to reset the selected image when the close button is clicked
  closeImage() {
    this.imageGallery.selectedImage = null;
  }

  onMoveNextImagePreview(currentImage: string) {
    const findImage = this.imageGallery.images.findIndex(
      (img) => img === currentImage
    );
    if (findImage !== this.imageGallery.images.length - 1) {
      this.imageGallery.selectedImage = this.imageGallery.images[findImage + 1];
    } else {
      this.imageGallery.selectedImage = this.imageGallery.images[0];
    }
  }
  onMovePreveImagePreview(currentImage: string) {
    const findImage = this.imageGallery.images.findIndex(
      (img) => img === currentImage
    );
    if (findImage === 0) {
      this.imageGallery.selectedImage =
        this.imageGallery.images[this.imageGallery.images.length - 1];
    } else {
      this.imageGallery.selectedImage = this.imageGallery.images[findImage - 1];
    }
  }

  onFavHotel(hotel: SearchDTO, isLiked: boolean, event: Event) {
    event.stopPropagation();
    const token = this.datacontext.getRouteParams('token');
    if (!token) {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.router.navigate(['/signin']).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    // this.rootBlocker.start()

    this.datacontext
      .getData(`/wishlist/add-remove/${hotel.id}?token=${token}`)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(() => {
            // this.rootBlocker.stop()
            this.datacontext.showError('', err.error.message);
          });
        })
      )
      .subscribe((value: any) => {
        this.datacontext.showSuccess('',this.translate.instant('update_successful'));
        if (isLiked) {
          hotel.isFavorite = true;
        } else {
          hotel.isFavorite = false;
        }
        // this.rootBlocker.stop()
      });
  }
}
