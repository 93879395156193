import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SearchDTO } from 'app/search-details/search-dto';
import { DataContextService } from 'app/services/global-context/data-context.service';
import { SigninComponent } from 'app/signin/signin.component';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { catchError, throwError } from 'rxjs';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
  @Input() cardsSearch: SearchDTO[] = [];
  @ViewChild('heartIcon') heartIcon!: ElementRef;
  @BlockUI('root') rootBlocker!: NgBlockUI;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private datacontext: DataContextService,
    private ch: ChangeDetectorRef,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {}
  isFlipped = false;
  favorites: Set<number>;
  ngOnInit(): void {
    this.datacontext.favoritesSubject.subscribe((favorites) => {
      this.favorites = favorites;
    });
    this.isFlipped = this.translate.currentLang === 'en';

    // Listen for language change events
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.isFlipped = event.lang === 'en'; // Check if the new language is Arabic
    });
    console.log(this.cardsSearch, 'asd');
    console.log(this.cardsSearch);
  
  }
  changeColor(i: number) {
    const token = this.datacontext.getRouteParams('token');
    if (token) {
      // this.rootBlocker.start();
      this.datacontext
        .getData(
          `/wishlist/add-remove/${this.cardsSearch[i].id}?token=${token}`
        )
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => {
              // this.rootBlocker.stop();
              this.datacontext.showError('', err.error.message);
            });
          })
        )
        .subscribe((value: any) => {
          if (value.success) {
            this.cardsSearch[i].isFavorite = !this.cardsSearch[i].isFavorite;

            this.datacontext.toggleFavorite(this.cardsSearch[i].id);
            // this.datacontext.showSuccess('', 'تم التحديث');
          }
          // this.rootBlocker.stop();
        });
    } else {
      this.datacontext.showError('', this.translate.instant('please_register'));
      this.dialog.open(SigninComponent, {
        width: '450px',
        disableClose: true,
      });
      return;
    }
  }

  goToDetails(id: number) {
    const activatedRoute = this.router.url.includes('/search');
    if (activatedRoute) {
      this.activatedRoute.queryParamMap.subscribe((query: ParamMap) => {
        if (query.get('startDate')) {
          const searchResult: any = {
            startDate: query.get('startDate'),
            endDate: query.get('endDate'),
            adults: query.get('adults'),
            rooms: query.get('rooms'),
            destination: query.get('destination'),
            children: query.get('children'),
          };

          localStorage.setItem('searchResult', JSON.stringify(searchResult));
          this.router.navigate(['/details', id]);
        } else {
          this.router.navigate(['/details', id]);
        }
      });
    } else {
      this.router.navigate(['/details', id]);
    }
  }
}
