<div class="container" dir="auto" style="text-align: start;">
<div id="nav-hidden">
  <app-navigation></app-navigation>
</div>

<div class="heading d-flex align-items-center  gap-3">
  <h2 class="title mb-0">{{'payments'|translate}}</h2>
  <span class="d-flex align-item-center gap-1 ">
    <p role="button" routerLink="/profile/payments-history" class="view-all-txt mb-0 ">{{'payments_view_all'|translate}}</p>
    <img src="../../assets/Icon feather-arrow-left.svg" alt="" >
</span>
</div>
<div class="row " *ngIf="statistic">
  <div class="col-sm-12 col-md-4 col-lg-4 my-4">
    <div
      class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap "
    >
      <p class="mb-0 main-p">{{'total_amount'|translate}}</p>
      <p class="mb-0 sec-p">{{ statistic.total| number : "1.2-2" }}</p>
      <span>{{'ASR'|translate}}</span>
    </div>
  </div>
  <div class="col-sm-12 col-md-3 col-lg-3 my-4">
    <div
      class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap "
    >
      <p class="mb-0 main-p"> {{'withdrawal_balance'|translate}}</p>
      <p class="mb-0 p-secondary">{{ statistic.available| number : "1.2-2" }}</p>
      <span>{{'ASR'|translate}}</span>
    </div>
  </div>
  <div class="col-sm-12 col-md-3 col-lg-3 my-4">
    <div
      class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap "
    >
      <p class="mb-0 main-p"> {{'withdrawn'|translate}}</p>
      <p class="mb-0 p-green">{{ statistic.paidout | number : "1.2-2" }}</p>
      <span>{{'ASR'|translate}}</span>
    </div>
  </div>
  <div class="col-sm-12 col-md-2 col-lg-2 my-4">
    <div
      class="d-flex align-items-center justify-content-center box-salery gap-2 flex-wrap "
    >
      <p class="mb-0 main-p"> {{'arofia_commission'|translate}}</p>
      <p class="mb-0 text-primary" style="font-size: 23px;">{{statistic.fee}}</p>
    </div>
  </div>
</div>

  <div class="row my-4 ">
    <div class="col-sm-12 col-md-8">
      <form [formGroup]="bankForm" >
        <div class="col-8">
          <input formControlName="value" appNumbersOnly type="text" placeholder="{{'amount'|translate}}"
          [ngClass]="{'border-red':this.bankForm.controls['value'].errors && this.bankForm.controls['value'].touched}"/>
        </div>
        <div class="my-4 col-12 align-items-center d-flex">
          <!-- *ngIf="bankForm.controls['bankDetailsId'].value" -->
          <select class="custom-select"    [ngStyle]="{
            'text-align': !isFlipped ? 'right' : 'left',
            'background-position': !isFlipped ? 'left center' : 'right center',
            'padding-left': !isFlipped ? '20px' : '10px',
            'padding-right': !isFlipped ? '10px' : '20px',
          }"  formControlName="VendorBankAccountId" 
          [ngClass]="{'border-red':this.bankForm.controls['VendorBankAccountId'].errors && this.bankForm.controls['VendorBankAccountId'].touched,'bg-select-img':bankList && bankList.length >0}">
            <option dir="auto" style="text-align: start;" value="">{{'bank_details'|translate}} </option>
            <option *ngFor="let bank of bankList"  [value]="bank.id" >{{bank.bank}}</option>
          </select>
          <!-- <select style="color: #ccc;" class="custom-select" formControlName="bankDetailsId" *ngIf="!bankForm.controls['bankDetailsId'].value"
          [ngClass]="{'border-red':this.bankForm.controls['bankDetailsId'].errors && this.bankForm.controls['bankDetailsId'].touched}">
            <option value="" >تفاصيل البنك </option>
            <option *ngFor="let bank of bankList"  [value]="bank.id" >{{bank.name}}</option>
          </select> -->
          <span role="button" class="col-4 btn-outline-danger" (click)="addBankAccount()">{{'add_new_property'|translate}}</span>
        </div>
        <div class="col-8">
          <button type="button" class="action-button" (click)="submitPayment()">
            {{'submit_request'|translate}}          </button>
        </div>
      </form>
    </div>
  </div>
</div>
